//react hooks
import React, { useEffect, useState, useRef, useContext } from 'react';
// Material UI Components
import {
    Avatar,
    Box,
    Grid,
    Typography,
    Checkbox,
    Badge,
    Popover,
    TextField,
    Paper,
    CircularProgress
} from '@mui/material'

//Constants 
import Constant from '../../util/constants'
//Styled Components
import { StyledButton } from '../../component/common/Styled';

//User defined components and functions
import CheckInList from './CheckInList';
import Token from '../../util/Token';
import HttpService from '../../services/HttpService';
import ReportIssue from '../oaza/ReportIssue';
import { Context } from '../../context';

//lodash
import isBoolean from 'lodash/isBoolean'

// Icons and Images
import ThumbUpAltOutlinedIcon from '@mui/icons-material/ThumbUpAltOutlined';
import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt';
import ThumbDownAltIcon from '@mui/icons-material/ThumbDownAlt';
import ThumbDownOffAltOutlinedIcon from '@mui/icons-material/ThumbDownOffAltOutlined';
import TextsmsOutlinedIcon from '@mui/icons-material/TextsmsOutlined';

const { label, action, placeholder } = Constant;

const alignments = {
    vertical: 'bottom',
    horizontal: 'right'
}

const styles = {
    reportBtn: {
        width: 'max-content',
    },
    reviewParentBox: {
        '& > div:not(:last-child)': {
            mb: 3
        }
    },
    form: {
        p: 3
    },
    submit: {
        mb: 2
    }
}

const anchorOrigin = {
    vertical: 'bottom',
    horizontal: 'left'
}

function stringAvatar(name) {
    const [firstName, lastName] = name.split(' ');
    return {
        sx: {
            bgcolor: 'var(--app-color)',
            width: 48,
            height: 48,
            textTransform: "capitalize"
        },
        children: `${firstName[0]}${lastName ? lastName[0] : ""}`
    }
}

function FeedbackPopover({ placeholder, ...props }) {
    const { anchorEl } = props;

    return (
        <Popover 
        id={Boolean(anchorEl) ? 'position-feedback-popover' : undefined}
        open={Boolean(anchorEl)}
        anchorOrigin={anchorOrigin}
        anchorEl={anchorEl}
        { ...props }
        >
            <Paper sx={styles.form} component="form" id="form-feedback" elevation={0}>
                <Grid container>
                    <Grid item xs={12} lg={12} mb={2}>
                        <TextField 
                            id="feedback"
                            placeholder={placeholder}
                            multiline
                            name="message"
                            rows={3}
                            fullWidth
                            />
                    </Grid>
                    <Grid item xs={12} lg={12}>
                        <StyledButton variant="contained" type="button">
                            {label.SUBMIT}
                        </StyledButton>
                    </Grid>
                </Grid>
            </Paper>
        </Popover>
    )
}

const initialState = {
    loading: !0,
    checkInList: [],
    isReportShow: !1,
    like: !1,
    dislike: !1,
    anchorEl: null,
    likeCount: 0,
    disLikeCount: 0
}

function CustomerReview({ user, location }) {
    const [customerReviewState, setCustomerReviewState] = useState(initialState);
    const customerReviewRef = useRef(null);
    const { message } = useContext(Context);

    const handleClick = (type) => async (e) => {
        const bool = type;
        type = type === action.ON_REPORT_CLICK || 
               typeof(type) === 'boolean' ? 
               action.ON_REPORT_CLICK : type;
               
        switch(type) {
            case action.ON_REPORT_CLICK:
                setCustomerReviewState((prev) => ({
                    ...prev,
                    isReportShow: isBoolean(bool) ? !1 : !0
                }))
                break;
            case action.POPOVER_CLOSE:
                setCustomerReviewState((prev) => ({
                    ...prev,
                    anchorEl: null
                }));
                break;
            default:
                const { data } = Token.parsedToken();
                const checked = e.target.checked;
                const target = e.currentTarget;
                const reactionRes = await HttpService(
                    Constant.methods.REACTION,
                    { 
                        location: location._id, 
                        user: data._id, 
                        type: e.target.name && checked === 'like' ? 2 : 
                              e.target.name && checked === 'dislike' ? 3 : 1
                    }
                );
                if(reactionRes.status) {
                    setCustomerReviewState((prev) => ({
                        ...prev,
                        anchorEl: checked ? target : null,
                        [e.target.name]: checked
                    }))
               }
        }
    }

    useEffect(() => {
        const _fetch = async () => {
            const { data } = Token.parsedToken();
            const { setMessageOption } = message;
            setCustomerReviewState((prev) => ({ ...prev, loading: !0 }))
            const promises = Promise.all([
                await HttpService(
                    Constant.methods.GET_REPORTED_ISSUE,
                    { queryParameters: { chargerId: location?.devices[0]?.deviceId } } 
                ),
                await HttpService(
                    Constant.methods.GET_REACTIONS,
                    { queryParameters: { id: data._id } }
                )
            ]);
            const [issueRes, reactionRes] = await promises;
            if(!issueRes.status || !reactionRes.status) {
                setCustomerReviewState((prev) => ({ ...prev, loading: !1 }));
                setMessageOption((prev) => ({
                    ...prev,
                    open: !0,
                    variant: 'error',
                    message: reactionRes.hasOwnProperty('message') 
                    ? reactionRes.message : issueRes.message
                  }))
            }

            if(issueRes.status || reactionRes.status) {
                const reactions = reactionRes?.data || []; 
                const _likeCount = reactions.find((r) => r._id === 1);
                const _disLikeCount = reactions.find((r) => r._id === 2);
                issueRes.data.reverse()
                setCustomerReviewState((prev) => ({
                    ...prev,
                    loading: !1,
                    likeCount: _likeCount?.count || 0,
                    disLikeCount: _disLikeCount?.count || 0,
                    checkInList: issueRes.data
                }))
            }
        }
       _fetch();
    }, []);

    return (<>
        {customerReviewState.loading ?
        <CircularProgress /> :
        <Box className="cm-customer-reviews" sx={styles.reviewParentBox} ref={customerReviewRef}>
            <Grid container alignItems="center" justifyContent="flex-start">
                <Grid item xs={3} sm={3} lg={3} md={3}>
                    <Avatar {...stringAvatar(user.name)} variant="rounded" />
                </Grid>
                <Grid item xs={9} sm={9} lg={9} md={9}>
                    <Typography variant="subtitle2" textAlign="start" textTransform="capitalize">{user.name}</Typography>
                    <Typography variant="caption" display="block" textAlign="start">{label.LEAVE_CHECKIN}</Typography>
                </Grid>
            </Grid>
            <Grid container alignItems="center" textAlign="start">
                <Grid item xs={6} sm={6} lg={6} md={6}>
                    <Badge color="info" sx={{ mr: 2 }} anchorOrigin={alignments} badgeContent={customerReviewState.likeCount} max={1000} >
                        <Checkbox onChange={handleClick(action.ON_LIKE_CLICK)} checked={customerReviewState.like} inputProps={{ 'aria-label': 'like' }} name="like" checkedIcon={<ThumbUpAltIcon />} icon={<ThumbUpAltOutlinedIcon />} />
                    </Badge>
                    <Badge color="info" anchorOrigin={alignments} badgeContent={customerReviewState.disLikeCount} max={1000}>
                        <Checkbox onChange={handleClick(action.ON_DISLIKE_CLICK)} checked={customerReviewState.dislike} inputProps={{ 'aria-label': 'dislike' }} name="dislike" checkedIcon={<ThumbDownAltIcon color="error" />} icon={<ThumbDownOffAltOutlinedIcon />} />
                    </Badge>
                    <FeedbackPopover anchorEl={customerReviewState.anchorEl} placeholder={customerReviewState.anchorEl?.name === 'like' ? placeholder.LIKE : placeholder.DISLIKE} onClose={handleClick(action.POPOVER_CLOSE)} />
                </Grid>
                <Grid item xs={6} sm={6} lg={6} md={6}>
                    <StyledButton variant="contained" onClick={handleClick(action.ON_REPORT_CLICK)} sx={styles.reportBtn} startIcon={<TextsmsOutlinedIcon />} color="error" >
                        {label.REPORT_ISSUE_BTN}
                    </StyledButton> 
                </Grid>
            </Grid>
            <Grid container alignItems="center">
                <Grid item xs={12} sm={12} lg={12}>
                    <ReportIssue 
                    isShow={customerReviewState.isReportShow}
                    container={customerReviewRef.current}
                    chargerId={location?.devices[0]?.deviceId}
                    onReportClick={handleClick}
                    locationId={location._id}
                    />
                </Grid>
            </Grid>
            <Grid container alignItems="center">
                <Typography variant="h6" mb={1}>{label.CHECKIN}</Typography>
                <CheckInList listItem={customerReviewState.checkInList} />
            </Grid>
        </Box>}
    </>)
}

export default React.memo(CustomerReview);
