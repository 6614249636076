import * as Yup from "yup";
import { useFormik, Form, FormikProvider } from "formik";
import axios from "axios";
import {
  Alert,
  Box,
  Divider,
  Slide,
  TextField,
  Typography,
} from "@mui/material";
import { useCallback, useEffect, useRef, useState } from "react";
import { LoadingButton } from "@mui/lab";
import { paymentStyles, PAYMENT_ENDPOINTS } from "./payment.constants";
import { displayRazorpay } from "./razorpay";
import { is } from "../../../util/is";

const paymentFormSchema = Yup.object().shape(
  {
    emailOrPhone: Yup.string(),
    amount: Yup.number()
      .required()
      .positive()
      .typeError("Amount must be integer")
      .test("is-decimal", "Please enter valid amount", (value) =>
        (value + "").match(/^\d+(\.\d{1,2})?$/)
      )
  //     .when("minutes", (minutes, schema) => {
  //       return minutes > 0 ? schema.optional() : schema;
  //     }),
  //   minutes: Yup.number()
  //     .positive()
  //     .typeError("Minutes must be integer")
  //     .when("amount", (amount, schema) => {
  //       return amount > 0 ? schema.optional() : schema;
  //     }),
  },
  ["amount"]
);

export const PaymentForm = ({
  connector,
  chargerId,
  clientHandler,
  email,
  customerName,
  submitHandler,
  paymentPortal,
  setReportStat
}) => {
  const [error, setError] = useState(false);
  const firstInputRef = useRef();
  const [paymentId, setPaymentId] = useState("");
  const paymentFormFormik = useFormik({
    initialValues: {
      emailOrPhone: email,
      customerName: customerName,
      amount: 0,
      // minutes: 0,
      kwh: 0,
    },
    validationSchema: paymentFormSchema,

    onSubmit: (formData, { setSubmitting, resetForm }) => {
      (async function () {
        let startChargingCost = Number(connector.fixedFeeStarting) + Number(connector.fixedFeeCharging)
        if(formData.amount <= startChargingCost){
          setReportStat({open: true, variant: "error", message: `Minimum INR ${startChargingCost+1} required to start charger.`});
          setSubmitting(false);
          return;
        }
        if(formData.amount <= 35 && paymentPortal.toLowerCase() === "stripe"){
          setReportStat({open: true, variant: "success", message: "Please enter amount greater than 35"});
          setSubmitting(false);
          return;
        }
        setError(false);
        // const isStripeGateway =
        //   process.env.REACT_APP_CHARGEMILES_PAYMENT_GATEWAY === "STRIPE";
        const isStripeGateway = paymentPortal.toLowerCase().includes("stripe");
        try {
          const { emailOrPhone } = formData;
          const isPhone = /^\d{10}$/.test(emailOrPhone);
          const email = !isPhone ? emailOrPhone : '';
          const phone = isPhone ? emailOrPhone : '';
          const url = isStripeGateway
            ? PAYMENT_ENDPOINTS.getClientIdUrl
            : PAYMENT_ENDPOINTS.getPaymentOrderUrl;
            
          const response = await axios(url, {
            method: "post",
            data: {
              ...formData,
              paymentId,
              chargerId,
              email,
              customerName,
              phone,
              currency: connector?.currency,
              connectorId: !is.empty(connector?.ocpi_connector_id)? connector?.ocpi_connector_id:connector?.connector_id,
            },
          });
          if (!isStripeGateway) {
            if (response?.data?.id) {
              displayRazorpay(
                {
                  ...formData,
                  currency: connector?.currency,
                  orderId: response?.data?.id,
                  connectorId: !is.empty(connector?.ocpi_connector_id)? connector?.ocpi_connector_id:connector.connector_id,
                  chargerId,
                },
                submitHandler
              );
            } else {
              throw new Error();
            }
          }

          if (isStripeGateway) {
            if (response?.data?.status === "requires_payment_method") {
              clientHandler(true);
            } else if (response?.data?.clientId && response?.data?.paymentId) {
              clientHandler(true, response.data.clientId);
              setPaymentId(response.data.paymentId);
            } else {
              throw new Error();
            }
          }
          setSubmitting(false);
          resetForm({ values: formData });
        } catch (error) {
          setSubmitting(false);

          setError(true);
          resetForm();
        }
      })();
    },
  });

  const {
    errors,
    touched,
    dirty,
    handleSubmit,
    isSubmitting,
    getFieldProps,
    setFieldValue,
    setValues,
    handleChange,
    values: { amount, emailOrPhone, kwh },
    isValid,
  } = paymentFormFormik;

  // const getMinutes = useCallback(
  //   (amount) => {
  //     const {
  //       fixedFeeCharging = 0,
  //       fixedFeeStarting = 0,
  //       kw,
  //       priceperKW = 0,
  //       priceperMin = 0,
  //       taxPercent = 0,
  //     } = connector ?? {};

  //     if (Number(priceperKW) === 0 || isNaN(Number(priceperKW))) {
  //       return (amount / priceperMin).toFixed(2).replace(/\.00$/, "");
  //     } else {
  //       const amountForCharging = amount * (1 - Number(taxPercent) / 100) - Number(fixedFeeCharging) - Number(fixedFeeStarting);
  //       const pricePerWatt = priceperKW / 1000;
  //       const totalWattToBeCharged = amountForCharging / pricePerWatt;
  //       const chargePerMin = (kw * 1000) / 60;
  //       setFieldValue(
  //         "kwh",
  //         totalWattToBeCharged > 0
  //           ? Number(totalWattToBeCharged.toFixed(2).replace(/\.00$/, "")) / 1000
  //           : 0
  //       );
  //       return (totalWattToBeCharged / chargePerMin)
  //         .toFixed(2)
  //         .replace(/\.00$/, "");
  //     }
  //   },
  //   [connector]
  // );

  const setValuesByAmount = useCallback(
    (amount) => {
    if (amount > 0) {
      const {
        fixedFeeCharging = 0,
        fixedFeeStarting = 0,
        kw,
        priceperKW = 0,
        // priceperMin = 0,
        taxPercent = 0,
      } = connector || {};
        const amountForCharging = amount * (1 - Number(taxPercent) / 100) - Number(fixedFeeCharging) - Number(fixedFeeStarting);
        const pricePerWatt = priceperKW / 1000;
        const totalWattToBeCharged = amountForCharging / pricePerWatt;
        const chargePerMin = (kw * 1000) / 60;
        setFieldValue(
          "kwh",
          totalWattToBeCharged > 0
            ? (Number(totalWattToBeCharged.toFixed(2).replace(/\.00$/, "")) / 1000).toFixed(3)
            : 0
        );
        return (totalWattToBeCharged / chargePerMin)
          .toFixed(2)
          .replace(/\.00$/, "");
      
      // const minutes = getMinutes(amount);
      // setFieldValue("minutes", minutes > 0 ? minutes : 0);
    } else {
      // setFieldValue("minutes", 0);
      setFieldValue("kwh", 0)
    }
  }, [connector])

  // const setValuesByMin = useCallback((minutes) => {
  //   const {
  //     fixedFeeCharging = 0,
  //     fixedFeeStarting = 0,
  //     kw,
  //     taxPercent = 0,
  //   } = connector || {};

  //   const kwh = ((Number(kw) / 60) * Number(minutes)).toFixed(2);
  //   const originalAmount = (kwh * 1) + Number(fixedFeeCharging) + Number(fixedFeeStarting);
  //   const amount = originalAmount + (originalAmount * Number(taxPercent) / 100);

  //   if (minutes > 0) {
  //     setFieldValue("kwh", kwh);
  //     setFieldValue("amount", amount, false);
  //   } else {
  //     setFieldValue("amount", 0);
  //     setFieldValue("kwh", 0);
  //   }
  // }, [connector]);

  const setValuesByKw = useCallback((kwh) => {
    const {
      fixedFeeCharging = 0,
      fixedFeeStarting = 0,
      kw,
      priceperKW,
      priceperMin,
      taxPercent = 0,
    } = connector || {};

    const time = Math.ceil(Number(kwh) / Number(kw));
    const cost = Number(priceperKW) ? Number(kwh) * Number(priceperKW) : time * Number(priceperMin);
    const costWithExtra = cost + Number(fixedFeeCharging) + Number(fixedFeeStarting);
    const amount = costWithExtra + (costWithExtra * Number(taxPercent) / 100);
    
    if (kwh > 0) {
      setFieldValue("amount", amount);
      // setFieldValue("minutes", time);
    }else {
      setFieldValue("amount", 0);
      // setFieldValue("minutes", 0);
    }
  }, [connector]);

  useEffect(() => {
    clientHandler(false);
  }, [amount]);

  useEffect(() => {
    firstInputRef?.current?.focus();
    setValues({ amount: 0, emailOrPhone, kwh: "" });
  }, [connector?.connector_id]);

  const _handleChange = (e) => {
    const { name, value } = e.currentTarget;
    // if (name === 'minutes') {
    //   setValuesByMin(+value);
    // }

    if (name === 'amount') {
      setValuesByAmount(+value);
    }

    if (name === 'kwh') {
      setValuesByKw(+value);
    }
    handleChange(e);
  }

  return (
      <Box className="tabs-parent" sx={paymentStyles.tabParent}>
        {error && (
          <Alert
            variant="outlined"
            severity="error"
            sx={{ marginBottom: "20px", color: "red" }}
          >
            Some Error Occured! Please try again.
          </Alert>
        )}
        <Typography variant="caption" mb={3}>
          Cost per KWH: {connector?.rate || "N/A"}
        </Typography>
        <FormikProvider value={paymentFormFormik}>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <TextField
                label="Email or Phone"
                {...getFieldProps("emailOrPhone")}
                error={Boolean(touched.emailOrPhone && errors.emailOrPhone)}
                helperText={touched.emailOrPhone && errors.emailOrPhone}
                sx={{ backgroundColor: "white" }}
                disabled= {true}
              />
              <Divider sx={{ margin: "20px 0" }} />
              <TextField
                required
                inputRef={firstInputRef}
                label="Enter amount to  Charge"
                {...getFieldProps("amount")}
                error={Boolean(touched.amount && errors.amount)}
                onChange={_handleChange}
                helperText={
                  (touched.amount && errors.amount) ||
                  "*Price is inclusive of GST and other service charges"
                }
                sx={{ backgroundColor: "white" }}
              />
              {/* <Divider sx={{ margin: "20px 0" }}></Divider>
              <TextField
                label="Minutes to charge"
                {...getFieldProps("minutes")}
                onChange={_handleChange}
                error={Boolean(touched.minutes && errors.minutes)}
                helperText={"*Minutes are indicative. Actual may vary"}
                sx={{ backgroundColor: "white" }}
              /> */}
              <Divider sx={{ margin: "20px 0" }}></Divider>
              <TextField
                label="Total KW to be charged"
                {...getFieldProps("kwh")}
                onChange={_handleChange}
                sx={{ backgroundColor: "white" }}
              />
              <LoadingButton
                variant="contained"
                loading={isSubmitting}
                disabled={!(isValid && dirty)}
                type="submit"
                sx={{ marginTop: "20px" }}
              >
                Continue
              </LoadingButton>
            </Box>
          </Form>
        </FormikProvider>
      </Box>
  );
};
