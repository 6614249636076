// material
import {
  Box,
  Grid,
  Typography,
  ImageList,
  ImageListItem,
  Stack,
  Paper,
} from "@mui/material";

//images
import OazaEergyMap from "../../image/product/oe-map-laptop.png";
import OazaEnergyMapMobile from "../../image/product/oe-map-mobile-app.png";
import FleetAndWorkPlace from "../../image/product/oe-fleet-and-workplace.png";
import CarsParking from "../../image/product/oe-car-parking.png";
import MobileMap from "../../image/product/mobile-map.png";
import CommercialDestination from "../../image/product/oe-commerical-and-destinations.png";
import CMS from "../../image/product/oe-cms.png";
import BulbCharge from "../../image/product/oe-bulb-charge.png";
import GrowthGraphWithTime from "../../image/product/oe-growth-graph-with-time.png";
import Rating from "../../image/product/oe-rating.png";
import GlobalStandard from "../../image/product/global-standard.jpg";
import MobileWithEv from "../../image/product/oe-mobile-with-ev.png";

import OazaEnergyLogoMobile from "../../image/product/oe-logo-mobile.png";
import OazaEnergyChargerInfoMobile from "../../image/product/oe-charger-info-mobile.png";

// components
import Page from "../Page";
import { StyledButton } from "../common/Styled";
import { StyledTitleSpan } from "./WhyOaza";

const styles = {
  section: {
    // padding: "3rem 0 3rem 0",
  },
  grid: {
    // '&:not(:last-child)': {
    //   borderBottom: '2px solid #eaeaea'
    // }
  },
  span: {
    display: "inline-block",
    position: "absolute",
    top: "77px",
    left: "112px",
    width: "76px",
  },
  img: {
    height: "154px",
    borderRadius: "10px",
  },
};

export default function Product() {
  return (
    <Page title="Dashboard | Oaza-UI">
      <Box
        sx={styles.section}
        component="section"
        className="product-main"
        id="main"
      >
        <StyledTitleSpan className="title-legend">
          Oaza Product's
        </StyledTitleSpan>

        <Typography variant="h4">CAPP</Typography>
        <Typography variant="h5">EV Driver Web and Mobile App</Typography>
        <Typography variant="body2" fontStyle="italic" mb={2}>
          <q>Smart solutions for Sustainable Power</q>
        </Typography>
        <Typography variant="body1" mb={5} color="#777777">
          B2C product we have is www.oaza.energy where we are listing all public
          and private charging points for 2,3,4 wheelers and battery swapping
          stations. The product will have capability to view details,
          availability (available, occupied, fault etc) of charging points and
          will allow user to plan EV journey for longer route.
        </Typography>
        <Grid sx={styles.grid} container spacing={2} mb={5} pb={5}>
          <Grid item xs={12} md={6} lg={6}>
            <Box component="div">
              <ImageListItem>
                <img
                  src={OazaEergyMap}
                  className="cm-product-web-image"
                  alt="Oaza Energy Map"
                  width="100%"
                />
              </ImageListItem>
              <Typography variant="h6">Oaza CAPP Web App</Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={6} lg={6}>
            <ImageListItem>
              <img
                src={OazaEnergyMapMobile}
                className="cm-product-mobile-image"
                alt="Charge Miles Mobile Map"
                width="auto"
                height={350}
              />
            </ImageListItem>
            <Typography variant="h6">Oaza CAPP Mobile App</Typography>
          </Grid>
        </Grid>
        <Grid sx={styles.grid} container mb={5}>
          <Grid item xs={12} md={12} lg={12}>
            <Box component="div">
              <Typography variant="body1" color="#777777">
                For B2B we have built a Charging Station Management system for
                apartments, hotel, offices, and EV fleet management operators to
                manage their fleets. The application can manage charging
                stations, remotely start , stop, and schedule charging and also
                capable of integrating with any external system.
              </Typography>
            </Box>
          </Grid>
        </Grid>
        <Grid container spacing={2} mb={8} justifyContent="space-around">
          <Grid item lg={12} mb={3}>
            <Typography variant="h4">Oaza Connect</Typography>
            <Typography variant="h5">
              EV Charging Management Solutions
            </Typography>
            <Typography variant="body2" fontStyle="italic">
              <q>Manage and Monetise your charging point</q>
            </Typography>
            {/* <Typography variant="caption">Applications from residential to commercial developments, multiple chargers solutions. </Typography> */}
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <Grid container mb={2} justifyContent="center">
              <Grid item lg={12} sm={12} md={12}>
                <Typography variant="h6" textTransform="uppercase">
                  Fleet and Workplace
                </Typography>
              </Grid>
              <Grid item lg={12}>
                <ImageList cols={1}>
                  {imageList.map(
                    (image) =>
                      image.id === 1 && (
                        <ImageListItem key={image.id}>
                          <img
                            src={`${image.img}`}
                            srcSet={`${image.img}?w=150&h=150&fit=crop&auto=format&dpr=2 2x`}
                            alt={image.title}
                            loading="lazy"
                          />
                        </ImageListItem>
                      )
                  )}
                </ImageList>
              </Grid>
              <Grid item>
                <Typography variant="body2" textAlign="justify">
                  Smart EV charging for all businesses of all sizes for fleet
                  and employee use
                </Typography>
              </Grid>
            </Grid>
            <Grid
              container
              className="cars-parking"
              id="cars-parking-section"
              justifyContent="center"
            >
              <Grid item lg={12} sm={12} md={12}>
                <Typography variant="h6" textTransform="uppercase">
                  Car Parks and Residentials
                </Typography>
              </Grid>
              <Grid item lg={12} md={12}>
                <ImageList cols={1}>
                  {imageList.map(
                    (image) =>
                      image.id === 2 && (
                        <ImageListItem key={image.id}>
                          <img
                            src={`${image.img}`}
                            srcSet={`${image.img}?w=150&h=150&fit=crop&auto=format&dpr=2 2x`}
                            alt={image.title}
                            loading="lazy"
                          />
                        </ImageListItem>
                      )
                  )}
                </ImageList>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={6} lg={3} position="relative">
            <Grid item lg={12}>
              <ImageList cols={1}>
                {imageList.map(
                  (image) =>
                    image.id === 4 && (
                      <ImageListItem key={image.id}>
                        <img
                          src={`${image.img}`}
                          srcSet={`${image.img}?w=150&h=150&fit=crop&auto=format&dpr=2 2x`}
                          alt={image.title}
                          loading="lazy"
                        />
                      </ImageListItem>
                    )
                )}
              </ImageList>
            </Grid>
            <Grid item lg={12} mb={2}>
              <Typography variant="body1" fontWeight={600}>
                Future Planning for EV Fleet and workplace has never been
                easier. Workplace has never been easier. Oaza is the right
                charging management solution for your business needs
              </Typography>
            </Grid>
            <Grid item lg={12}>
              <Typography variant="body1" color="#777777" textAlign="justify">
                Destination and public charging stations are becoming an
                increasingly important part of EV charging network in india,
                with the Government announcing a policy to install thousands of
                charge points by 2030. Application to manage fast and rapid
                charging for public, large-scale, and nationwide projects.
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <Grid container justifyContent="center">
              <Grid item lg={12} md={12}>
                <ImageList cols={1}>
                  {imageList.map(
                    (image) =>
                      image.id === 5 && (
                        <ImageListItem key={image.id}>
                          <img
                            src={`${image.img}`}
                            srcSet={`${image.img}?w=150&h=150&fit=crop&auto=format&dpr=2 2x`}
                            alt={image.title}
                            loading="lazy"
                          />
                        </ImageListItem>
                      )
                  )}
                </ImageList>
              </Grid>
              <Grid item lg={12}>
                <ul
                  className="Mui-List text-justify"
                  aria-label="Commerical and Destination List"
                >
                  <li>
                    <Typography variant="body1">
                      Generate a new revenue stream with fees
                    </Typography>{" "}
                  </li>
                  <li>
                    <Typography variant="body1">
                      Increase the amount of dwell time customer spends in the
                      area
                    </Typography>
                  </li>
                  <li>
                    <Typography variant="body1">
                      Increase in-store retail spend from customers waiting for
                      their car to charge
                    </Typography>{" "}
                  </li>
                  <li>
                    <Typography variant="body1">
                      Attract and retain customers and employees by showing a
                      commitment to sustainability
                    </Typography>
                  </li>
                </ul>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          container
          mb={8}
          className="cm-cms-platform-section"
          gap={{ sm: 4, lg: 0, xs: 4 }}
        >
          <Grid item xs={12} md={6} lg={6}>
            <Typography
              textAlign={{ lg: "start", md: "center", sm: "center" }}
              textTransform="uppercase"
              variant="h4"
            >
              Oaza Connect Dashboard and Admin portal
            </Typography>
            <Typography
              variant="subtitle2"
              mb={4}
              color="#555"
              textAlign={{ lg: "start", md: "center", sm: "center" }}
            >
              Enabling you to manage and Operate your own charging network
            </Typography>
            <Typography
              variant="body1"
              color="#777777"
              textAlign={{ lg: "start", md: "justify", sm: "justify" }}
            >
              The management system is designed for operators to manage the
              charge points and the charging service to all users. The future of
              charging is smart, and our management system is equipped with
              future-proof features. A cloud-based central system for charge
              operators to manage charge points and monitor charging service.
              Status monitor, charge record, prices management, firmware upgrade
              and remote diagnosis are offered on the platform.
            </Typography>
          </Grid>
          <Grid item xs={12} md={6} lg={6}>
            <span className="image">
              <ImageListItem>
                <img src={CMS} alt="CMS Platform" />
              </ImageListItem>
            </span>
          </Grid>
        </Grid>
        <Grid
          container
          mb={8}
          className="cm-mobile-app-section"
          gap={{ sm: 4, lg: 0, xs: 4 }}
        >
          <Grid
            item
            xs={12}
            md={6}
            lg={6}
            order={{ lg: 0, md: 1, sm: 1, xs: 1 }}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <Box as="div" sx={{position:"relative"}} className="img-wrapper">
            <Box as="span" className="image">
              <Box as="img"
              sx={{ height: {xs: "180px",sm:"310px"},position:"relative", left: {xs: "0px"}}}
                src={OazaEnergyLogoMobile}
                className="cm-product-mobile-image"
                alt="CM Mobile App Platform"
              />
            </Box>
            <Box as="span" className="image">
              <Box as="img"
                  sx={{ height: {xs: "180px",sm:"310px"},position:"relative", zIndex:10,left:{xs: "-13px"}}}
                src={OazaEnergyChargerInfoMobile}
                className="cm-product-mobile-image"
                alt="CM Mobile App Platform"
              />
            </Box>
            </Box>

          </Grid>
          <Grid item xs={12} md={6} lg={6}>
            <Typography
              textAlign={{ lg: "start", md: "center", sm: "center" }}
              textTransform="uppercase"
              variant="h4"
            >
              Oaza connect ev driver mobile app
            </Typography>
            <Typography
              variant="subtitle2"
              mb={4}
              color="#555"
              textAlign={{ lg: "start", md: "center", sm: "center" }}
            >
              Manage your charging activity whilst benefitting from an array of
              features via the CM mobile app
            </Typography>
            <Typography
              variant="body1"
              color="#777777"
              textAlign={{ lg: "start", md: "justify", sm: "justify" }}
            >
              The charging App connects EV drivers with charging stations so
              that they can easily find a charger and enjoy the charging
              services. It enables the users to do location searches, start/stop
              charging, charging monitor and payment settlement. All is done on
              a mobile phone in a customer friendly way.
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={4} mb={8} className="cm-solution-section">
          <Grid item xs={12} md={12} lg={12}>
            <Typography
              variant="h5"
              mb={1}
              textTransform="uppercase"
              fontWeight={600}
            >
              Our Powerful Solution Leverages
            </Typography>
            <Typography
              variant="body1"
              mb={4}
              color="#777777"
              textAlign="start"
            >
              Our Charging Point Management System delivers a seamless, fully
              web and mobile-enabled solution for Charging Point Operators, that
              allows them to perform time-consuming tasks online from their desk
              resulting in:
            </Typography>
          </Grid>
          <Grid item xs={12} md={4} lg={4}>
            <Stack direction="column">
              <span className="image-oaza-benefits">
                <ImageListItem>
                  <img src={BulbCharge} alt="" />
                </ImageListItem>
              </span>
              <Paper elevation={1}>
                <Typography variant="subtitle1">
                  Operational Efficiency improvement
                </Typography>
              </Paper>
            </Stack>
          </Grid>
          <Grid item xs={12} md={4} lg={4}>
            <Stack direction="column">
              <span className="image image-oaza-benefits">
                <ImageListItem>
                  <img src={Rating} alt="" />
                </ImageListItem>
              </span>
              <Paper elevation={1}>
                <Typography variant="subtitle1">
                  Improved Customer Experience
                </Typography>
              </Paper>
            </Stack>
          </Grid>
          <Grid item xs={12} md={4} lg={4}>
            <Stack direction="column">
              <span className="image-oaza-benefits">
                <ImageListItem>
                  <img src={GrowthGraphWithTime} alt="" />
                </ImageListItem>
              </span>
              <Paper elevation={1}>
                <Typography variant="subtitle1">
                  Savings every year for Electricity and other overheads
                </Typography>
              </Paper>
            </Stack>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={12} lg={12} md={12}>
            <Typography
              variant="h5"
              mb={1}
              textTransform="uppercase"
              fontWeight={600}
            >
              We Support Global Standards
            </Typography>
            <Typography
              variant="body1"
              mb={4}
              color="#777777"
              textAlign="center"
            >
              Our platform makes it easy to integrate and operate a network with
              charging points equipment from multiple OEMs
            </Typography>
          </Grid>
          <Grid item xs={12} lg={12} md={12}>
            <span className="image">
              <ImageListItem>
                <img
                  src={GlobalStandard}
                  className="cm-product-global-standard"
                  alt="CM Global Standard"
                />
              </ImageListItem>
            </span>
          </Grid>
        </Grid>
      </Box>
      <Box className="section-request-demo" component="section">
        <Box className="request-demo-grid">
          <Box className="request-demo-layout layout-grid layout-grid-form">
            <Box className="grid-node">
              <Typography variant="h4">Request CMS software Demo</Typography>
            </Box>
            <Box className="grid-node">
              <StyledButton
                variant="contained"
                href="https://calendly.com/oazaenergy/30min"
                target="_blank"
              >
                Schedule Demo
              </StyledButton>
            </Box>
          </Box>
        </Box>
      </Box>
    </Page>
  );
}

const imageList = [
  { id: 1, img: FleetAndWorkPlace, title: "Car" },
  { id: 2, img: CarsParking, title: "Cars Parking" },
  { id: 3, img: MobileMap, title: "Mobile Map" },
  { id: 4, img: MobileWithEv, title: "Mobile Map Overlay" },
  { id: 5, img: CommercialDestination, title: "Commercial and Destinations" },
];
