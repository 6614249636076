import React, { useEffect, useContext } from 'react';
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';

import OazaPlayStore from '../../image/logo/app_icon_black.png';
import OazaAppStore from '../../image/logo/app_icon_white.png';

import { StyledButton } from '../../component/common/Styled';
import { Context } from '../../context';

function MobileAppInstallWidget() {
    const { maps } = useContext(Context);
    const isIOS = typeof navigator !== 'undefined' && /iPad|iPhone|iPod/.test(navigator.userAgent);
    const closeWidget = () => {
        const { setMapsData } = maps;
        setMapsData((prev) => ({
            ...prev,
            widget: false
        }))
    }

    useEffect(() => {
        const { setMapsData } = maps;
        setMapsData((prev) => ({
            ...prev,
            widget: true
        }))
    }, []);

    return (<>
        {maps.mapsData?.widget && 
        <Box className="mobile-install-widget">
            <Box className="widget">
                <IconButton onClick={closeWidget} aria-label='close widget'> <CloseOutlinedIcon /> </IconButton>
                <picture>
                    <source media="(max-width:600px)" type="image/webp" />
                    <img src={!isIOS ? OazaPlayStore : OazaAppStore} alt="Play Store Logo" />
                </picture>
                <Paper elevation={0}>
                    <Typography variant="subtitle2">Oaza</Typography>
                </Paper>
                <StyledButton variant="contained" target="_blank" href={!isIOS ? process.env.REACT_APP_GOOGLE_PLAY_STORE_URL : process.env.REACT_APP_IOS_APP_STORE_URL}>
                    Install 
                </StyledButton>
            </Box>
        </Box>}
    </>)
}

export default React.memo(MobileAppInstallWidget);