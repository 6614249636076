import React from 'react'
import { useMediaQuery, styled, useTheme, SvgIcon, Link } from '@mui/material';
import { StyledFab } from '../common/Styled';
import Constant from '../../util/constants';

import { ReactComponent as ContributionSVG } from "../../image/app_icon_contribution.svg";
import { Link as RouterLink } from 'react-router-dom';

const CustomizedStyledFab = styled('div')`
  bottom: 2px;
  left: 50%;
  width: 100%;
  transform: translateX(-50%);
  background-color: #1f2731;
  color: #fff;
  letter-spacing: .5px;
  padding: 5px;
  font-size: 11px;
  position: absolute;
  max-height: 24px;
  overflow: auto;
`;

const StyledDisclaimer = styled('div')(({ theme }) => ({
  backgroundColor: "green",
  bottom: 2
}));

const styles = {
  fab: {
    '&:hover': {
      backgroundColor: 'var(--app-color) !important'
    }
  },
  svg: {
    color: '#fff',
  }
}

function Disclaimer() {
  const theme = useTheme();
  const belowMdScreen = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <CustomizedStyledFab variant="extended" size="large">
      Disclaimer – Chargers Information that are not managed by Oaza is sourced from open source. The accuracy of such data is not guaranteed or warranted in any way.
    </CustomizedStyledFab>
  )
};

export default React.memo(Disclaimer);