// material
import { Box, Button, Container, Paper } from "@mui/material";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import EmailIcon from "@mui/icons-material/Email";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { CMBreadCrumb } from "../common/OazaBreadCrumb";
import { CMFooter } from "../common/OazaFooter";
// components
import Page from "../Page";
// ----------------------------------------------------------------------

export default function ContactUs() {
  return (
    <Page title="Dashboard | Oaza-UI">
        <Box
          className="contact-Us-Main-Container"
          sx={{
            display: "flex",
            flexDirection: { xs: "column", md: "row" },
            justifyContent: "center",
            "& > :not(style)": {
              m: { md: 3, xs: "auto" },
              mb: { xs: 3 },
              width: "300px",
              padding: "60px 45px 60px 45px",
              backgroundColor: "#F7F9FA",
            },
          }}
        >
          <Paper elevation={3}>
            <Button
              sx={{
                backgroundColor: "var(--app-color)",
                fill: "#FFFFFF",
                color: "#FFFFFF",
                fontSize: "56px",
                borderRadius: "50%",
                padding: "0.5em",
                marginBottom: "15px",
                "&:hover": {
                    backgroundColor: "var(--app-color-dark)" 
                }
              }
            }
            >
              <LocalPhoneIcon sx={{ fontSize: "56px" }} />
            </Button>
            <h4 style={{ marginBottom: "0px", color: "#2C2C2C" }}>
              Our Contact
            </h4>
            <p
              style={{
                color: "#848484",
                marginBottom: "4px",
                textAlign: "left",
              }}
            >
              {/* <a
                href="tel:+91-8071295355"
                style={{
                  color: "#848484",
                  textDecoration: "none",
                  textAlign: "left",
                }}
              >
                India: +91 963-020-1482 <br></br>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;+91
                813-044-8154
              </a> */}
            </p>
            <p
              style={{
                color: "#848484",
                marginBottom: "4px",
                textAlign: "center",
              }}
            >
              <a
                href="tel:+447557906634"
                style={{
                  color: "#848484",
                  textDecoration: "none",
                  textAlign: "center",
                }}
              >
                UK: +44 7557 906634
              </a>
            </p>
            <p
              style={{
                marginBottom: "4px",
                textAlign: "center",
              }}
            >
               <a
                href="tel:+91-8071295355"
                style={{
                  color: "#848484",
                  textDecoration: "none",
                  textAlign: "left",
                }}
              >
                India: +91 8071295355
              </a>
            </p>
          </Paper>
          <Paper elevation={3}>
            <Button
              sx={{
                backgroundColor: "var(--app-color)",
                fill: "#FFFFFF",
                color: "#FFFFFF",
                fontSize: "56px",
                borderRadius: "50%",
                padding: "0.5em",
                marginBottom: "15px",
                "&:hover": {
                  backgroundColor: "var(--app-color-dark)" 
              }
              }}
            >
              <EmailIcon sx={{ fontSize: "56px" }} />
            </Button>
            <h4 style={{ marginBottom: "0px", color: "#2C2C2C" }}>Mail Us</h4>
            <p style={{ color: "#848484", marginBottom: "4px" }}>
              <a
                href="mailto:info@oaza.energy"
                style={{
                  color: "#848484",
                  textDecoration: "none",
                }}
              >
                info@oaza.energy
              </a>
            </p>
            <p style={{ color: "#848484", marginBottom: "4px" }}>
              <a
                href="mailto:support@oaza.energy"
                style={{
                  color: "#848484",
                  textDecoration: "none",
                }}
              >
                support@oaza.energy
              </a>
            </p>
          </Paper>
          <Paper elevation={3}>
            <Button
              sx={{
                backgroundColor: "var(--app-color)",
                fill: "#FFFFFF",
                color: "#FFFFFF",
                fontSize: "56px",
                borderRadius: "50%",
                padding: "0.5em",
                marginBottom: "15px",
                "&:hover": {
                  backgroundColor: "var(--app-color-dark)" 
              }
              }}
            >
              <LocationOnIcon sx={{ fontSize: "56px" }} />
            </Button>
            <h4 style={{ marginBottom: "0px", color: "#2C2C2C" }}>
              Our Location
            </h4>
            <p style={{ color: "#848484", marginBottom: "4px",textAlign:"left"}}>
              UK Address: Oaza Energy Ltd 61 rhydypenau road , Cardiff, CF23 6PY
            </p>
            <p style={{ color: "#848484", marginBottom: "4px", textAlign:"left"}}>
            India Address:
            Oaza Energy Private Limited A19, Sai Jyot Park, Nakhate wasti, Rahatani-Pimple Saudagar Road, Pune 411017.
            </p>
          </Paper>
        </Box>
    </Page>
  );
}
