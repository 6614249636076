import React from "react";
import { styled } from "@mui/system";
import Box from "@mui/material/Box";
import { Container } from "@mui/material";
import step1 from "../../image/delete-user-account-steps/step-1.jpeg";
import step2 from "../../image/delete-user-account-steps/step-2.jpeg";
import step3 from "../../image/delete-user-account-steps/step-3.jpeg";

const DeleteUserAccount = () => {
  const ContainerStyle = styled("div")({
    textAlign: "left",
    "& #zap_map_content ": {
      // paddingTop: '90px'
    },
    "& #block-zap-map-content ": {
      minHeight: "50vh",
    },
    "& .title-block--centered": {
      textAlign: "center",
    },
    "& .title-block__title ": {
      margin: 0,
      padding: 0,
      width: "100%",
      lineHeight: "1.1",
    },
    "& .html__table ": {
      padding: "20px 0",
      maxWidth: "100%",
      overflow: "hidden",
    },

    "& .title-block__overlay ": {
      width: "100%",
      minHeight: "calc(70vh - 90px)",
      position: "relative",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    "& .title-block__content ": {
      padding: "0 1.3rem",
      maxWidth: "100%",
    },

    "& h5": {
      fontSize: "1.5em",
      fontWeight: "bold",
      margin: ".5em 0",
    },
    "& .note": {
      fontSize: ".8em",
    },
    "& .title-block--centered .title-block__text": {
      margin: "0 auto !important",
      maxWidth: "800px",
    },
    "& .delete-step": {
      height: "600px",
    },
  });
  return (
    <ContainerStyle>
      <main role="main" id="zap_map_content">
        <div className="region region--highlighted">
          <div data-drupal-messages-fallback="" className="hidden"></div>
        </div>

        <Box className="layout-content">
          <div className="region region--content">
            <div id="block-zap-map-content">
              <div className="template__container dynamic__page clearfix">
                <Box
                  id="title-block-53-1"
                  className="title-block title-block--centered title-block--custom-bg-color title-block--custom-text-color"
                  sx={{ backgroundColor: "var(--app-color)", color: "#f3f4f7" }}
                >
                  <div className="title-block__wrapper">
                    <div className="title-block__overlay">
                      <div className="title-block__content container">
                        <div className="title-block__text">
                          <Box
                            sx={{ fontSize: { xs: "52px", lg: "86px" } }}
                            as="h1"
                            className="title-block__title page__title"
                          >
                            How to delete your Oaza account
                          </Box>
                          <Box
                            as="p"
                            sx={{ fontSize: { lg: "1.7rem" } }}
                            className="field--hero-summary"
                          >
                            Find full instructions below
                          </Box>
                        </div>
                      </div>
                    </div>
                  </div>
                </Box>
                <Container>
                  <div
                    id="next__section"
                    className="html__table clearfix ck-content ck-content--frontend"
                  >
                    <div className="container">
                      <h4>
                        Please use the following instructions to delete your
                        Oaza user account within the Oaza app.
                      </h4>
                      <p>&nbsp;</p>
                      <h5>Step 1:</h5>
                      <img
                        src={step1}
                        alt="go to profile section"
                        className="delete-step"
                      />
                      <p>
                        In the Oaza app, go to the profile section by selecting{" "}
                        <strong>'Profile'</strong> from the menu bar at the
                        bottom of the app display.
                      </p>
                      <p>&nbsp;</p>
                      <h5>Step 2:</h5>
                      <p>
                        Select <strong>'Profile'</strong> from the menu.
                      </p>
                      <p>&nbsp;</p>
                      <h5>Step 3:</h5>
                      <img
                        src={step2}
                        alt="click on the delete my account button"
                        className="delete-step"
                      />
                      <p>
                        Within the profile settings you will find a{" "}
                        <strong>'Delete account</strong>' button.&nbsp;
                        <br />
                        Select the 'Delete account' button to proceed with the
                        deletion of your Oaza account.
                      </p>
                      <p>&nbsp;</p>
                      <h5>Step 4:</h5>
                      <img
                        src={step3}
                        alt="click on the delete my account button"
                        className="delete-step"
                      />
                      <p>
                        A pop-up message will appear asking{" "}
                        <strong>
                          'Are you sure you want to delete your account?"
                        </strong>
                      </p>
                      <p>
                        Confirm deletion by selecting <strong>'Delete'</strong>
                      </p>
                      <p>&nbsp;</p>
                      <h5 className="note">
                        Note:
                        <em>
                          You will lose all of your route plans, saved filters,
                          EV models, Zap-Pay history, saved payment cards,
                          Zap-Home/Zap-Work points and any other personal data
                          stored in your account if you chose to proceed with
                          deletion.
                        </em>
                      </h5>
                      <p>&nbsp;</p>
                      <h5 dir="ltr">Step 5:</h5>
                      <p dir="ltr">
                        You will receive an email to confirm we have received
                        your request.&nbsp; Once we have completed the account
                        deletion you will receive a second email to confirm
                        this.
                        <br />
                        &nbsp;
                      </p>
                      <h4 dir="ltr">
                        If you are unable to access the Oaza app to delete your
                        account, please contact our Support team at&nbsp;
                        <a href="mailto:support@oaza.energy">
                          support@oaza.energy
                        </a>
                      </h4>

                      <p dir="ltr">
                        <em>
                          <strong>Oaza's </strong>
                        </em>
                        <a href="https://www.oaza.energy/privacy-policy">
                          <em>
                            <strong>privacy policy</strong>&nbsp;
                          </em>
                        </a>
                        <em>
                          <strong>
                            contains a detailed explanation of how our user data
                            is handled.
                          </strong>
                        </em>
                      </p>
                      <p>
                        <strong>
                          Here is an overview of how long we retain your data
                          after account deletion:
                        </strong>
                      </p>
                      <p dir="ltr">
                        For inactive users, we will retain and use your
                        information solely for the purposes set out in our&nbsp;
                        <a href="https://www.oaza.energy/privacy-policy">
                          Privacy Policy
                        </a>{" "}
                        and to the extent necessary to comply with our legal
                        obligations, resolve disputes, enforce our agreements
                        and protect Oaza's legal rights.
                      </p>
                      <p dir="ltr">
                        We may retain personal data of inactive accounts for a
                        reasonable period to allow us to respond to any follow
                        up enquiries or complaints. To determine appropriate
                        retention periods for personal data, we consider the
                        amount, nature, and sensitivity of the personal data,
                        and the potential risk of harm from unauthorised use or
                        disclosure of your personal data. In the majority of
                        cases this period is less than 24 months.
                      </p>
                      <p dir="ltr">
                        Where registered users subsequently withdraw their
                        content and/or wish to remove all of their personal
                        information from our Sites and Services, we will only
                        continue to retain the registration details (including
                        username and password) as part of a suppression list to
                        ensure we comply with all 'do not contact' requests, to
                        allow us to respond to any follow up enquiries or
                        complaints, and to comply with our legal obligations,
                        resolve disputes, enforce our agreements and protect
                        Oaza's legal rights.
                      </p>
                    </div>
                  </div>
                </Container>
              </div>
            </div>
          </div>
        </Box>
      </main>
    </ContainerStyle>
  );
};

export default DeleteUserAccount;
