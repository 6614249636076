import React, { useMemo } from 'react';
import { 
    Dialog, 
    DialogActions, 
    DialogContentText, 
    DialogContent,
    DialogTitle,
    Button,
    useMediaQuery,
    IconButton,
} from "@mui/material";
import LoadingButton from '@mui/lab/LoadingButton';
import { useTheme } from '@mui/material/styles';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';

import Constant from '../../util/constants';

const { label } = Constant;

function MuiDialog({ open, onClose, title, children, actionContent, onOk, loading, actions, ...props }){
    const { scroll } = props;
    const theme = useTheme();
    const belowSmScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const styles = useMemo(() => ({
        dialogAction: {
            flexDirection: belowSmScreen ? 'column': 'row',
            '& > button': belowSmScreen && {
                '&': {
                    marginBottom: '0.925rem'
                },
                '&:first-child': {
                    marginTop: '0.925rem'
                },
                '&:last-child': {
                    marginLeft: 0
                }
            }
        },
        closeIcon: {
            position: 'absolute',
            top: 10,
            right: 10
        },
        okButton: {
           '&:hover': {
               backgroundColor: 'var(--app-color)'
           }
        }
    }), [belowSmScreen])

    const actionsContent = useMemo(() => (<>
        <Button variant='outlined' className='round' fullWidth={belowSmScreen} onClick={onClose}>{label.CANCEL}</Button>
        <LoadingButton variant='contained' loading={loading} sx={styles.okButton} fullWidth={belowSmScreen} onClick={onOk} className='round'>{label.APPLY_FILTER}</LoadingButton>
    </>), [onOk]);

    return (
        <Dialog
        open={open}
        onClose={onClose}
        aria-labelledby={`scroll-dialog-${title}`}
        aria-describedby="scroll-dialog-description"
        {...props}
        >
            {title !== '' && <DialogTitle id={`scroll-dialog-${title}`}>{title}</DialogTitle>}
            <DialogContent dividers={scroll === 'paper'}>
                <DialogContentText
                 id="scroll-dialog-description"
                 tabIndex={-1}
                >
                {children}
                <IconButton onClick={onClose} sx={styles.closeIcon} aria-label="close dialog">
                    <CloseOutlinedIcon />
                </IconButton>
                </DialogContentText>
            </DialogContent>    
            {actions && 
            <DialogActions className={actionContent && 'MuiAction-root-custom'} sx={styles.dialogAction}>
                {!actionContent ? actionsContent : actionContent}
            </DialogActions>}
        </Dialog>
    )
}

MuiDialog.defaultProps = {
    scroll: 'paper',
    title: '',
    maxWidth: 'xs',
    actions: true,
    onOk: () => {}
  };

export default MuiDialog