import React, { useState, useEffect, useContext } from 'react';
import { Paper, Box, Button, Typography, Link } from '@mui/material';
import { MHidden, MuiDialog } from '../@material-extend';
import { Link as RouterLink } from 'react-router-dom';

import LoginBanner from "../../image/oaza-login-banner.png";
import GooglePlay from "../../image/android-download-white.svg";
import AppStore from '../../image/app-store.png';
import AuthSocial from './AuthSocial';
import LoginForm from '../authentication/login/LoginForm';
import RegisterForm from '../authentication/register/RegisterForm';
import Constant from '../../util/constants';
import { Context } from '../../context';
import HttpService from '../../services/HttpService';
import Token from '../../util/Token';

const { label, action, methods } = Constant;
const styles = {
    authDialog: {
        '& .MuiDialogContent-root': {
            borderTop: 'none',
            borderBottom: 'none',
            '& > p.MuiDialogContentText-root': {
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
            },
            '& .MuiButtonBase-root': {
                flex: '50%',
                fontSize: '15px !important',
                '& ~ span': {
                    flex: '50%',
                    '& button': {
                        fontSize: '15px !important',
                        '& svg': {
                            marginBottom: '-7px !important'
                        }
                    }
                }
            },
            '& .MuiLoadingButton-root': {
                mt: 1
            }
        },
    },
    leftSection: {
        position: 'relative',
        backgroundImage: `url(${LoginBanner})`,
        width: '40%',
        minHeight: '450px',
        margin: '-16px -24px',
        borderRadius: '0',
        backgroundSize: 'cover',
        backgroundPosition: 'top'
    },
    rightSection: {
        width: '60%'
    },
    link: {
        textAlign: 'center',
        marginTop: '8px'
    },
    typographyh5: {
        mt: 3,
        color: 'var(--app-color)',
        borderBottom: '3px solid var(--app-color)'
    }
}

const actionContent = (<>
    <Typography variant="subtitle1">App Available now on App Store</Typography>
    <Box className="cm-apps">
        <a href={process.env.REACT_APP_IOS_APP_STORE_URL} target="_blank" rel="noopener noreferrer">
            <span className="sr-only">Get it on App Store</span>
            <picture>
            <source media='' type="image/svg" />
            <img className="image" src={AppStore} style={{ filter: 'invert(1)' }} alt="Get it on App Store" loading="lazy" />
            </picture>
        </a>
    </Box>
    <Box className="cm-apps">
        <a href={process.env.REACT_APP_GOOGLE_PLAY_STORE_URL} target="_blank" rel="noopener noreferrer">
            <span className="sr-only">Get it on Google Play</span>
            <picture>
                <source media='' type="image/svg" />
                <img className="image" src={GooglePlay} alt="Get it on Google Play" loading="lazy" />
            </picture>
        </a>
    </Box>
</>)

export default function AuthPopup(){
    const { auth:{ authenticated, setAuthenticated }, user } = useContext(Context);
    const [popUpState, setPopUpState] = useState({
        openPopup: !1,
        toggelForm: label.LOGIN,
    });

    const handleEvents = (type) => () => {
        if(action.MODAL_CLOSE === type) {
            setPopUpState({
                ...popUpState,
                openPopup: !1
            })
            setAuthenticated({
                ...authenticated,
                authPopup: !1
            });
        }

        if(action.TOGGLE_BUTTON ===  type)
            setPopUpState({
                ...popUpState,
                toggelForm: popUpState.toggelForm === label.LOGIN ? label.REGISTER : label.LOGIN
            })
    }
    
    useEffect(() => {
        setPopUpState({
            ...popUpState,
            openPopup: authenticated.authPopup
        })
    }, [authenticated.authPopup, popUpState]);

    useEffect(() => {
        if(authenticated.authElementType) {
            const userData = Token.parsedToken();
            const getUser = async (userId) => {
                const userRes = await HttpService(
                    methods.GET_USER,
                    { queryParameters: { userId } }
                )
                user.setUserDetail(userRes.data);
            }

            if (userData && userData.success && userData.data) {
                const { data: { _id } } = userData;
                getUser(_id);
            }
        }
    }, [authenticated.authElementType, user])

    useEffect(() => {
        let popUpStatus = localStorage.getItem('v1_oaza_popup_status');
        let isExpired = popUpStatus && new Date() > new Date(popUpStatus);
        if(!popUpStatus || isExpired) {
            localStorage.setItem('v1_oaza_popup_status', new Date().setHours(24));
            setAuthenticated({
                ...authenticated,
                authPopup: !0
            });
        }
    }, [authenticated, setAuthenticated]);

    return (
        <MuiDialog 
        open={popUpState.openPopup} 
        onClose={handleEvents(action.MODAL_CLOSE)} 
        maxWidth="md" 
        fullWidth={true} 
        actions={true}
        actionContent={actionContent} 
        sx={styles.authDialog}
        className="auth-popup"
        >
            <MHidden width='lgDown'>
                <Paper sx={styles.leftSection} className="popup-left-section"></Paper>
            </MHidden>
            <Paper elevation={0} sx={styles.rightSection} className="popup-right-section">
                <Typography variant="h5" sx={styles.typographyh5}>{popUpState.toggelForm}</Typography>
                <Typography variant="body2" align="center" sx={{ color: 'text.secondary', mt: 2, mb: 2 }}>
                    By registering, I agree to Oaza Energy&nbsp;
                    <Link to="/terms-of-services" underline="always" sx={{ color: 'text.primary' }} component={RouterLink}>
                    Terms of Service
                    </Link>
                    &nbsp;and&nbsp;
                    <Link to="/privacy-policy" underline="always" sx={{ color: 'text.primary' }} component={RouterLink}>
                    Privacy Policy
                    </Link>
                    .
                </Typography>
                <AuthSocial direction='row'/>
                {popUpState.toggelForm === label.LOGIN && <LoginForm />}
                {popUpState.toggelForm === label.REGISTER && <RegisterForm />}
                <Box sx={styles.link}>
                    <span>{popUpState.toggelForm === label.LOGIN ? label.NEW_ACCOUNT : label.ALREADY_HAVE_ACCOUNT}</span>
                    <Button href='#' size="small" onClick={handleEvents(action.TOGGLE_BUTTON)}>
                        {popUpState.toggelForm === label.LOGIN ? label.REGISTER : label.LOGIN}
                    </Button>
                </Box>
            </Paper>
        </MuiDialog>
    )
}

