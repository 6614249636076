import * as Yup from "yup";

export const validatePhone = (phone) => {
    return Yup.string()
      .test((phone) => {
        return /^\d{10}$/.test(phone);
      })
      .isValidSync(phone);
  };
  export const validateEmail = (email) => {
    return Yup.string().email().isValidSync(email);
  };