import { Link as RouterLink } from "react-router-dom";
// material
import { styled } from "@mui/material/styles";
import { Box, Card, Link, Container, Typography } from "@mui/material";
// layouts
import AuthLayout from "../../layout/AuthLayout";
// components
import { MHidden } from "../@material-extend";
import ForgetPasswordForm from "../authentication/forget-password/ForgetPasswordForm";
import login_banner from "../../image/oaza-login-banner.png";
// ----------------------------------------------------------------------

const SectionStyle = styled(Card)(({ theme }) => ({
  width: "100%",
  maxWidth: 464,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  margin: theme.spacing(2, 0, 2, 2),
}));

const ContentStyle = styled("div")(({ theme }) => ({
  maxWidth: 480,
  margin: "auto",
  display: "flex",
  flexDirection: "column",
}));

// ----------------------------------------------------------------------

export default function ForgetPassword() {
  return (
    <Container>
      <AuthLayout>
        Don’t have an account? &nbsp;
        <Link
          underline="none"
          variant="subtitle2"
          component={RouterLink}
          to="/register"
        >
          Get Started
        </Link>
      </AuthLayout>
      <Box sx={{display:"flex", alignItems:"center",justifyContent:"center",gap:"24px"}} >
      <MHidden width="mdDown">
        <SectionStyle>
          <Typography variant="h5" sx={{ px: 5, mt: 10, mb: 5 }}>
            Providing Best Experience For Charging Point Services
          </Typography>
          <img alt="register" src={login_banner} />
        </SectionStyle>
      </MHidden>

      <Container>
        <ContentStyle>
          <Box sx={{ mb: 5, mt:{xs:8, md:0} }}>
            <Typography variant="h6" gutterBottom>
              Forget your password?
            </Typography>
            <Typography sx={{ color: "text.secondary" }}>
              Enter your details below.
            </Typography>
          </Box>

          <ForgetPasswordForm />

          <Typography
            variant="body2"
            align="center"
            sx={{ color: "text.secondary", mt: 3 }}
          >
            By registering, I agree to Oaza&nbsp;
            <Link
              to="/terms-of-services"
              underline="always"
              sx={{ color: "text.primary" }}
              component={RouterLink}
            >
              Terms of Service
            </Link>
            &nbsp;and&nbsp;
            <Link
              to="/privacy-policy"
              underline="always"
              sx={{ color: "text.primary" }}
              component={RouterLink}
            >
              Privacy Policy
            </Link>
            .
          </Typography>

          <MHidden width="smUp">
            <Typography variant="subtitle2" sx={{ mt: 3, textAlign: "center" }}>
              Already have an account?&nbsp;
              <Link to="/login" component={RouterLink}>
                Login
              </Link>
            </Typography>
          </MHidden>
        </ContentStyle>
      </Container>
      </Box>
    </Container>
  );
}
