import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'

const Timer = ({action}) => {

 const [timeLeft,setTimeLeft] = useState(150)   

 useEffect(()=>{
   if(timeLeft===0) {
    action()
    return;
}

   const intervalId= setInterval(()=>{
    setTimeLeft(timeLeft-1)

   },1000)

   return ()=>clearInterval(intervalId)
 },[timeLeft])

 const minutes = Math.floor(timeLeft/60);
 const seconds = timeLeft%60;
  return (
    <div>
        {minutes.toString().padStart(2,"0")}:{seconds.toString().padStart(2,"0")} Sec. Left
    </div>
  )
}

export default Timer