import React, { useContext, useEffect } from "react";
import { styled } from "@mui/material/styles";
import Accordion from '@mui/material/Accordion';
import { 
  AccordionSummary,
  AccordionDetails,
  Typography,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  IconButton
} from "@mui/material";
import Paper from "@mui/material/Paper";
//import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import CardContent from '@mui/material/CardContent';
import AddCommentIcon from '@mui/icons-material/AddComment';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DeleteIcon from '@mui/icons-material/Delete';
import FavoriteOutlinedIcon from '@mui/icons-material/FavoriteOutlined';
import Constant from "../../util/constants";
import { useGetTrip } from "../../react-query/hooks/useTrip";
import { Context } from "../../context";
import { useNavigate } from "react-router-dom";
import { useDeleteTrip } from "../../react-query/hooks/useTrip";
import { toggleFavourite } from "../../util/Display";
import "./Filter.css";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(2),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const styles = {
  accordianSummary: {
    paddingLeft: 0,
    paddingRight: 0
  },
  cardContent: {
    paddingBottom: 0
  },
  listItem: {
    paddingLeft: 0
  }
}

export default function Filter({ onDirectionHandler, onRequestClose, ...props }) {
  const { auth, user } = useContext(Context);
  const { userDetail:{ favoriteLocations, _id } } = user;
  const { data: trips, refetch } = useGetTrip(_id);
  const mutation = useDeleteTrip();
  const navigate = useNavigate();

  const handleRedirection = () => {
    const { authenticated, setAuthenticated } = auth;
    !authenticated.isAuth && 
    setAuthenticated({ 
      ...authenticated, 
      authPopup: !0, 
      redirectTo: Constant.paths.REQUEST_CHARGER
    });
    authenticated.isAuth && navigate(Constant.paths.REQUEST_CHARGER)
  }

  const removeFavourite = (key) => () => {
    toggleFavourite(
      auth,
      user,
      { favourite: !0 },
      key
    )
  }

  const onClickSavedTrip = (e) => {
    const { google: { maps }, history, location } = window;
    const { tripStart, tripEnd, tripWaypoint, tripId } = e.currentTarget.dataset;
    const _tripStart = JSON.parse(tripStart);
    const _tripEnd = JSON.parse(tripEnd);
    const _tripWaypoint = JSON.parse(tripWaypoint);
    onDirectionHandler(
      { lat: _tripStart.latitude, lng: _tripStart.longitude },
      { lat: _tripEnd.latitude, lng: _tripEnd.longitude },
      _tripWaypoint.map((way) => ({ location: new maps.LatLng(way.latitude, way.longitude), stopover: true }))
    );
    history.pushState({ tripId }, '', `${location.href}?from=${tripStart}&to=${tripEnd}&tripId=${tripId}`);
    onRequestClose();
  }

  const onDeleteTrip = (key) => async (e) => {
    e.stopPropagation();
    const deletedTrip = await mutation.mutateAsync({ tripId: key });
    if(deletedTrip) {
      refetch();
    }
  }

  return (
    <div style={{ width: 300 }}>
      <CardContent>
      <Button
        fullWidth
        variant="contained"
        onClick={handleRedirection}
        >
        {Constant.label.REQUEST_FOR_CHARGER}
        </Button>
      </CardContent>
      <CardContent sx={styles.cardContent}>
        <Accordion elevation={0}>
            <AccordionSummary sx={styles.accordianSummary}  expandIcon={<ExpandMoreIcon />} aria-controls='panel-1-content' id="panel-1-header">
               <Typography color="var(--app-color)">{Constant.label.FAVOURITE_LIST}</Typography>
            </AccordionSummary>
            <AccordionDetails>
               {favoriteLocations?.length > 0 && 
               <List dense={!1}>
                 {favoriteLocations?.map(loc => (
                   <ListItem sx={styles.listItem} key={loc._id}>
                     <ListItemIcon>
                       <IconButton aria-label="favourite" onClick={removeFavourite(loc._id)} >
                        <FavoriteOutlinedIcon color="primary" />
                       </IconButton>
                     </ListItemIcon>
                    <ListItemText 
                    primary={loc.name}
                    />
                </ListItem>
                 ))}
               </List>}

               {!favoriteLocations?.length && 
               <Typography textAlign="center" variant="body2">{Constant.message.NO_FAVOURITE_LIST}</Typography>}
            </AccordionDetails>
        </Accordion>
        <Accordion elevation={0}>
        <AccordionSummary sx={styles.accordianSummary}  expandIcon={<ExpandMoreIcon />} aria-controls='panel-2-content' id="panel-2-header">
            <Typography color="var(--app-color)">{Constant.label.SAVED_TRIP}</Typography>
            </AccordionSummary>
            <AccordionDetails className="saved-trip-panel">
               {trips?.data?.length > 0 && 
               <List dense={!1}>
                 {trips.data?.map(trip => (
                   <ListItem 
                   onClick={onClickSavedTrip}
                   data-trip-id={trip?._id}
                   data-trip-start={JSON.stringify(trip?.from)} 
                   data-trip-end={JSON.stringify(trip?.to)} 
                   data-trip-waypoint={JSON.stringify(trip?.via)} 
                   sx={styles.listItem} 
                   key={trip._id}
                   
                   secondaryAction={
                     <IconButton onClick={onDeleteTrip(trip?._id)} title="Remove Trip" edge="end" aria-label="remove trip">
                       <DeleteIcon />
                     </IconButton>
                   }
                   >
                    <ListItemText 
                    primary={trip?.from?.address}
                    />
                </ListItem>
                 ))}
               </List>}

               {!trips?.data.length && 
               <Typography textAlign="center" variant="body2">{Constant.message.NO_SAVED_TRIP}</Typography>}
            </AccordionDetails>
        </Accordion>
      </CardContent>
      <CardContent>
        <Button
          fullWidth
          variant="outlined"
          startIcon={<AddCommentIcon />}
          href="/feedback"
          >
          {Constant.label.FEEDBACK_POPOVER}
        </Button>
      </CardContent>
    </div>
  );
}
