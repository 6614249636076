import { useState, useMemo } from 'react';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import Home from "./container";
import { Context } from './context';
import { MuiSnackBar } from './component/@material-extend';
import './App.css';

import WhatsappImg from "./image/whatsapp_logo.png"

const theme = createTheme({
  palette: {
    primary: {
      light: '#757ce8',
      main: '#1F5150',
      dark: '#136f61',
      contrastText: '#fff',
    },
    secondary: {
      light: '#ff7961',
      main: '#f44336',
      dark: '#ba000d',
      contrastText: '#000',
    },
  },
});

function App() {
  const [userDetail, setUserDetail] = useState({});
  const [authenticated, setAuthenticated] = useState({ isAuth: !1, authPopup: !1 });
  const [mapsData, setMapsData] = useState({ loading: !1 });
  const [messageOption, setMessageOption] = useState({
    open: false,
    message: '',
    variant: 'success'
  });
  const userDetailMemo = useMemo(
    () => ({ userDetail, setUserDetail }),
    [userDetail, setUserDetail]
  );
  const authMemo = useMemo(
    () => ({ authenticated, setAuthenticated }),
    [authenticated, setAuthenticated]
  );
  const messageMemo = useMemo(
    () => ({ messageOption, setMessageOption }),
    [messageOption, setMessageOption]
  );

  const mapsMemo = useMemo(
    () => ({ mapsData,  setMapsData}),
    [mapsData, setMapsData]
  )

  /**
 * This will load all setting data upfront going to be used
 * through out in application.
 */

  return (
    <div className="App">
      <Context.Provider
        value={{
          user: userDetailMemo,
          auth: authMemo,
          message: messageMemo,
          maps: mapsMemo
        }}
      >
        <ThemeProvider theme={theme}>
          <Home />
          <MuiSnackBar 
          open={messageOption.open} 
          severity={messageOption.variant} 
          message={messageOption.message} 
          />
        </ThemeProvider>
      </Context.Provider>

      <div className='whatsapp_logo_container'>
        <a href="https://api.whatsapp.com/send?phone=918071295355" target='_blank' rel="noopener noreferrer"><img src={WhatsappImg}  alt="whatsapp logo"/></a>
      </div>
    </div>
  );
}

export default App;
