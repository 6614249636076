import React, { useMemo, useState } from "react";
import { 
    Paper, 
    Box, 
    Typography,
    Stack,
    Avatar,
    IconButton, 
    Slide
} from '@mui/material';
import InfoIcon from '@mui/icons-material/InfoOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';

import { StyledFab } from "../common/Styled";
import { MHidden } from "../@material-extend";
import Constant from "../../util/constants";

import { chargePointSpeedList, vehicleSegmentList } from "../../util/Display";

const styles = {
    keyFab: {
        bottom: 'calc(48px + 62px)'
    },
    keyMiniSideDrawer: {
        position: 'fixed',
        left: '0.5rem',
        bottom: '0.5rem',
        zIndex: 1060,
        width: '-webkit-fill-available'
    },
    keyDrawerPaper: {
        position: 'relative',
        maxWidth: '24rem',
        borderRadius: '1.5rem',
        padding: '1.5rem 2.5rem'
    },
    keyPointSpeeds: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column'
    },
    avatar: {
        width: 49,
        height: 49,
        bgcolor: 'var(--app-color)',
        '& img': {
            width: 28,
            height: 28
        }
    },
    closeIcon: {
        position: 'absolute',
        right: 16,
        top: 16
    }
}

export default function Mapkey() {
    const [showKeyInfo, setShowKeyInfo] = useState(!1);
    const speeds = useMemo(() => chargePointSpeedList, []);

    const handleKeyInfoShow = () => setShowKeyInfo(!showKeyInfo);

    return (
        <div className="map-key-info">
            <MHidden width="mdDown">
                <Paper
                    component="div"
                    className="relative Map-action-button map-key-info--paper-button desktop"
                    onClick={handleKeyInfoShow}
                    >
                    <InfoIcon />
                    <span>Key</span>
                </Paper>
            </MHidden>
            <MHidden width="mdUp">
                <Paper
                component="div"
                className="mobile"
                onClick={handleKeyInfoShow}
                >   
                    <StyledFab sx={styles.keyFab} aria-label="key info">
                        <InfoIcon />
                    </StyledFab>
                </Paper>
            </MHidden>
            <Slide in={showKeyInfo} direction='right' mountOnEnter unmountOnExit>
                <Box className="map-key-info--mini-drawer" sx={styles.keyMiniSideDrawer}>
                    <Paper variant="elevation" square sx={styles.keyDrawerPaper}>
                        <section className="mini-drawer-section-1">
                            <Typography variant="h6" mb={1} align="left">{Constant.label.VEHICLE_SEGMENT}</Typography>
                            <Stack direction="row" spacing={3}>
                                {vehicleSegmentList.map(segment => (
                                    <Box sx={styles.keyPointSpeeds} key={segment.id}>
                                        <Avatar sx={{ ...styles.avatar, bgcolor: 'transparent', width: '21px' }} alt={segment.ariaLabel} variant="rounded" src={segment.marker} />
                                        <Box>
                                            <Typography mt={1} variant="subtitle2" lineHeight={1} letterSpacing={1} fontWeight={600} fontSize='.75rem'>{segment.checkboxLabel}</Typography>
                                            {/* <Typography variant="caption" color={'var(--text-secondary)'}>{speed.kw}</Typography> */}
                                        </Box>
                                </Box>
                                ))}
                            </Stack>
                        </section>
                        <section className="mini-drawer-section-1">
                            <Typography variant="h6" mb={1} mt={2} align="left">{Constant.label.CHARGE_POINT_SPEEDS}</Typography>
                            <Stack direction="row" spacing={3}>
                                {speeds.map(speed => (
                                    <Box sx={styles.keyPointSpeeds} key={speed.id}>
                                        <Avatar sx={styles.avatar} alt={speed.alt} variant="rounded"  src={speed.src} />
                                        <Box>
                                            <Typography mt={1} variant="subtitle2" lineHeight={1} letterSpacing={1} fontWeight={600} fontSize='.75rem'>{speed.text}</Typography>
                                            <Typography variant="caption" color={'var(--text-secondary)'}>{speed.kw}</Typography>
                                        </Box>
                                    </Box>
                                ))}
                            </Stack>
                            <Typography variant="subtitle1" align="left" mt={2} fontSize='0.9em' lineHeight={1.2} color={'var(--text-secondary)'}>
                                The map icon will display the highest charging speed accessible at that location.
                            </Typography>
                        </section>
                        <IconButton sx={styles.closeIcon} aria-label="close" onClick={handleKeyInfoShow}>
                            <CloseOutlinedIcon />
                        </IconButton>
                    </Paper>
                </Box>
            </Slide>
        </div>
    );
}
