import { Link as RouterLink } from 'react-router-dom';
// material
import { styled } from '@mui/material/styles';
import { Box, Card, Link, Container, Typography, Paper, Stack } from '@mui/material';
// layouts
import AuthLayout from '../../layout/AuthLayout';
// components
import Page from '../Page';
import { MHidden } from '../@material-extend';
import LoginForm from '../authentication/login/LoginForm';
import AuthSocial from '../authentication/AuthSocial';
import login_banner from "../../image/oaza-login-banner.png";
import GooglePlay from "../../image/android-download.svg";
import AppStore from "../../image/app-store.png";

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex'
  }
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: '100%',
  maxWidth: 464,
  display: 'flex',
  flexDirection: 'column',
  margin: theme.spacing(2, 0, 2, 2)
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  display: 'flex',
  flexDirection: 'column',
}));

// ----------------------------------------------------------------------

export default function Login() {
  return (
    <RootStyle title="Register | Oaza-UI">
      

    <Container  className="cm-login-container">
    <AuthLayout>
        Don’t have an account? &nbsp;
        <Link underline="none" variant="subtitle2" component={RouterLink} to="/register">
          Get Started
        </Link>
      </AuthLayout>    <Box sx={{display:"flex", alignItems:"center",justifyContent:"center",gap:"24px"}} >
       <MHidden width="mdDown">
          <SectionStyle>
            <Typography variant="h5" sx={{ px: 5, mb: 5 }}>
              Providing Best Experience For Charging Point Services
            </Typography>
            <img alt="register" src={login_banner} />
          </SectionStyle>
        </MHidden>    
        <ContentStyle>
          <Box sx={{ mb: 5 }}>
            <Typography variant="h6" gutterBottom>
              Sign in to Oaza 
            </Typography>
            <Typography sx={{ color: 'text.secondary' }}>
              Enter your details below.
            </Typography>
          </Box>

          <AuthSocial />

          <LoginForm />

          <Typography variant="body2" align="center" sx={{ color: 'text.secondary', mt: 3 }}>
            By registering, I agree to Oaza&nbsp;
            <Link to="/terms-of-services" underline="always" sx={{ color: 'text.primary' }} component={RouterLink}>
              Terms of Service
            </Link>
            &nbsp;and&nbsp;
            <Link to="/privacy-policy" underline="always" sx={{ color: 'text.primary' }} component={RouterLink}>
              Privacy Policy
            </Link>
            .
          </Typography>

          <MHidden width="smUp">
            <Typography variant="subtitle2" sx={{ mt: 3, textAlign: 'center' }}>
              Don’t have an account?&nbsp;
              <Link to="/register" component={RouterLink}>
              Get Started 
              </Link>
            </Typography>
          </MHidden>
          <Stack direction="row" alignItems="center" justifyContent="center" spacing={4} className="apps-stack">
            <Typography variant="h6">Download Now</Typography>
            <Box className="cm-apps">
              <a href={process.env.REACT_APP_IOS_APP_STORE_URL} target="_blank" rel="noopener noreferrer">
                <span className="sr-only">Get it on App Store</span>
                <picture>
                  <source media='' type="image/svg" />
                  <img className="image" src={AppStore} alt="Get it on App Store" loading="lazy" />
                </picture>
              </a>
            </Box>
            <Box className="cm-apps">
              <a href={process.env.REACT_APP_GOOGLE_PLAY_STORE_URL} target="_blank" rel="noopener noreferrer">
                <span className="sr-only">Get it on Google Play</span>
                <picture>
                  <source media='' type="image/svg" />
                  <img className="image" src={GooglePlay} alt="Get it on Google Play" loading="lazy" />
                </picture>
              </a>
            </Box>
          </Stack>
        </ContentStyle> </Box>
      

       
      </Container>
    </RootStyle>
  );
}
