import React, { useContext } from "react";
import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import SearchIcon from '@mui/icons-material/Search';
import DirectionsIcon from '@mui/icons-material/Directions';
import Popover from '@mui/material/Popover';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { Context } from "../../context";
import Filter from "./Filter";
import { Box, Slide } from "@mui/material";
import Constant from '../../util/constants';

import './SearchBar.css';

const  SearchBar = React.forwardRef(({ applyFilter, toggleDirection, mapFilter, setCenter, onDirectionHandler }, ref) => {
    const { maps, auth } = useContext(Context);
    const theme = useTheme();
    const belowSmScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const [filterAnchorEl, setFilterAnchorEl] = React.useState(null);

    const handleMenuClick = (event) => {
        const { authenticated, setAuthenticated } = auth;
        if(!authenticated.isAuth){
          setAuthenticated({ 
            ...authenticated, 
            authPopup: !0,
            redirectTo: Constant.paths.HOME,
          });
          return;
        }

        setFilterAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setFilterAnchorEl(null);
    };

    const handleRouterPlanner = () => {
        const { setMapsData, mapsData } = maps;
        setMapsData({
            ...mapsData,
            routePlanner: !0
        });
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        const { places: { PlacesService } } = window.google.maps; 
        const { mapInstance } = window;
        const service = new PlacesService(mapInstance);
        const request = {
            query: e.target.elements['location'].value,
            fields: ['name', 'geometry']
        };
        service.findPlaceFromQuery(request, (result) => {
            const { location: { lat, lng } } = result[0].geometry;
            const { directionRendererInstance } = window;
            directionRendererInstance && directionRendererInstance.set('directions', null);
            setCenter({
                lat: lat(),
                lng: lng()
            })
        })
    }

    const isFilterOpen = Boolean(filterAnchorEl);
    const filterId = isFilterOpen ? 'filter-popover' : undefined;

    return (
        <Box sx={{ml: { sm: 20, lg:0}}} className={`search-bar-location`}>
             <Slide in={true} direction="right" mountOnEnter unmountOnExit>
                    <Paper
                        component="form"
                        sx={{ p: '2px 4px',display: 'flex', alignItems: 'center', width: '100%', borderRadius: "25px"}}
                        className="relative"
                        onSubmit={handleSubmit}
                        >
                        <IconButton sx={{ p: '10px' }} aria-label="menu" onClick={handleMenuClick} aria-describedby={filterId}>
                            <MenuIcon />    
                        </IconButton>
                        <Popover
                            id={filterId}
                            open={isFilterOpen}
                            anchorEl={filterAnchorEl}
                            onClose={handleClose}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'center',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                        >
                            <Filter
                                applyFilter={applyFilter}
                                mapFilter={mapFilter}
                                toggleDirection={toggleDirection}
                                onRequestClose={handleClose}
                                onDirectionHandler={onDirectionHandler}
                            />
                        </Popover>
                        <InputBase
                            sx={{ ml: 1, flex: 1 }}
                            placeholder="Search for a location"
                            inputProps={{ 'aria-label': 'search google maps' }}
                            name='location'
                            inputRef={ref}
                        />
                        <IconButton type="submit" sx={{ p: '10px' }} aria-label="search" disabled>
                            <SearchIcon />
                        </IconButton>
                        <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
                        <IconButton color="primary" sx={{ p: '10px' }} aria-label="directions" onClick={handleRouterPlanner}>
                            <DirectionsIcon />
                        </IconButton>
                    </Paper>
                </Slide>
        </Box>
    );
})

export default React.memo(SearchBar);