import { Box, IconButton, Grid, Modal, Stack, Typography, TextField, Button, styled } from '@mui/material'
import { Edit } from '@mui/icons-material';
import React, { useRef, useState } from 'react'
import Timer from '../../common/Timer/Timer';
const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    borderRadius: 1,
    boxShadow: 24,
    p: 4,
    display:"flex",
    flexDirection:"column",
    alignItems:"center",

};

const StyledTextField = styled(TextField)({
    textAlign: 'center',
    fontSize: 30,
    margin:30,
    width:90,
    '& .MuiInputBase-input':{
        textAlign: "center"
      },
    ' & .MuiInput-underline:before':{
        borderBottom:"1px solid #929292"
      },
     ' & .MuiInput-underline:hover:not(.Mui-disabled):before':{
        borderBottom: "1px solid #929292"
      },
     ' & .MuiInput-underline:after':{
        borderBottom: "2px solid #929292"
      },
     
});


const OTPVerification = ({ open, onClose,countryCode,phone, resendOtp,submitOtpFunc, handleSuccess }) => {
    const [otp, setOtp] = useState(["", "", "", ""])
    const [responseError, setResponseError] = useState("")
    const[allowResendOption,setAllowResendOption] = useState(false)
    const refsArr = {
        0: useRef(null),
        1: useRef(null),
        2: useRef(null),
        3: useRef(null),
    }
    const handleOtpChange = (e) => {
        const { value, name } = e.target
        const onlyDigits = /^[0-9\b]+$/;
        const nextField = Number(name) !== 3 ? Number(name) + 1 : 0
        if (value === "" || onlyDigits.test(value)) {
            if (value !== "" && refsArr[nextField].current && refsArr[nextField].current.value === "") refsArr[nextField].current.focus()
            setOtp((previousOtp) => {
                const updatedOtp = [...previousOtp]
                updatedOtp[name] = value
                return updatedOtp
            })
        }
    }

    const submitOtp = async () => {
        try {
            const response = await submitOtpFunc({ phone, phoneOtp: otp.join("") })
            if (response.success && (response.message === "OTP verified successfully" || response.message==="OTP verified successfully!")) {
                if (responseError) setResponseError("")
                onClose()
                handleSuccess(otp.join(""))
            } else {
                setResponseError(response.message)
            }
        } catch (error) {
            setResponseError(error)
        }
    }

    const handleResendOtp = async() =>{
       try {
        const response = await resendOtp(phone)
        if(response==="resend success"){
            if (responseError) setResponseError("")
            setAllowResendOption(false)
        }
       } catch (error) {
         console.log(error)
         setResponseError(error)
       }
    }

    return (
        <Modal
            open={open}
            onClose={onClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description">
            <Box sx={style} >
                <Typography variant='h4' marginBottom={2}>We've sent you a verification code</Typography>
                <Typography variant="body1" marginBottom={2}>Enter the 4 digit code we have sent you at {countryCode}{phone}</Typography>
                {/* <Typography variant="caption">{phone} <IconButton aria-label="edit"><Edit /></IconButton></Typography> */}
                <Stack flexDirection='row' alignItems='center' justifyContent='space-between'>
                    {
                        Array.from({ length: 4 }, (_, index) => index).map((place) => {

                            return <StyledTextField
                            variant="standard"
                                name={`${place}`}
                                value={otp[place]}
                                onChange={handleOtpChange}
                                inputRef={refsArr[place]}
                                inputProps={{
                                    maxLength: 1,
                                    pattern: '[0-9]'
                                }}
                            />
                        })
                    }

                </Stack>
                {responseError && <Typography variant="h6">{responseError}</Typography>}
                <Button onClick={submitOtp} variant="contained" marginTop={2} sx={{borderRadius:18,width:200}}>Submit</Button>
               { !allowResendOption && <Typography marginTop={2} color={"var(--app-color)"}><Timer action={()=>setAllowResendOption(true)}/></Typography>}
                <Button sx={{
               color:"var(--app-color)",
               marginTop:2,
               textTransform:"none",
                ' &:hover':{
                    backgroundColor:"transparent",
                  textDecoration:"underline"
                } 
                }} onClick={handleResendOtp} disabled={!allowResendOption}>I didn't receive a code</Button>
            </Box>
        </Modal>
    )
}

export default OTPVerification