import * as Yup from 'yup';
import { useState, useContext } from 'react';
import { useFormik, Form, FormikProvider } from 'formik';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';
// material
import { Stack, TextField, IconButton, InputAdornment, Link } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import LoadingButton from '@mui/lab/LoadingButton';
import isEmpty from 'lodash/isEmpty';
import axios from '../../../util/axios';
import token from '../../../util/Token';
import { Context } from '../../../context';
import Constant from '../../../util/constants';

// ----------------------------------------------------------------------

const { paths, action } = Constant;

export default function RegisterForm() {
  const { auth } = useContext(Context);
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState('');
  const [successMessage, setSuccessMessage] = useState();
  const phoneNoRegex = /^\d{10}$/i;
  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/i;

  const loginSchema = Yup.object().shape({
    // email: Yup.string().email('Email must be a valid email address').required('Email is required'),
    contact: Yup.string().test('phone-or-email','Please enter a valid phone number or email address',(value)=>{

      if(phoneNoRegex.test(value)|| emailRegex.test(value)) return true;
      return false;
    }).required('Either email or phone no. is required'),
    password: Yup.string().required('Password is required')
  });

  const formik = useFormik({
    initialValues: {
      contact: '',
      password: ''
    },
    validationSchema: loginSchema,
    onSubmit: (loginData, { setSubmitting, resetForm }) => {
      (async function () {
        try {
          let loginPayload = {
            type:emailRegex.test(loginData.contact)?"email":"phone",
            password:btoa(loginData.password)
          }
          if(loginPayload.type==="email") loginPayload.email = loginData.contact
          else {
            loginPayload.phone = loginData.contact
            loginPayload.countryCode = "91"
          }
          const loginHelper = async () => {
            const response = await axios(`${process.env.REACT_APP_API_END_POINT}auth/v2/login`, {
              method: 'post',
              data: loginPayload
            });
            const tokenResp = response?.data?.data?.data;
            if (tokenResp && tokenResp.token) {
              const { authenticated, setAuthenticated } = auth;
              token.set(tokenResp.token);
              //  TODO: make it work
              //  Token.setAuthToken();
              //  window.location.href = '/dashboard';
              //  navigate('/dashboard');
              if (authenticated.authPopup) {
                setAuthenticated({
                  ...auth,
                  authPopup: !1,
                  authElementType: action.AUTH_ELELEMT_TYPE_POPUP, 
                });
                return
              }
              window.location.href = isEmpty(authenticated.redirectTo) ? paths.HOME : authenticated.redirectTo;
            }
          };
          await loginHelper();
        } catch (error) {
          setSubmitting(false);
          if (error.response && error.response.data && error.response.data.message) {
            setError(error.response.data.message);
          }
        }
      })();
      //navigate('/dashboard', { replace: true });
    }
  });

  const { errors, touched, handleSubmit, isSubmitting, getFieldProps} = formik;

  return (
    <FormikProvider value={formik}>
      <div style={{ color: 'red' }}>{error}</div>
      <div style={{ color: 'green' }}>{successMessage}</div>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3}>
          <TextField
            fullWidth
            autoComplete="username"
            type="text"
            label="Email address / Phone number"
            {...getFieldProps('contact')}
            error={Boolean(touched.contact && errors.contact)}
            helperText={touched.contact && errors.contact}
          />

          <TextField
            fullWidth
            autoComplete="current-password"
            type={showPassword ? 'text' : 'password'}
            label="Password"
            {...getFieldProps('password')}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton edge="end" onClick={() => setShowPassword((prev) => !prev)}>
                    {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                  </IconButton>
                </InputAdornment>
              )
            }}
            error={Boolean(touched.password && errors.password)}
            helperText={touched.password && errors.password}
          />
          <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
            <Link component={RouterLink} variant="subtitle2" to="/forget-password">
              Forgot password?
            </Link>
          </Stack>

          <LoadingButton
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            loading={isSubmitting}
          >
            Login
          </LoadingButton>
        </Stack>
      </Form>
    </FormikProvider>
  );
}
