import { useState } from "react";
import { Formik, Form } from "formik";
import styled from "@emotion/styled";
import axios from "axios";
import * as Yup from "yup";

import {
  Badge,
  Grid,
  InputLabel,
  Rating,
  Stack,
  Typography,
  TextField,
  Button,
  ToggleButtonGroup,
  ToggleButton,
  Snackbar,
  IconButton,
  Box,
  Container,
  Alert,
} from "@mui/material";

import {
  Send as SendIcon,
  Close as CloseIcon,
  CloudUpload as CloudUploadIcon,
} from "@mui/icons-material";

import Feedback from "../image/feedback/feedback.png";
import Page from "./Page";

const FeedBack = () => {
  return (
    <Page>
      <Container>
        <Box className="section-feedback">
          <Grid container spacing={2} justifyContent={"space-between"}>
            <Grid item sx={{ display: { xs: "none", md: "block" } }} md={6}>
              <Box>
                <Stack>
                  <Typography
                    variant="h2"
                    sx={{ fontWeight: 600, color: "var(--app-color)" }}
                  >
                    Rate us
                  </Typography>
                </Stack>
                <Stack>
                  <span className="image">
                    <img src={Feedback} alt="feedback" />
                  </span>
                </Stack>
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box justifyContent={"center"}>
                <FeedBackForm />
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Page>
  );
};

const FeedBackForm = () => {
  const [reaction, setReaction] = useState(1);
  const [type, setType] = useState("FEEDBACK");
  const [attachment, setAttachment] = useState();
  const [snackBarStatus, setSnackbarStatus] = useState(false);
  const [severityType, setSeverityType] = useState("success");
  const [snackBarMessage, setSnackbarMessage] = useState(
    "Feedback has been submitted successfully"
  );

  const handleCategoryChange = (event, newAlignment) => {
    setType(newAlignment);
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarStatus(false);
  };

  const snackBarAction = (
    <>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleCloseSnackbar}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </>
  );
  const addImage = async (addImagePayload) => {
    let response = await axios(
      `${process.env.REACT_APP_API_END_POINT}util/image-upload`,
      {
        method: "post",
        data: addImagePayload,
      }
    );
    if (response && response.data && response.data.success) {
      return response.data.data;
    }
  };
  const uploadImage = async (binaryString) => {
    if (!binaryString) {
      return;
    }
    let addImagePayload = {
      image: binaryString,
    };
    try {
      const resp = await addImage(addImagePayload);
      return resp;
    } catch (error) {
      setSnackbarMessage("Failed to upload attachment");
      setSnackbarStatus(true);
      setSeverityType("error");
      console.error(error.response);
    }
  };

  const submitFeedback = async (feedbackData, { setSubmitting, resetForm }) => {
    feedbackData.type = type ?? "FEEDBACK";
    feedbackData.reaction = reaction;
    let attachmentURL;
    if (attachment) {
      attachmentURL = await uploadImage(attachment);
      // console.log(attachmentURL);
      if (!attachmentURL) {
        setSnackbarStatus(true);
        setSeverityType("error");
        setSnackbarMessage("Failed to upload attachment");
        return;
      }
    }
    feedbackData.attachment = attachmentURL;
    (async function () {
      try {
        const addFeedback = async () => {
          let response = await axios(
            `${process.env.REACT_APP_API_END_POINT}feedback/add`,
            {
              method: "post",
              data: feedbackData,
            }
          );
          let _tokenResp = response.data.data;
          resetForm();
          setAttachment(null);
          setReaction(1);
          setSnackbarStatus(true);
          setSeverityType("success");
          setSnackbarMessage("Feedback has been submitted successfully");
        };
        await addFeedback();
      } catch (error) {
        setSnackbarStatus(true);
        setSeverityType("error");
        setSnackbarMessage("Failed to submit feedback");
        console.error(error.response);
      }
    })();
  };

  const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1,
  });

  const handleImageChange = (e) => {
    e.preventDefault();
    let reader = new FileReader();
    let file = e.target.files[0];
    reader.onloadend = (readerEvt) => {
      let binaryString = readerEvt.target.result;
      if (!binaryString) {
        return;
      }
      setAttachment(binaryString);
    };
    reader.readAsDataURL(file);
  };

  const handleRemoveImage = () => {
    setAttachment(null);
  };

  return (
    <>
      <Formik
        initialValues={{
          type: "",
          reaction: "",
          comment: "",
          name: "",
          attachment: "",
        }}
        validationSchema={Yup.object({
          type: Yup.string(),
          reaction: Yup.string(),
          comment: Yup.string().required("Please compose your feedback"),
          name: Yup.string().required("Please add your corresponding alias"),
          attachment: Yup.string(),
        })}
        onSubmit={submitFeedback}
      >
        {({ values, errors, touched, handleChange, handleSubmit }) => (
          <Form>
            <Box className="feedback-wrapper">
              <div className="top">
                <div className="head-sub-review">
                  <div className="head-sub">
                    <div className="head-text">
                      <div className="session-feedback">Session feedback</div>
                    </div>
                    <div className="sub-text">
                      <div className="please-rate-your">
                        Please rate your experience below
                      </div>
                    </div>
                  </div>
                  <div className="review">
                    <div className="star-wrapper">
                      <Rating
                        name="simple-controlled"
                        value={reaction}
                        onChange={(event, newValue) => {
                          setReaction(newValue);
                        }}
                      />
                    </div>
                    <Box as="div" className="star-count">
                      <div className="stars">{reaction}/5 stars</div>
                    </Box>
                  </div>
                </div>
                <div className="top-inner">
                  <div className="frame-wrapper">
                    <Stack
                      spacing={2}
                      direction="row"
                      alignItems={"center"}
                      justifyContent={"center"}
                    >
                      <ToggleButtonGroup
                        className="feedback-type-wrapper"
                        color="primary"
                        value={type}
                        exclusive
                        onChange={handleCategoryChange}
                      >
                        <ToggleButton className="feedback-type" value="BUG">
                          Bug
                        </ToggleButton>
                        <ToggleButton className="feedback-type" value="FEATURE">
                          Suggest Feature
                        </ToggleButton>
                        <ToggleButton
                          className="feedback-type"
                          value="LOCATION"
                        >
                          Report CP Issues
                        </ToggleButton>
                      </ToggleButtonGroup>
                    </Stack>
                  </div>
                </div>
                <div className="input-area">
                  <InputLabel htmlFor="name-email">Your Name/Email</InputLabel>
                  <TextField
                    fullWidth
                    sx={{ mb: 2 }}
                    name="name"
                    placeholder="My Name / Email"
                    id="name-email"
                    value={values.name}
                    onChange={handleChange}
                    error={Boolean(touched.name && errors.name)}
                    helperText={touched.name && errors.name}
                  />

                  <InputLabel htmlFor="additional-feedback">
                    Additional feedback
                  </InputLabel>

                  <TextField
                    id="additional-feedback"
                    placeholder="My feedback!!"
                    fullWidth
                    name="comment"
                    multiline
                    rows={4}
                    value={values.comment}
                    onChange={handleChange}
                    error={Boolean(touched.comment && errors.comment)}
                    helperText={touched.comment && errors.comment}
                  />
                </div>
              </div>
              <div className="submit-section">
                <Button type="submit" variant="outlined" fullWidth>
                  Submit feedback
                </Button>
                <div className="sub-text4">
                  <div className="option">OR</div>
                </div>
                <Box
                  sx={{
                    width: 120,
                    height: 120,
                    boxShadow: 3,
                    mx: "auto",
                    mb: 5,
                  }}
                  hidden={!attachment}
                >
                  <Badge
                    sx={{ height: "100%", cursor: "pointer" }}
                    color="primary"
                    badgeContent={<CloseIcon />}
                    // variant="dot"
                    onClick={handleRemoveImage}
                    className="cross-badge"
                  >
                    <img src={attachment} alt="attachment icon" />
                  </Badge>
                </Box>
                <div className="button-group">
                  <div className="btn-wrapper">
                    <Button
                      component="label"
                      role={undefined}
                      variant="contained"
                      tabIndex={-1}
                      startIcon={<CloudUploadIcon />}
                    >
                      {attachment ? "Change Attachment" : "Attachment"}
                      <VisuallyHiddenInput
                        type="file"
                        onChange={handleImageChange}
                      />
                    </Button>
                  </div>
                  <div className="btn-wrapper">
                    <Button
                      fullWidth
                      startIcon={<SendIcon />}
                      type="submit"
                      variant="contained"
                    >
                      Submit
                    </Button>
                  </div>
                </div>
              </div>
            </Box>
          </Form>
        )}
      </Formik>
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        open={snackBarStatus}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        action={snackBarAction}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={severityType}
          variant="filled"
          sx={{ width: "100%" }}
        >
          {snackBarMessage}
        </Alert>
      </Snackbar>
    </>
  );
};

export default FeedBack;
