// material
import { styled } from "@mui/material/styles";
import { Container, Grid, Box, Typography } from "@mui/material";
import { CMBreadCrumb } from "../common/OazaBreadCrumb";
import { CMFooter } from "../common/OazaFooter";

// images and icons
import FindBestCM from '../../image/why-oaza/oaza-find-best-charger.png';
import GoMilesCM from '../../image/why-oaza/oaza-go-miles.png';
import ManageYourCharging from '../../image/why-oaza/oaza-manage-your-charging-point.png';
import PlanYourCharging  from '../../image/why-oaza/oaza-plan-your-trip.png';
// components
import Page from "../Page";

export const StyledTitleSpan = styled("span")`
  font-family: 'Work Sans', sans-serif;
  text-transform: uppercase;
  font-size: var(--fs-xl);
  font-weight: 500;
  position: relative;
  color: var(--app-color);
  width: 100%;
  margin: 0 0 10px;
  padding-right: 45px;
  letter-spacing: -2px;
  padding-left: 45px;
  :before {
    content: "";
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    background: #86bb45;
    height: 1px;
    width: 35px;
  }
  :after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    background: #86bb45;
    height: 1px;
    width: 35px;
  }
`;
export default function WhyOaza() {
  return (
    <Page title="Dashboard | Oaza-UI" className="why-cm-container">
        <Box className="our-feature mb-28">
          <StyledTitleSpan className="title-legend">OUR FEATURES</StyledTitleSpan>
          <Typography variant="body1">Do you want to locate the best rapid charging stations along your route?</Typography>
          <Typography variant="body1">Are you preparing for a long trip by electric vehicle for the holidays?</Typography>
          <Typography variant="body1">Are you looking for free charging stations in the surrounding area?</Typography>
          <Typography variant="body1">Are you looking for a charging station nearby?</Typography>
        </Box>
        <Typography variant="h4">
          Introducing Oaza Mapping Service – Locate Charge Points, 
          Plan Your Journey And Share Updates With Other EV Drivers
        </Typography>
        <Grid container justifyContent="center" alignItems="center" className="zig-zag" spacing={2}>
          <Grid item xs={12} lg={4} md={4}>
            <Typography variant="h2">Find the best charging point</Typography>
          </Grid>
          <Grid item xs={12} lg={8} md={8}>
            <span className="image">
              <img src={FindBestCM} alt="find best charging point" height={275} />
            </span>
          </Grid>
        </Grid>
        <Grid container justifyContent="center" alignItems="center" className="zig-zag" spacing={2}>
          <Grid item xs={12} lg={8} md={8}>
            <span className="image">
              <img src={PlanYourCharging} alt="find best charging point" height={275} />
            </span>
          </Grid>
          <Grid item xs={12} lg={4} md={4}>
            <Typography variant="h2">Plan Longer Electric Journey's</Typography>
          </Grid>
        </Grid>
        <Grid container justifyContent="center" alignItems="center" className="zig-zag" spacing={2}>
          <Grid item xs={12} lg={4} md={4}>
            <Typography variant="h2">Travel Together with fellow EV drivers</Typography>            
          </Grid>
          <Grid item xs={12} lg={8} md={8}>
            <span className="image">
              <img src={GoMilesCM} alt="find best charging point" height={275} />
            </span>
          </Grid>
        </Grid>
        <Grid container justifyContent="center" alignItems="center" className="zig-zag" spacing={2}>
          <Grid item xs={12} lg={8} md={8}>
            <span className="image">
              <img src={ManageYourCharging} alt="find best charging point" height={275} />
            </span>
          </Grid>
          <Grid item xs={12} lg={4} md={4}>
            <Typography variant="h2">Manage your charging</Typography>
          </Grid>
        </Grid>
        <Grid container justifyContent="center">
          <Box className="our-feature">
            <StyledTitleSpan className="title-legend">Other Features</StyledTitleSpan>
            <Typography variant="body1">
              Oaza allows commercial and domestic users, charging point
              providers to add a charger to Oaza Map to enable community
              charging for free or a small charge. Add new public charge points
              through the wizard, points will be reviewed by the Oaza data
              team and then added to Oaza Map.
            </Typography>
          </Box> 
        </Grid>
        <Grid container justifyContent="center">
          <Box className="team-cares mb-28">
            <StyledTitleSpan className="title-legend">A Team that Cares</StyledTitleSpan>
            <Typography variant="body1">
              Oaza is also a dream team that gives their all to enhance the app every day with 
              the help of your invaluable feedback. Any questions, suggestions, rave reviews? 
              Please don’t hesitate to get in touch <a href="mailto:support@oaza.energy">support@oaza.energy</a>
            </Typography>
          </Box> 
        </Grid>
    </Page>
  );
}
