/**
 * @author AshutoshMaurya
 */

 import setAuthorizationToken from "./setAuthorizationToken";

const Token = {
  set: function(val) {
    localStorage.setItem("_cmst", JSON.stringify(val));
  },
  get: function(key) {
    var item = localStorage.getItem("_cmst");
    return JSON.parse(item);
  },
  remove: function(key) {
    localStorage.removeItem(key);
  },
  clear: function() {
    localStorage.clear();
  },
  parsedToken: function() {
    let res = {
        success: false,
        data: null,
        message: ""
      },
      tokenArray = [],
      token = this.get("_cmst");

    if (!token) {
      res.success = false;
      res.message = "Invalid or No tiken passed";
      res.data = "";
      return res;
    }
    tokenArray = token.split(".");
    if (tokenArray.length < 2) {
      res.success = false;
      res.message = "Invalid or No tiken passed";
    }
    res.success = true;
    res.message = "Token parsed successfully";
    res.data = JSON.parse(window.atob(tokenArray[1]));
    return res;
  },

  setAuthToken: function() {
    setAuthorizationToken(this.get("_cmst"));
  }
};

export default Token;
