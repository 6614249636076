// material
import { Box, Grid, Container, Typography } from '@mui/material';
// components
import Page from '../Page';
import RequestChargerForm from '../requestCharger/RequestChargerForm'

export default function RequestCharger() {
  return (
    <Page title="Dashboard | Oaza-UI">
        <RequestChargerForm />
    </Page>
  );
}
