import { useMutation, useQuery } from "@tanstack/react-query";
import HttpService from "../../services/HttpService";
import { queryKeys } from "../queryKeys";
import Constant from "../../util/constants";

export const useCreateTrip = () => {
    return useMutation(queryKeys.createTrip(), (data) => {
        return HttpService(
            Constant.methods.CREATE_TRIP,
            data
        )
    })
};

export const useUpdateTrip = () => {
    return useMutation(queryKeys.updateTrip(), (data) => {
        return HttpService(
            Constant.methods.UPDATE_TRIP,
            data
        )
    });
}

export const useGetTrip = (userId) => {
    return useQuery(queryKeys.getTrip(userId), async () => {
        return await HttpService(
            Constant.methods.GET_TRIP,
            { queryParameters: { userId } } 
        )
    }, {
        cacheTime: 2 * 60 * 1000
    })
}

export const useDeleteTrip = () => {
    return useMutation(queryKeys.deleteTrip(), ({ tripId }) => {
        return HttpService(
            Constant.methods.DELETE_TRIP,
            { queryParameters: { tripId } }
        )
    });
}
