import axios from "axios";

export const fetchWithError = async ({ queryKey }) => {
  try {
    const [, queryParams] = queryKey ?? {};
    const { url, method = "GET", body } = queryParams || {};
    const { data } = await axios({
      url,
      method,
      ...((method === "GET" || method === "DELETE") && { params: body }),
      ...((method === "POST" || method === "PUT") && { data: body }),
    });
    return data;
  } catch (e) {
    return Promise.reject(e);
  }
};
