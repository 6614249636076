import { Navigate, useRoutes } from "react-router-dom";
import Login from "../component/pages/Login";
import Logout from "../component/pages/Logout";
import Account from "../component/pages/Account";
import ResetPassword from "../component/pages/ResetPassword";
import ForgetPassword from "../component/pages/ForgetPassword";
import Register from "../component/pages/Register";
import Oaza from "../component/oaza";
import FeedBack from "../component/Feedback";
import DashboardLayout from "../layouts/dashboard";
import Terms from "../component/pages/Terms";
import PrivacyPolicy from "../component/pages/PrivacyPolicy";
import ChangePassword from "../component/pages/ChangePassword";
import MyVehicle from "../component/account/MyVehicle";
import RequestCharger from "../component/pages/RequestCharger";
import Product from "../component/pages/Product";
import WhyOaza from "../component/pages/WhyOaza";
import ContactUs from "../component/pages/ContactUs";
import Refund from "../component/pages/Refund";
import { PaymentStatus } from "../component/oaza/Payment/PaymentStatus";
import { ChargerInformation } from "../component/oaza/QRScanner/ChargerDetails";
import { PaymentResponse } from "../component/oaza/Payment/PaymentResponse";
import ChargerTransactionStats from "../component/oaza/ChargerTransactionStats";
import TransactionInvoice from "../component/oaza/TransactionInvoice";
import RedirectPage from "../component/oaza/RedirectPage";
import DeleteUserAccount from "../component/pages/DeleteUserAccount";
import NotFound from "../component/pages/Page404"
import Faqs from "../component/pages/Faqs";


export default function Router({ isLoggedIn }) {
  return useRoutes([
    {
      path: "/",
      element: <DashboardLayout />,
      children: [
        { element: <Navigate to="/" replace /> },
        { path: "/", element: <Oaza /> },
        { path: "/home", element: <Navigate to="/" replace /> },
        { path: "/feedback", element: <FeedBack /> },
        { path: "/terms-of-services", element: <Terms /> },
        { path: "/privacy-policy", element: <PrivacyPolicy /> },
        { path: "/delete-user-account", element: <DeleteUserAccount /> },
        { path: "/faqs", element: <Faqs /> },
        { path: "/refund", element: <Refund /> },
        { path: "/account", element: <Account /> },
        { path: "/my-vehicle", element: <MyVehicle /> },
        { path: "/change-password", element: <ChangePassword /> },
        { path: "/request-charger", element: <RequestCharger /> },
        { path: "/why-oaza", element: <WhyOaza /> },
        { path: "/product", element: <Product /> },
        { path: "/contact-us", element: <ContactUs /> },
        { path: "/payment-status", element: <PaymentStatus /> },
        { path: "/payment/status", element: <PaymentResponse /> },
        { path: "/charger/:chargerId", element: <ChargerInformation /> },
        { path: "/en/:redirectId", element: <RedirectPage /> },
        { path: "/status", element: <ChargerTransactionStats /> },
        { path: "/invoice", element: <TransactionInvoice /> },
      ],
    },
    { path: "/login", element: <Login /> },
    { path: "/register", element: <Register /> },
    { path: "/forget-password", element: <ForgetPassword /> },
    { path: "/reset-password/:id/:secret", element: <ResetPassword /> },
    { path: "/reset-password/:id/:secret/:type", element: <ResetPassword /> },
    { path: "/logout", element: <Logout /> },
    { path: "pagenotfound", element: <NotFound /> },
    { path: "*", element: <Navigate to="/pagenotfound" replace /> },
  ]);
}