import React, { useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { is } from '../../util/is';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { transactionRedirectionReq } from '../../react-query/endpoints';
import { ErrorComponent } from './ChargerTransactionStats';
import { CircularProgress } from '@mui/material';


const fetchRedirectionUrl = async (endPoint, redirectId) => {
    const response = await axios.get(transactionRedirectionReq(`${endPoint}/${redirectId}`), {
    })
    return response.data;
  }


const RedirectPage = () => {
    const navigate = useNavigate();
  // const location = useLocation();
  // const queryParams = new URLSearchParams(location.search);
  
  // Retrieve specific query parameters
  // const redirectId = queryParams.get('id');
  const params = useParams();
  const {redirectId} = params;
  
  const [isInvalidRedirectId,setIsInvalidRedirectId] = useState(false)


  const { data, isLoading } = useQuery(["transactionRedirect"], () => fetchRedirectionUrl("transactionRedirect", redirectId), {
    enabled: !is.empty(redirectId),
    onSuccess: async (data) => {
        if (is.empty(data)) {
            return
          }
          const { token } = data;
        navigate(`/status?token=${token}`)
    },
    onError: (error) => {
      console.log(error)
      const {response} = error;
      if(!is.empty(response) && !is.empty(response.data) && !response?.data?.auth && (response?.data?.message==="Please provide a valid redirectId" ||  response?.data?.message==="System error, please try again!")){
        setIsInvalidRedirectId(true);
       return;
      }
    }
  });

  if (is.empty(redirectId) || (isInvalidRedirectId)) {
    return <ErrorComponent message1={"Invalid Request"} message2={"Please try again with valid information"}/>
  };

  if (isLoading) {
    return <div style={{ margin: 300 }}><CircularProgress
      color="success"
      size={40}
      thickness={4}
      variant="indeterminate" /> </div>
  };
  return (
    <div></div>
  )
}

export default RedirectPage