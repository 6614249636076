import React from "react";
import CssBaseline from '@mui/material/CssBaseline';
import Router from "../route";

function Home(props) {

    return (
        <React.Fragment>
          <CssBaseline />
          <Router />
        </React.Fragment>
      );
}

export default Home;
