import * as Yup from 'yup';
import { useContext, useState } from 'react';
import { useFormik, Form, FormikProvider } from 'formik';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';
// material
import { Stack, TextField, IconButton, InputAdornment, Box, Link, Grid, Typography } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import LoadingButton from '@mui/lab/LoadingButton';
import axios from '../../../util/axios';
import token from '../../../util/Token';
import { Context } from '../../../context';
import { f } from 'html2pdf.js';

// ----------------------------------------------------------------------

export default function ChangePasswordForm() {
  const { user, userType } = useContext(Context);
  const { userDetail } = user;
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [error, setError] = useState('');
  const [successMessage, setSuccessMessage] = useState();
  const [passwordChanged, setPasswordChanged] = useState(false);


  const resetPasswordSchema = Yup.object().shape({
    password: Yup.string().min(8, 'Password must be at least 8 characters')
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
      'Password must contain at least one uppercase letter, one lowercase letter, one digit, and one special character'
    ).required('Password is required'),
    confirmPassword: Yup.string().required('Confirm password is required').oneOf([Yup.ref('password'), null], 'Passwords must match')
,
  });

  const formik = useFormik({
    initialValues: {
      password: '',
      confirmPassword: ''
    },
    validationSchema: resetPasswordSchema,
    onSubmit: (resetPasswordData, { setSubmitting, resetForm }) => {
      if (!userDetail) {
        setError("Invalid session");
        setSuccessMessage("");
        setSubmitting(false);
        return;
      }
      if (resetPasswordData.password !== resetPasswordData.confirmPassword) {
        setError("Password and confirm password does not match.");
        setSuccessMessage("");
        setSubmitting(false);
        return;
      }
      resetPasswordData['id'] = userDetail._id;
      (async function () {
        try {
          resetPasswordData.password = btoa(resetPasswordData.password);
          resetPasswordData.confirmPassword = btoa(resetPasswordData.confirmPassword);
          const registerHelper = async () => {
            // console.log(process.env.REACT_APP_API_END_POINT);
            const response = await axios(`${process.env.REACT_APP_API_END_POINT}auth/v2/change-auth-password`, {
              method: 'post',
              data: resetPasswordData
            });
            const resetPasswordResponse = response.data.data;
            setSubmitting(false);
            resetForm();
            setError('');
            setSuccessMessage("Password changed successfully.");
            setPasswordChanged(true);
          };
          await registerHelper();
        } catch (error) {
          setSubmitting(false);
          if (error.response && error.response.data && error.response.data.message) {
            setError(error.response.data.message);
            setSuccessMessage("");
          }
        }
      })();
      //navigate('/dashboard', { replace: true });
    }
  });

  const { errors, touched, handleSubmit, isSubmitting, getFieldProps, resetForm } = formik;

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={0} columns={{ xs: 12, sm: 12, md: 12 }}>
            <Grid item xs={12} sm={12} md={4}>
              <div style={{ color: 'red' }}>{error}</div>
              <div style={{ color: 'green' }}>{successMessage}</div>
              {!passwordChanged && <Stack spacing={1}>
                <Typography variant="h6" style={{ color: "grey" }}>
                  {" "}
                  Change your password!
                </Typography>
                <Stack direction={{ xs: 'column', sm: 'column' }} spacing={1}>
                  <TextField
                    fullWidth
                    type={showPassword ? 'text' : 'password'}
                    size="small"
                    label="Password"
                    {...getFieldProps('password')}
                    error={Boolean(touched.password && errors.password)}
                    helperText={touched.password && errors.password}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton edge="end" onClick={() => setShowPassword((prev) => !prev)}>
                            {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                          </IconButton>
                        </InputAdornment>
                      )
                    }}
                  />

                  <TextField
                    fullWidth
                    size="small"
                    type={showConfirmPassword ? 'text' : 'password'}
                    label="Confirm password"
                    {...getFieldProps('confirmPassword')}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton edge="end" onClick={() => setShowConfirmPassword((prev) => !prev)}>
                            {showConfirmPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                          </IconButton>
                        </InputAdornment>
                      )
                    }}
                    error={Boolean(touched.confirmPassword && errors.confirmPassword)}
                    helperText={touched.confirmPassword && errors.confirmPassword}
                  />
                </Stack>
                <Stack direction={{ xs: 'column', sm: 'column' }} spacing={1}>
                  <LoadingButton
                    size="small"
                    type="submit"
                    variant="contained"
                    loading={isSubmitting}
                    disabled={errors.password || errors.confirmPassword || isSubmitting || formik.values.password === '' || formik.values.confirmPassword === ''}
                    style={{ width: "150px", textAlign: "center" }}
                  >
                    Save
                  </LoadingButton>
                </Stack>
              </Stack>}
              {passwordChanged && <Stack direction={{ xs: 'column', sm: 'column' }} spacing={1}>
                <Link to="/account" underline="always" sx={{ color: 'text.primary' }} component={RouterLink}>
                  Back to my account
                </Link>
                <Link to="/" underline="always" sx={{ color: 'text.primary' }} component={RouterLink}>
                  Back to home
                </Link>
              </Stack>}

            </Grid>

          </Grid>
        </Box>
      </Form>
    </FormikProvider>
  );
}
