import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useFormik, Form, FormikProvider } from "formik";
import {
  Stack,
  TextField,
  Box,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button
} from "@mui/material";
import * as Yup from "yup";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import UploadPhoto from "./UploadPhoto";
import axios from "../../util/axios";
import "./PersonalDetailTab.css";
import Snackbar from '@mui/material/Snackbar';
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Link as RouterLink } from 'react-router-dom';

export default function PersonalDetailTab({ userDetail }) {
  const [dob, setDob] = useState(null);
  const [userProfilePhoto, setUserProfilePhoto] = useState("");
  const [countries, setCountries] = useState([]);
  const [snackBarStatus, setSnackbarStatus] = useState(false);
  const [snackBarMessage, setSnackbarMessage] = useState("");
  const [isDeleteDialogOpen, setDeleteDialogOpen] = useState(false);
  let navigate = useNavigate();

  const handleDeleteDialogOpen = () => {
    setDeleteDialogOpen(true);
  };

  const handleDeleteDialogClose = () => {
    setDeleteDialogOpen(false);
  };

  const showMessage = () => {
    setSnackbarStatus(true);
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarStatus(false);
  };

  const snackBarAction = (
    <>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleCloseSnackbar}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </>
  );

  const uploadImage = (binaryString) => {
    if (!binaryString) {
      return;
    }
    let addImagePayload = {
      image: binaryString,
    };
    (async function () {
      try {
        const addImage = async () => {
          // console.log(process.env.REACT_APP_SERVER_URL);
          let response = await axios(
            `${process.env.REACT_APP_API_END_POINT}util/image-upload`,
            {
              method: "post",
              data: addImagePayload,
            }
          );
          if (response && response.data) {
            setUserProfilePhoto(response.data.data);
          }
        };
        await addImage();
      } catch (error) {
        console.error(error.response);
      }
    })();
  };

  const deleteAccountHandler = () => {
    (async function () {
      try {
        const url = `${process.env.REACT_APP_API_END_POINT}user/delete-account?userId=${userDetail._id}`;
        const deleteUserAccount = async () => {
          await axios(url, {
            method: "delete"
          });
          navigate("/logout");
        };
        await deleteUserAccount();
      } catch (error) {
        console.error(error.response);
      }
    })();
  }

  useEffect(() => {
    (async function () {
      try {
        const getCountryList = async () => {
          const response = await axios(
            `${process.env.REACT_APP_API_END_POINT}util/list-country`,
            {
              method: "get",
            }
          );
          const countryList = response.data.data;
          setCountries(countryList);
        };

        await getCountryList();
      } catch (error) {
        console.error(error.response);
      }
    })();
  }, []);

  const userSchema = Yup.object().shape({
    name: Yup.string().max(60, "Must be 60 characters or less"),
    gender: Yup.string().max(10, "Must be 1 characters or less"),
    dob: Yup.date(),
    language: Yup.string().max(60, "Must be 60 characters or less"),
    country: Yup.string().max(60, "Must be 60 characters or less"),
    city: Yup.string().max(60, "Must be 60 characters or less"),
  });

  const formik = useFormik({
    initialValues: {
      name: "",
      gender: "",
      dob: "",
      language: "",
      country: "",
      city: "",
    },
    validationSchema: userSchema,
    onSubmit: (userData, { setSubmitting }) => {
      (async function () {
        userData._id = userDetail._id;
        userData.dob = dob;
        userData.photoId = userProfilePhoto || "";
        try {
          const url = `${process.env.REACT_APP_API_END_POINT}user/detail`;
          const updateClientDetail = async () => {
            await axios(url, {
              method: "patch",
              data: userData,
            });
            setSubmitting(false);
            setSnackbarStatus(true);
            setSnackbarMessage("Profile updated successfully.")
          };
          await updateClientDetail();
        } catch (error) {
          console.error(error.response);
          setSubmitting(false);
        }
      })();
    },
  });

  const {
    errors,
    touched,
    values,
    handleSubmit,
    isSubmitting,
    getFieldProps,
    setFieldValue,
  } = formik;

  useEffect(() => {
    let fieldToUpdate = [
      "city",
      "country",
      "dob",
      "gender",
      "language",
      "name",
    ];
    // Object.keys(userDetail).forEach((field) => {
    //     if (fieldToUpdate.includes(field)) {
    //         setFieldValue(field, userDetail[field], false);
    //     }
    // });
    setFieldValue("name", userDetail["name"], false);
    setFieldValue("language", userDetail["language"], false);
    setFieldValue("gender", userDetail["gender"], false);
    setFieldValue("dob", userDetail["dob"], false);
    setFieldValue("country", userDetail["country"], false);
    setFieldValue("city", userDetail["city"], false);
    setDob(userDetail.dob);
    setUserProfilePhoto(userDetail.photoId);
  }, [userDetail]);

  return (
    <>
      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={0} columns={{ xs: 12, sm: 12, md: 12 }}>
              {/* <Grid item xs={12} sm={12} md={8}>
                            
                        </Grid> */}
              <Grid item xs={12} sm={12} md={4}>
                <div className="account-image">
                  <UploadPhoto
                    setImageSrc={setUserProfilePhoto}
                    imageSrc={userProfilePhoto}
                    uploadImage={uploadImage}
                  />
                </div>
                <Stack spacing={1}>
                  <Stack direction={{ xs: "column", sm: "column" }} spacing={1}>
                    <TextField
                      fullWidth
                      label="Name"
                      size="small"
                      {...getFieldProps("name")}
                      error={Boolean(touched.name && errors.name)}
                      helperText={touched.name && errors.name}
                    />
                    <FormControl
                      sx={{ m: 1, minWidth: 120 }}
                      size="small"
                      className="select-name-align"
                    >
                      <InputLabel id="demo-select-small">Gender</InputLabel>
                      <Select
                        labelId="demo-select-small"
                        id="demo-select-small"
                        label="Gender"
                        {...getFieldProps("gender")}
                      >
                        <MenuItem value="">
                          <em>None</em>
                        </MenuItem>
                        <MenuItem value="m">Male</MenuItem>
                        <MenuItem value="f">Female</MenuItem>
                        <MenuItem value="o">Other</MenuItem>
                      </Select>
                    </FormControl>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        label="Date of birth"
                        value={dob}
                        onChange={(newValue) => {
                          setDob(newValue);
                        }}
                        renderInput={(params) => (
                          <TextField {...params} size="small" />
                        )}
                      />
                    </LocalizationProvider>
                    <FormControl
                      sx={{ m: 1, minWidth: 120 }}
                      size="small"
                      className="select-name-align"
                    >
                      <InputLabel id="demo-select-small">Language</InputLabel>
                      <Select
                        labelId="demo-select-small"
                        id="demo-select-small"
                        label="Language"
                        {...getFieldProps("language")}
                      >
                        <MenuItem value="">
                          <em>None</em>
                        </MenuItem>
                        <MenuItem value={1}>Hindi</MenuItem>
                        <MenuItem value={2}>English</MenuItem>
                        <MenuItem value={3}>Other</MenuItem>
                      </Select>
                    </FormControl>
                    <FormControl
                      sx={{ m: 1, minWidth: 120 }}
                      size="small"
                      className="select-name-align"
                    >
                      <InputLabel id="demo-select-small">Country</InputLabel>
                      <Select
                        labelId="demo-select-small"
                        id="demo-select-small"
                        label="Country"
                        {...getFieldProps("country")}
                      >
                        {countries &&
                          countries.map((option) => (
                            <MenuItem key={option._id} value={option._id}>
                              {option.name}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                    <TextField
                      fullWidth
                      label="City"
                      size="small"
                      {...getFieldProps("city")}
                      error={Boolean(touched.city && errors.city)}
                      helperText={touched.city && errors.city}
                    />
                    <Button type="submit" variant="contained">
                      Submit
                    </Button>
                    <Button variant="outlined" color="error" onClick={e => handleDeleteDialogOpen()}>
                      Delete my account
                    </Button>
                  </Stack>
                </Stack>
              </Grid>
            </Grid>
          </Box>
        </Form>
      </FormikProvider>
      <Dialog
        open={isDeleteDialogOpen}
        onClose={handleDeleteDialogClose}
        aria-labelledby="draggable-dialog-title"
      >
        <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
          Delete Account
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure, you want to delete your account? This process can not be undone.
          </DialogContentText>
          <DialogContentText>
            Instead, would you like to provide feedback?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleDeleteDialogClose}>
            Cancel
          </Button>
          <Button onClick={handleDeleteDialogClose} to="/feedback" component={RouterLink}>Feedback</Button>
          <Button onClick={deleteAccountHandler} color="error">Delete</Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right"
        }}
        open={snackBarStatus}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        message={snackBarMessage}
        action={snackBarAction}
      />
    </>
  );
}
