import React, { useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import Token from "../../util/Token";
import { Context } from "../../context";

export default function Logout() {
  let navigate = useNavigate();
  const { user, auth } = useContext(Context);
  const { setUserDetail } = user;
  const { setAuthenticated } = auth;

  useEffect(() => {
    Token.remove("_cmst");
    setUserDetail({});
    setAuthenticated(false);
    navigate("/");
  }, []);

  return <></>;
}
