export const is = {
    null: (value) => value === null,
    undefined: (value) => value === undefined,
    nullOrUndefined: (value) => is.undefined(value) || is.null(value),
    string: (value) => typeof value === "string",
    object: (value) => typeof value === "object",
    array: (value) => Array.isArray(value),
    empty: (value) => {
      if (is.nullOrUndefined(value)) {
        return true;
      }
      if (is.array(value) || is.string(value)) {
        return value.length === 0;
      }
      return is.object(value) && Object.keys(value).length === 0;
    },
  };
  