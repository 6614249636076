// React Library
import React, { useCallback, useEffect, useState } from 'react';
// MUI Components and icons
import {
    Box,
    ImageList,
    ImageListItem,
    Button,
    Typography,
    ImageListItemBar,
    IconButton,
    useMediaQuery
} from '@mui/material'
import CancelIcon from '@mui/icons-material/Cancel';
import CircularProgress from '@mui/material/CircularProgress';

// uuid
import { v4 as uuidv4 } from 'uuid';
//lodash
import isEmpty from 'lodash/isEmpty';

// User defined functions and components
import HttpService from '../../services/HttpService';
import Constant from '../../util/constants';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import CloseIcon from '@mui/icons-material/Close';
import { useTheme } from '@emotion/react';

const styles = {
  uploadBtn: {
    height: '-webkit-fill-available'
  },
  itemListBar: {
    background: 'linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, ' +
        'rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
    '& button': {
      color: '#fff'
    }
  }
}

const initialState = {
  imageList: [],
  loading: !1
}

function Photos({ location }) {
  const [photoState, setPhotoState] = useState(initialState)
  const [photoModal, setPhotoModal] = useState({open: false, src: ""});

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const getPhotoList = useCallback((photo, list) => {
    const length = photo.length;
    for(let p = length - 1; p >= 0; p--) {
      list.push({
        img: photo[p],
        title: `location image ${p}`,
        id: uuidv4(),
        style: {}
      });
    }
    return list;
  }, []);

  const handleImageUpload = useCallback((e) => {
    const [file] = e.target.files;
    const fileReader = new FileReader();
    let objectURL = '';
    let list = [...photoState.imageList];

    fileReader.onload = function() {
      objectURL = URL.createObjectURL(file);
      list.unshift({
        img: objectURL,
        title: 'Location',
        id: uuidv4(),
        style: { filter: 'blur(1.5px)' }
      })
      setPhotoState({ 
        imageList: list
      })
    }

    fileReader.onloadend = (reader) => {
      HttpService(
        Constant.methods.UPLOAD_IMAGE,
        { id: location._id, image: reader.target.result } 
      ).then((res) => {
        if(res.status) {
          const { data: { photos } } = res;
          const _list = getPhotoList(photos, []);
          URL.revokeObjectURL(objectURL);
           setPhotoState({
            imageList: _list
          })
        }
      })
    }
    fileReader.readAsDataURL(file);
  }, [photoState.imageList]);

  const handleDeleteImage = (imagePath) => async () => {
    const deleteRes = await HttpService(
      Constant.methods.REMOVE_IMAGE,
      { id: location._id, imagePath }
    )
    if(deleteRes.status) {
      const { data: { photos } } = deleteRes;
      const _list = getPhotoList(photos, []);
      setPhotoState({
        imageList: _list
      })
    }
  }

  useEffect(() => {
    if(!isEmpty(location)) {
      const list = [];
      const { photos } = location;
      const _list = getPhotoList(photos, list);
      setPhotoState({
        imageList: _list
      })
    }
  }, []);

  return (
      <Box className="cm-photos">
          <ImageList cols={3} rowHeight={90}>
              <ImageListItem>
                  <Button sx={styles.uploadBtn} aria-label="upload button" variant="outlined" component="label">
                      <input hidden accept="image/*" multiple type="file" onChange={handleImageUpload} />
                      <Typography variant="subtitle1">Add Photo</Typography>
                  </Button>
              </ImageListItem>
              {photoState.imageList.map((item) => (
                  <ImageListItem key={item.id}>
                    <img
                        src={item.img}
                        srcSet={item.img}
                        alt={item.title}
                        loading="lazy"
                        style={item.style}
                        onClick={() => {setPhotoModal({open: true, src: item.img})}}
                    />
                    <ImageListItemBar 
                      sx={styles.itemListBar}
                      position='top'
                      actionPosition='right'
                      actionIcon={
                        <IconButton onClick={handleDeleteImage(item.img)} aria-label={`delete ${item.title}`}>
                          <CancelIcon />
                        </IconButton>
                      }
                    />
                  </ImageListItem>
              ))}

              <Dialog
                   onClose={() => {setPhotoModal({open: false, src: ""})}}
                   aria-labelledby="customized-dialog-title"
                   open={photoModal.open}
              >
                  <IconButton
                    aria-label="close"
                    onClick={() => {setPhotoModal({open: false, src: ""})}}
                    sx={{
                      position: 'absolute',
                      right: 0,
                      top: 0,
                    }}
                  >
                    <CloseIcon />
                    </IconButton>
                    <DialogContent sx={{minHeight: "300px", minWidth: isSmallScreen ? "100%" : "600px", display: "flex", placeItems: "center", placeContent: "center", padding: isSmallScreen ? "30px 16px" : "32px 40px"}}>
                      <img src= {photoModal.src} alt="Review" />
                    </DialogContent>
              </Dialog>
          </ImageList>    
      </Box>
  )
}

export default React.memo(Photos)