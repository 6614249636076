import { Box } from "@mui/material";
import { OazaFooterPrimary } from "./OazaFooterPrimary";

export const OazaFooter = () => {
  return (
    <Box>
      <footer id="pt-footer">
        <OazaFooterPrimary />
      </footer>
    </Box>
  );
};
