import { Box, Grid, Container, Typography, Stack } from "@mui/material";

// components

import React from "react";

import Page from "../Page";

import AccessTimeIcon from "@mui/icons-material/AccessTime";

import PublicIcon from "@mui/icons-material/Public";

import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { CMFooter } from "../common/OazaFooter";

// ----------------------------------------------------------------------

export default function Refund() {
  const [email, setEmail] = React.useState();

  return (
    <Page title="Dashboard | Oaza-UI">
         <Typography
            variant="h4"
            style={{ textAlign: "start", display: "block" }}
          >
            Refund and Cancellation Policy
          </Typography>

          <Typography
            variant="p"
            alignItems={"flex-start"}
            style={{
              textAlign: "start",

              display: "block",

              marginBottom: 30,

              color: "grey",

              fontSize: 18,
            }}
          >
          The service being provided by Oaza Energy Pvt. Ltd.(aka Oaza Energy) is for charging of EVs at the 
          charging stations owned and/or operated by various CPO’s. The cost of charging a vehicle is collected at the beginning 
          as per his need like the amount/KWH and then the charging gun is unlocked and in case of any charging issues , 
          the consumer can connect to oaza support with the number displayed on the unit and also reachout for 
          support : <a href="mailto:support@oaza.energy">support@oaza.energy</a> and we would try to resolve the problem in 24 working Hrs. 
          The consumer at his own will not use the charging service if he/she finds the service not upto 
          the mark or the prices for the services are considered high. Once the consumer’s electric vehicle 
          has been charged as per the unit meter reading, CPO reserves the right to charge the cost of charging from the consumer. 
          </Typography>

    </Page>
  );
}
