import { useState, useEffect, useContext } from 'react';
import {Outlet } from 'react-router-dom';


import axios from '../../util/axios';
import Token from '../../util/Token';
import { Context } from '../../context';

// ----------------------------------------------------------------------

import Header from "./Header"
import { OazaFooter } from '../../component/common/OazaFooter';
import { Container } from '@mui/material';

// ----------------------------------------------------------------------

export default function DashboardLayout() {
  const [userContact, setUserContact] = useState('');
  const [userEmail,setUserEmail] = useState('');
  const [userPhone,setUserPhone] = useState('');
  const [userId, setUserId] = useState('');


  const { user, auth } = useContext(Context);
  const { userDetail, setUserDetail } = user;
  const { authenticated, setAuthenticated } = auth;

  /**
   * First thing to call after login or page refresh
   * This function will check if JWT token is set or not
   * If not it will redirect user to login
   * If yes, fetch JWT token from local storage and parse it. set userId to state
   */
  useEffect(() => {
    const userData = Token.parsedToken();
    if (userData && userData.success && userData.data) {
      if(userData.data.email){
         setUserEmail(userData.data.email);
         setUserContact("email")
        }
      if(userData.data.phone){
        setUserPhone(userData.data.phone)
        setUserContact("phone")
      }
      setUserId(userData.data._id);
    }
  }, []);

  /**
   * First thing to call after login or page refresh
   * return axios.get("/user/getUserDetail", {
      params: {
        username: userData.data.username
      }
   */
  useEffect(() => {
    if (!userContact || !userId) {
      return;
    }
    (async function () {
      try {
        const getUserDetail = async () => {
          const response = await axios(`${process.env.REACT_APP_API_END_POINT}user/detail?userId=${userId}`, {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${Token.get()}`
            }
          });
          const userDetailRes = response.data.data;
          if (userDetailRes) {
            setUserDetail(userDetailRes);
          }
        };

        await getUserDetail();
      } catch (error) {
        console.error(error.response);
      }
    })();
  }, [setUserDetail, userContact, userId]);

  useEffect(() => {
    if (userDetail && userDetail._id) {
      setAuthenticated({
        ...authenticated,
        isAuth: !0,
        authPopup: !1,
        redirect: null
      });
    }
  }, [authenticated, setAuthenticated, userDetail]);

  return (
    <>
      <Header />
      {
        window.location.pathname === '/' || window.location.pathname==='/delete-user-account' || window.location.pathname==='/faqs' ? <Outlet /> : <Container sx={{ py:{xs:'2vh', md:'5vh'},flexGrow: 1 }}> <Outlet /> </Container> 
      }
     <OazaFooter/>
    </>
  );
}
