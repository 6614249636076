import * as React from "react";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { Link as RouterLink } from "react-router-dom";
import { Link } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";

const options = [
  "None",
  "Atria",
  "Callisto",
  "Dione",
  "Ganymede",
  "Hangouts Call",
  "Luna",
  "Oberon",
  "Phobos",
  "Pyxis",
  "Sedna",
  "Titania",
  "Triton",
  "Umbriel",
];

const ITEM_HEIGHT = 48;

export default function HeaderMobileMenu() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton
        onClick={handleClick}
        sx={{ p: 0 }}
        style={{ color: "#ffffff" }}
      >
        <MenuIcon />
      </IconButton>
      <Menu
        sx={{ mt: "45px" }}
        id="menu-mobile-screen"
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <Link to="/home" component={RouterLink} className="popover-menu">
          <MenuItem onClick={handleClose}>
            <Typography textAlign="center">Home</Typography>
          </MenuItem>
        </Link>
        <Link to="/product" component={RouterLink} className="popover-menu">
          <MenuItem onClick={handleClose}>
            <Typography textAlign="center">Products</Typography>
          </MenuItem>
        </Link>
        <Link
          to="/why-oaza"
          component={RouterLink}
          className="popover-menu"
        >
          <MenuItem onClick={handleClose}>
            <Typography textAlign="center">Why Oaza</Typography>
          </MenuItem>
        </Link>
        <Link to="/feedback" component={RouterLink} className="popover-menu">
          <MenuItem onClick={handleClose}>
            <Typography textAlign="center">Feedback</Typography>
          </MenuItem>
        </Link>
        {/* <Link to="/about-us" component={RouterLink} className="popover-menu">
          <MenuItem onClick={handleClose}>
            <Typography textAlign="center">About us</Typography>
          </MenuItem>
        </Link> */}
        <Link to="/contact-us" component={RouterLink} className="popover-menu">
          <MenuItem onClick={handleClose}>
            <Typography textAlign="center">Contact us</Typography>
          </MenuItem>
        </Link>
      </Menu>
    </>
  );
}
