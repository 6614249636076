import axios from "axios";
//import Cookies from "js-cookie";

const instance = axios.create();

// Add a response interceptor
instance.interceptors.response.use(
  function(config) {
    // Do something before request is sent
    return config;
  },
  function(error) {
    if (error.response && error.response.status === 403) {
      //Cookies.remove("token");
      window.location.href = "/login";
    }
    return Promise.reject(error);
  },
);

export default instance;
