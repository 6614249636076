import React from 'react'
import { Fab, IconButton, Button, Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { LoadingButton } from '@mui/lab';

const FabComponent = styled(Fab)`
    position: absolute;
    left: 16px;
    background-color: #fff;
    z-index: 10;
    :hover{
        background-color: var(--app-color);
        & svg{
            color: #fff;
        }
    }
`;

const ImgComponent = styled('img')`
    display: inline-block;
    vertical-align: middle;
`

const ExpandMore = styled((props) => {
    const { expand, ...other } = props;
    return <IconButton {...other} />;
  })(({ theme, expand }) => ({
    transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  }));

const AppButton = styled(Button)(({
  borderRadius: '50px',
 ' &:hover': {
      backgroundColor: 'var(--app-color)',
      color:'var(--app-base)'
  }
}))

const AppLoadingButton = styled(LoadingButton)`
    border-radius: 50px;
    &:hover {
        background-color: var(--app-color)
    }
`

export const StyledExpand = (props) => (
    <ExpandMore { ...props } />
)

export const StyledFab = ({ children, ...props }) => (
    <FabComponent size="medium" {...props}>{children}</FabComponent>
)

export const StyledImg = (props) => (
    <ImgComponent { ...props } />
)

export const StyledButton = ({ children, ...props }) => (
    <AppButton { ...props }>{children}</AppButton>
)

export const StyledLoadingButton = ({ children, ...props }) => (
    <AppLoadingButton { ...props }>{children}</AppLoadingButton>
)

export const TabPanel = ({children, value, index, ...props }) => (
    <div 
    role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...props}
    >
        {value === index && (
        <Box sx={{ p: 3 }}>
          {children}
        </Box>
      )}
    </div>
)