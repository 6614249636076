import { forwardRef, memo, useState, useEffect } from 'react';
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton"
import Tooltip from '@mui/material/Tooltip';
import Constant from '../../util/constants';

import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';

const { action, types } = Constant;
const IOS = typeof navigator !== types.UNDEFINED && /iPad|iPhone|iPod/.test(navigator.userAgent);

function MuiDrawer(props, ref) {
    const { anchor, children, open, toggleDrawer, className = '', closeToolTipText = '' } = props;
    const [drawerState, setDrawerState] = useState({
        [action.TOP]: false,
        [action.LEFT]: false,
        [action.RIGHT]: false,
        [action.BOTTOM]: false
    });

    useEffect(() => {
        setDrawerState({
            [anchor]: open
        });
    }, [open])

    return (
        <SwipeableDrawer
        anchor={anchor}
        open={drawerState[anchor]}
        ref={ref}
        onClose={toggleDrawer}
        onOpen={toggleDrawer}
        disableBackdropTransition={!IOS}
        disableDiscovery={IOS}
        hideBackdrop={true}
        className={className}
        >
            <Box role="presentation" className='MuiDrawer-paper__content' aria-labelledby='Mui-swipeable-drawer'>
                {children}
            </Box>
            <Box className="MuiDrawer-paper--close icon-close">
                <Tooltip title={closeToolTipText} placement="right-start">
                    <IconButton onClick={toggleDrawer} aria-label="close dialog">
                        <CloseOutlinedIcon />
                    </IconButton>
                </Tooltip>
            </Box>
        </SwipeableDrawer>
    )
}

export default memo(forwardRef(MuiDrawer));