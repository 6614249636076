import PropTypes from 'prop-types';
// material
import { Box } from '@mui/material';
import logo_color from "../image/logo/logo_color_150x45.png";
// ----------------------------------------------------------------------

Logo.propTypes = {
  sx: PropTypes.object
};

export default function Logo({ sx }) {
  return <Box component="img" src={logo_color} sx={{ ...sx }} />;
}
