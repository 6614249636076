import { Container } from "@mui/material";
import { useSearchParams } from "react-router-dom";
import Page from "../../Page";
import { PaymentStatusContainer } from "../QRScanner/PaymentStatusContainer";

export const PaymentResponse = () => {
  const [searchParams] = useSearchParams();
  const [, chargerId, connectorId] =
    searchParams.get("referenceId")?.split("-") ?? [];

  const message =
    searchParams.get("status") === "paid"
      ? "Payment is successful! Please wait, charger will start in a while."
      : "Error";
  return (
    <Page
      title="Dashboard | Oaza-UI"
      sx={{ marginTop: { xs: "128px", md: "62px", overflow: "hidden" } }}
    >
      <Container
        sx={{
          padding: "50px",
          width: "100%",
          margin: "auto",
        }}
        style={{ maxWidth: "500px" }}
      >
        <PaymentStatusContainer
          chargerId={chargerId}
          connectorId={connectorId}
          status={searchParams.get("status") === "paid"}
          message={message}
        />
      </Container>
    </Page>
  );
};
