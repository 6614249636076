import {
  chargerStatus,
  chargerStop,
  chargerUrl,
  chargerStart,
  liveConnectorUrl,
  sendOtp,
  verifyOtp,
} from "./endpoints";
import URLs from "../services/urls";
import { validateEmail, validatePhone } from "../util/phoneEmailValidation";

export const queryKeys = {
  charger: (chargerId, query) => {
    return ["charger", { method: "GET", url: chargerUrl(chargerId, query) }];
  },
  chargerStatus: (body) => {
    return ["chargerStatus", { method: "POST", url: chargerStatus, body }];
  },
  stopCharger: (body) => {
    return ["stopCharger", { method: "POST", url: chargerStop, body }];
  },
  liveConnectorIds: (chargerId) => {
    return [
      "liveConnectorIds",
      { method: "GET", url: liveConnectorUrl(chargerId) },
    ];
  },
  sendOtp: (body) => {
    return ["sendOtp", { method: "POST", url: sendOtp, body }];
  },
  verifyOtp: (body) => {
    return [
      "verifyOtp",
      { method: "POST", url: verifyOtp, body },
    ];
  },
  startFreeCharger: (userInfo,chargerId,connector_id, chargingType, action) => {
    const payload = {
      chargerId,connectorId: connector_id, chargingType, action
    }
    if(validateEmail(userInfo)) payload.email = userInfo;
    if(validatePhone(userInfo)) payload.phone = userInfo;
    return [
      "startFreeCharger",
      { method: "POST", url: chargerStart, body: payload },
    ];
  },
  getTrip: (user) => {
    return ["getTrip", { method: "GET", url: `${URLs.GET_TRIP}?userId=${user}` }]
  },
  createTrip: () => {
    return [
      "createTrip", 
    ]
  },
  updateTrip: () => {
    return [
      "updateTrip"
    ]
  },
  deleteTrip: () => {
    return [
      "deleteTrip"
    ]
  }
};
