import { useEffect, useState, useContext, useRef} from "react";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import DirectionsIcon from "@mui/icons-material/Directions";
import LocationTabs from "./LocationTabs";
import HttpService from "../../services/HttpService";
import { Context } from "../../context";
import Constant from "../../util/constants";
import { StyledImg, StyledButton } from "../common/Styled";
import { Stack, Box, Paper, CircularProgress, Link } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import isEmpty from "lodash/isEmpty";
import isBoolean from "lodash/isBoolean";
import ReportIssue from "./ReportIssue";
import ShareDialog from "./ShareDialog";
import {
  getGeoLocation,
  toggleFavourite,
  hasAddedToTrip,
} from "../../util/Display";

// Component Images
import LoaderGif from "../../image/Charging-GIF.gif";
import ShareOutlinedIcon from "@mui/icons-material/ShareOutlined";
import FavoriteBorderOutlinedIcon from "@mui/icons-material/FavoriteBorderOutlined";
import FavoriteOutlinedIcon from "@mui/icons-material/FavoriteOutlined";
import axios from '../../util/axios';
// Component CSS
import "./MarkerLocationDetail.css";

const styles = {
  subHeader: {
    display: "flex",
    alignItems: "center",
    gap: "10px",
  },
  cardSectionLeft: {
    display: "grid"
  },
  avatar: {
    bgcolor: "var(--app-color)",
    fontSize: "16px",
    cursor: "pointer",
    width: "35px",
    height: "35px",
  },
  iconClose: {
    position: "absolute",
    top: 0,
    right: 8,
  },
  cardContent: {
    paddingTop: 0,
  },
  stack: {
    position: "relative",
    justifyContent: "space-between",
    gap: "50px"
  },
  fade: {
    transitionDelay: "1000ms",
  },
  shareIcon: {
    position: "absolute",
  },
};

const initialState = {
  favourite: !1,
  isShowIssue: !1,
  render: !0,
  data: [],
  shareOpen: !1,
  isAddedToStop: null,
  isThirdPartyCPO: false,
};

export default function MarkerLocationDetail({
  selectedLocation,
  marketLocationDetail,
  onDirectionHandler,
  map,
  networkFiltersArr,
}) {
  const [locationDetailState, setLocationDetail] = useState(initialState);
  const { user, maps, auth } = useContext(Context);
  const containerRef = useRef();

  const toggleFavorites = async () => {
    setLocationDetail((prev) => ({
      ...prev,
      favourite: !locationDetailState.favourite,
    }));
    toggleFavourite(auth, user, locationDetailState, selectedLocation._id);
  };

  const onDirection = async () => {
    const pos = await getGeoLocation();
    onDirectionHandler(
      { lat: pos.latitude, lng: pos.longitude },
      { lat: selectedLocation.latitude, lng: selectedLocation.longitude }
    );
  };

  const addStop = (e) => {
    const {
      directionRendererInstance,
      google: { maps },
    } = window;
    const direction = directionRendererInstance.getDirections();
    if (direction) {
      const {
        request: { destination, origin },
      } = direction;
      const { latitude, longitude } = selectedLocation;
      const { isAddedToStop } = locationDetailState;
      onDirectionHandler(
        { lat: origin.location.lat(), lng: origin.location.lng() },
        { lat: destination.location.lat(), lng: destination.location.lng() },
        { location: new maps.LatLng(latitude, longitude), stopover: true },
        isAddedToStop
      );
      setLocationDetail((state) => ({
        ...state,
        isAddedToStop: !isAddedToStop,
      }));
    }
  };

  const onReportIssue = (render, show) => () => {
    const { authenticated, setAuthenticated } = auth;
    if (!authenticated.isAuth) {
      setAuthenticated({
        ...authenticated,
        authPopup: !0,
        redirectTo: Constant.paths.HOME,
      });
      return;
    }

    setLocationDetail({
      ...locationDetailState,
      isShowIssue: show,
      render,
    });
  };

  const handleClick = () => {
    setLocationDetail((prev) => ({
      ...prev,
      shareOpen: !locationDetailState.shareOpen,
    }));
  };

  const getLocationDetail = (lat, long, stop) => {
    const locationApiCall= async ()=> {
      let response = await axios(`${process.env.REACT_APP_API_END_POINT}v2/location/get-area-location-detail?lat=${lat}&lng=${long}`, {
        method: 'get'
    });
      if (response.status) {
        setLocationDetail((prev) => ({
          ...prev,
          data: response.data.data,  
          isAddedToStop: stop,
        }));
      }
    }
      locationApiCall();
  };

  useEffect(() => {
    const { directionRendererInstance } = window;
    const direction = directionRendererInstance.getDirections();
    const {
      latitude,
      longitude,
      _id,
      id = 0,
      party_id = null,
    } = selectedLocation;
    let isAddedToStop = null;

    if (direction) {
      isAddedToStop = hasAddedToTrip(latitude, longitude);
    }
      if (selectedLocation && (_id || id)) {
        !party_id && getLocationDetail(selectedLocation.latitude, selectedLocation.longitude, isAddedToStop);
        party_id &&
          setLocationDetail((state) => ({
            ...state,
            isThirdPartyCPO: !0,
            data: selectedLocation,
            isAddedToStop,
          }));
      }
  },[]);

  useEffect(() => {
    const { userDetail } = user;
    if (!isEmpty(userDetail)) {
      const { favoriteLocations } = userDetail;
      let toggle = false;
      favoriteLocations.some((loc) => selectedLocation._id === loc._id)
        ? (toggle = !0)
        : (toggle = !1);
      toggle &&
        setLocationDetail((prev) => ({
          ...prev,
          favourite: toggle,
        }));
    }
  }, [user]);

  const getConnectorCount = (location) => {
    let locationCount = 0;
    if (!location.isThirdPartyCPO && location?.data?.length > 0) {
      for(let i = 0; i < location?.data?.length; i++){
        for (let j = 0; j < location?.data[i]?.devices?.length; j++) {
          let stations = location?.data[i]?.devices[0].stations;
          if (stations.length > 0) {
            locationCount = locationCount + stations.length;
          }
        }
      }
    }

    if (location.isThirdPartyCPO) {
      locationCount = location.data.evses.length;
    }
    return locationCount;
  };

  const getChargingStationLogo = () =>{
    let imgSrc = networkFiltersArr?.length > 0 ? networkFiltersArr.filter((network) => network.name === locationDetailState.data[0]?.provider)[0] : null;
    if(imgSrc){
      return <img src={imgSrc.src} alt={`${imgSrc.name} logo`} height={35} width={35} />
    } else {
      let pvtSrc = networkFiltersArr?.length > 0 ? networkFiltersArr.filter((network) => network.name === "Private CPO")[0] : null;
      return <img src={ pvtSrc?.src ?? ''} alt={pvtSrc?.name ?? ''} height={35} width={35} />
    }
  }

  return (
    <>{locationDetailState?.data?.length > 0 && 
      <Card
      sx={{ maxWidth: 375, width: 375, maxHeight: 512 }}
      ref={containerRef}
      className="location-detail-root"
    >
      {locationDetailState.render && (
        <>
          <CardHeader
            subheader={
              locationDetailState.data[0] && (
                <Paper component="div" elevation={0}>
                  {isBoolean(locationDetailState.isAddedToStop) && (
                    <Stack direction="row">
                      <StyledButton
                        variant="contained"
                        size="small"
                        color={
                          !locationDetailState.isAddedToStop
                            ? "primary"
                            : "error"
                        }
                        onClick={addStop}
                      >
                        {!locationDetailState.isAddedToStop
                          ? Constant.label.ADD_TO_TRIP_PLANNER
                          : Constant.label.REMOVE_TRIP}
                      </StyledButton>
                    </Stack>
                  )}
                  <Stack
                    direction="row"
                    alignItems="center"
                    gap={2}
                    mt={3}
                    mb={2}
                  >
                    {getChargingStationLogo()}
                    {/* <SvgChargingStationLogo /> */}
                    <div>
                  <Typography
                      color="text.secondary"
                      textAlign="left"
                      variant="h4"
                      fontWeight="bold"
                      textTransform="capitalize"
                      fontSize="1rem"
                    >
                      {locationDetailState.data[0]?.display_name
                        ? locationDetailState.data[0]?.display_name
                        : locationDetailState.data[0]?.name}
                  </Typography>
                  <Typography
                    variant="caption"
                    component="div"
                    textAlign="left"
                    mt={1}
                  >
                    {locationDetailState.data[0]?.address}
                  </Typography>
                    </div>
                  </Stack>
                  <Stack direction="row" sx={styles.stack}>
                    <Box
                      className="card-section-left"
                      sx={styles.cardSectionLeft}
                    >
                      <Box component="div" ml={2} sx={styles.subHeader}>
                        {/* <Typography variant="h6">{`
                    ${locationDetailState.data?.stations.reduce((prev, curr) => prev + (curr.isAvailable ? 1 : 0) , 0)}/${locationDetailState.data?.stations.length}`}
                  </Typography> */}
                        <Typography variant="h6">
                          {getConnectorCount(locationDetailState)}
                        </Typography>
                        <Typography
                          variant="caption"
                          textAlign="left"
                          fontSize="0.625rem"
                          fontWeight={500}
                        >
                          Charge Points available
                        </Typography>
                      </Box>
                    </Box>
                    <Stack
                      className="location-icons"
                      direction="row"
                      spacing={0.5}
                      alignItems="center"
                      justifyContent="center"
                    >
                      <IconButton
                        onClick={toggleFavorites}
                        color="primary"
                        aria-label="location"
                      >
                        {!locationDetailState.favourite ? (
                          <FavoriteBorderOutlinedIcon />
                        ) : (
                          <FavoriteOutlinedIcon />
                        )}
                      </IconButton>

                      <Box component="div">
                        {/* <Typography variant="h6">{locationDetailState.data.hoursRate}</Typography>
                  <Typography variant="caption" fontSize='0.625rem' fontWeight={500} pr={2}>Price from</Typography> */}
                        <IconButton
                          color="primary"
                          aria-label="share-location"
                          onClick={handleClick}
                        >
                          <ShareOutlinedIcon />
                        </IconButton>
                      </Box>
                      {!maps.mapsData.loading ? (
                        <Avatar sx={styles.avatar} onClick={onDirection}>
                          <DirectionsIcon />
                        </Avatar>
                      ) : (
                        <CircularProgress />
                      )}
                    </Stack>
                  </Stack>
                  <IconButton
                    sx={styles.iconClose}
                    aria-label="settings"
                    onClick={(e) => marketLocationDetail()}
                  >
                    <CloseIcon />
                  </IconButton>
                </Paper>
              )
            }
          />
          <CardContent sx={styles.cardContent} className="list-card-content">
            {!locationDetailState.data[0] ? (
              <StyledImg src={LoaderGif} alt="loading" width={128} />
            ) : (
              <LocationTabs
                locationDetail={locationDetailState.data}
                onReportClick={onReportIssue}
                networkFiltersArr={networkFiltersArr}
                isThirdPartyCPO={locationDetailState.isThirdPartyCPO}
              />
            )}
          </CardContent>
        </>
      )}
      {locationDetailState.shareOpen && (
        <ShareDialog
          open={locationDetailState.shareOpen}
          map={map}
          location={selectedLocation}
          onClose={handleClick}
        />
      )}
      {<ReportIssue
        isShow={locationDetailState.isShowIssue}
        container={containerRef.current}
        chargerId={
          locationDetailState?.isThirdPartyCPO
            ? locationDetailState?.data[0]?.id
            : locationDetailState?.data[0]?.devices[0]?.deviceId
        }
        onReportClick={onReportIssue}
        locationId={
          locationDetailState?.isThirdPartyCPO
            ? selectedLocation?.id
            : selectedLocation?._id
        }
      />}
    </Card>
    }</>
  );
}
