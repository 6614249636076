import * as Yup from 'yup';
import { useContext, useEffect, useState } from 'react';
import { useFormik, Form, FormikProvider } from 'formik';
// material
import { Stack, TextField, Button, Box, Link, Grid, Typography, MenuItem } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import LoadingButton from '@mui/lab/LoadingButton';
import axios from '../../util/axios';
import { Context } from '../../context';
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";

// ----------------------------------------------------------------------

export default function RequestChargerForm() {
  const { user, userType } = useContext(Context);
  const { userDetail } = user;
  const [error, setError] = useState('');
  const [successMessage, setSuccessMessage] = useState();
  const [purpose, setPurpose] = useState(null);
  const [makeList, setMakeList] = useState([]);
  const [selectedMake, setSelectedMake] = useState(null);
  const [modelList, setModelList] = useState([]);
  const handlePurposeChange = (event, value) => {
    setPurpose(value);
  };

  useEffect(() => {
    (async function () {
      try {
        const getMakeList = async () => {
          const response = await axios(`${process.env.REACT_APP_API_END_POINT}util/list-make`, {
            method: 'get'
          });
          const makeList = response.data.data;
          setMakeList(makeList);
        };

        await getMakeList();
      } catch (error) {
        console.error(error.response);
      }
    })();
  }, []);
  useEffect(() => {
    if (!selectedMake) {
      return;
    }
    (async function () {
      try {
        const getMakesModelList = async () => {
          const response = await axios(`${process.env.REACT_APP_API_END_POINT}util/list-model?makeId=${selectedMake}`, {
            method: 'get'
          });
          const makeModelList = response.data.data;
          setModelList(makeModelList);
        };

        await getMakesModelList();
      } catch (error) {
        console.error(error.response);
      }
    })();
  }, [selectedMake]);

  const resetPasswordSchema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
    email: Yup.string().email("Invalid email"),
    phone: Yup.string(),
    charger: Yup.string(),
  });

  const formik = useFormik({
    initialValues: {
      name: userDetail.name || '',
      email: '',
      phone: '',
      make: '',
      model: '',
      charger: ''
    },
    validationSchema: resetPasswordSchema,
    onSubmit: (requestChargerData, { setSubmitting, resetForm }) => {
      // if (!userDetail) {
      //   setError("Invalid session");
      //   setSuccessMessage("");
      //   setSubmitting(false);
      //   return;
      // }
      if (userDetail && userDetail._id) {
        requestChargerData['user'] = userDetail?._id;
      }
      if (purpose && purpose === "COMMERCIAL") {
        delete requestChargerData['make'];
        delete requestChargerData['model'];
      }
      (async function () {
        try {
          const registerHelper = async () => {
            const response = await axios(`${process.env.REACT_APP_API_END_POINT}util/request-charger`, {
              method: 'post',
              data: requestChargerData
            });
            const resetPasswordResponse = response.data.data;
            setSubmitting(false);
            resetForm();
            setError('');
            setSuccessMessage("Request submitted successfully.");
          };
          await registerHelper();
        } catch (error) {
          setSubmitting(false);
          if (error.response && error.response.data && error.response.data.message) {
            setError(error.response.data.message);
            setSuccessMessage("");
          }
        }
      })();
      //navigate('/dashboard', { replace: true });
    }
  });

  const { errors, touched, handleSubmit, isSubmitting, getFieldProps, resetForm, setFieldValue } = formik;

  const handleMakeChange = (e) => {
    if (!e.target.value) {
      return;
    }
    setSelectedMake(e.target.value);
    setFieldValue('make', e.target.value, false)
  }

  useEffect(() => {
    setFieldValue("name", userDetail["name"], false);
    setFieldValue("email", userDetail["email"], false);
  }, [userDetail]);

  return (
    <FormikProvider value={formik}>
      <div style={{ color: 'red' }}>{error}</div>
      <div style={{ color: 'green' }}>{successMessage}</div>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={0} columns={{ xs: 12, sm: 12, md: 12 }}>
            <Grid item xs={12} sm={12} md={4}>
              <Stack spacing={1}>
                <Typography variant="h6" style={{ color: "grey" }}>
                  {" "}
                  Request a charger!
                </Typography>
                <Stack direction={{ xs: 'column', sm: 'column' }} spacing={1}>
                  <TextField
                    fullWidth
                    size="small"
                    label="Name"
                    {...getFieldProps('name')}
                    error={Boolean(touched.name && errors.name)}
                    helperText={touched.name && errors.name}
                  />
                  <TextField
                    fullWidth
                    type='email'
                    size="small"
                    label="Email"
                    {...getFieldProps('email')}
                    error={Boolean(touched.email && errors.email)}
                    helperText={touched.email && errors.email}
                  />
                  <TextField
                    fullWidth
                    size="small"
                    type='number'
                    label="Phone"
                    {...getFieldProps('phone')}
                    error={Boolean(touched.phone && errors.phone)}
                    helperText={touched.phone && errors.phone}
                  />
                  <ToggleButtonGroup
                    color="primary"
                    value={purpose}
                    exclusive
                    onChange={handlePurposeChange}
                  >
                    <ToggleButton value="PERSONAL" fullWidth>Personal</ToggleButton>
                    <ToggleButton value="COMMERCIAL" fullWidth>Commercial</ToggleButton>
                  </ToggleButtonGroup>
                  {(purpose && purpose === "PERSONAL") && <>
                    <TextField
                      fullWidth
                      select
                      size="small"
                      label="Make of the Vehicle"
                      {...getFieldProps('make')}
                      onChange={handleMakeChange}
                      error={Boolean(touched.make && errors.make)}
                      helperText={touched.make && errors.make}
                      className="select-name-align"
                    >
                      {makeList &&
                        makeList.map((option) => (
                          <MenuItem key={option._id} value={option._id}>
                            {option.name}
                          </MenuItem>
                        ))}
                    </TextField>
                    <TextField
                      fullWidth
                      select
                      size="small"
                      label="Model of the Vehicle"
                      {...getFieldProps('model')}
                      error={Boolean(touched.model && errors.model)}
                      helperText={touched.model && errors.model}
                      className="select-name-align"
                    >
                      {modelList &&
                        modelList.map((option) => (
                          <MenuItem key={option._id} value={option._id}>
                            {option.name}
                          </MenuItem>
                        ))}
                    </TextField>
                  </>}
                  {(purpose && purpose === "COMMERCIAL") && <TextField
                    fullWidth
                    size="small"
                    label="Enter your requirements"
                    {...getFieldProps('charger')}
                    error={Boolean(touched.charger && errors.charger)}
                    helperText={touched.charger && errors.charger}
                  />}
                </Stack>
                <Stack direction={{ xs: 'column', sm: 'row' }} spacing={1}>
                  <LoadingButton
                    size="small"
                    type="submit"
                    variant="contained"
                    loading={isSubmitting}
                    style={{ width: "150px", textAlign: "center" }}
                  >
                    Save
                  </LoadingButton>
                  <Button variant="outlined" component={RouterLink} to="/">
                    Back to home
                  </Button>
                </Stack>
              </Stack>

            </Grid>

          </Grid>
        </Box>
      </Form>
    </FormikProvider>
  );
}
