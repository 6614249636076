import {  CircularProgress, Container, Divider, Paper, Stack, Typography, styled } from "@mui/material"
import Page from "../Page"
import {  useState } from "react";
import downloadFill from "@iconify/icons-eva/download-fill";
import redirectFill from "@iconify/icons-eva/arrow-back-fill";
import  html2pdf  from "html2pdf.js";
import { chargerTransactionActivityUrl } from "../../react-query/endpoints";
import axios from "axios";
import { useQuery } from "@tanstack/react-query";
import { useLocation, useNavigate } from "react-router-dom";
import { is } from "../../util/is";
import { isValid, format } from "date-fns";
import { Icon } from "@iconify/react";
import { LoadingButton } from "@mui/lab";
import Constant from "../../util/constants";

const {chargeConnectDisconnectMsg} = Constant;

const formatDateTime = (dateTimeStamp, dateFormat) => {
  const parsedDate = new Date(dateTimeStamp);
  return !is.empty(dateTimeStamp) && isValid(parsedDate)
    ? format(parsedDate, dateFormat)
    : "";
};

const StyledContainer = styled(Container)(({theme})=>({
margin:"auto",
width:"100%",
padding:theme.spacing(14),
display:"flex",
flexDirection:"column",
alignItems:"center",
justifyContent:"center",
[theme.breakpoints.down('sm')]:{
    padding:theme.spacing(0),
    paddingTop: "24px"
},
}));

export const StyledTypography = styled(Typography)(({ theme, fontSize, fontWeight }) => ({
    fontSize: fontSize ? fontSize : theme.spacing(2.1),
    fontWeight: fontWeight ? fontWeight : "500",
    "@media (max-width: 900px)": {
      fontSize: "13px"
    }
  }));

export const StyledLoadingButton = styled(LoadingButton)(({theme})=>({

  fontSize:theme.spacing(3), 
  marginBottom: theme.spacing(2),
  [theme.breakpoints.down('sm')]: {
      fontSize: "14px" 
    }
}));

export const StyledPaper = styled(Paper)(({theme})=>({
  width: "50%",
  marginLeft:theme.spacing(1),

  "@media (max-width: 900px)": {
    width: "100%",
    margin: theme.spacing(0),
  }
}))

export const InfoBlock = ( {centered , value1,value2,alignLeft,sx,val1Normal,val2Normal})=>{
    const centerStyle = {
        direction:"row",
        justifyContent:"center",
        alignItems:"center",
    }
 return (
    <Stack  {...(centered && {...centerStyle})} {...(sx && {sx:sx})} {...(alignLeft && {alignItems:"start"})}>
    <StyledTypography  fontWeight={val1Normal?false:"600"} fontSize={val1Normal?"15px":false} marginRight={0.3} flex={val1Normal?false:"none"}>{value1}  </StyledTypography><StyledTypography fontSize={val2Normal?"13px":false} >{value2}</StyledTypography>
   </Stack>
 )    
}

const fetchInvoiceDetails = async (endPoint, activitytoken) => {
  const response = await axios.get(chargerTransactionActivityUrl(endPoint), {
    headers: {
      authorization:`Bearer ${activitytoken}`
    }
  })
  return response.data;
}


const TransactionInvoice = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  const token = queryParams.get('token');

const [receiptObj,setReceiptObj] = useState({
    receiptNo:"",
    receiptDate:"",
    stationId:"",
    stationAddress:"",
    chargingDuration:"",
    currency:"",
    energyConsumption:"",
    totalCostExcludingTax:"",
    taxPercent:"", 
    taxAmount:"",
    transactionPercent:"",
    transactionFee:"",
    totalCostIncludingTaxAndTransactionFee:"",
    email: "",
    chargeBoxId:""
})

const onFetchingInvoiceDetails = (data) => {
  const {invoiceData} = data;
  if (!is.empty(invoiceData)){
  const {
     chargeBoxAddress,
     invoiceId,
     totalCostExcludingTax,
     totalCostIncludingTaxAndTransactionFee,
     taxPercent,
     taxAmount,
     transactionFee,
     currency,
     chargeBox,
     energyConsumption,
     starttime,
      stoptime,
      refundAmount,
      totalAmountPaid,
      email,
      chargeBoxId
} = invoiceData;
const updatedReceiptData ={
  receiptNo:invoiceId,
  receiptDate:`${formatDateTime(stoptime, "yyyy-MM-dd 'at' HH:mm")}`,
  chargingDuration: `${formatDateTime(
    starttime,
    "yyyy-MM-dd HH:mm"
  )} - ${formatDateTime(stoptime, "HH:mm")}`,
  stationId:chargeBox,
  stationAddress:chargeBoxAddress,
  currency,
  energyConsumption,
  totalCostExcludingTax,
  taxPercent, 
  taxAmount,
  transactionFee,
  totalCostIncludingTaxAndTransactionFee,
  refund:refundAmount,
  totalAmountPaid,
  email,
  chargeBoxId
} 
setReceiptObj(updatedReceiptData);
  }
  return
}

const { isLoading } = useQuery(["invoice"], () => fetchInvoiceDetails("invoice", token), {
  enabled: !is.empty(token),
  onSuccess: onFetchingInvoiceDetails,
  onError: (err) => {
    console.log(err)
  }
});


const handlePrintPDF = () => {
   const invoice = document.getElementById('invoice');
   let invoiceClone = invoice.cloneNode(true);
   invoiceClone.style.margin="20px";
   invoiceClone.style.border = '2px solid #48C13E'; 
   let invoiceDiv = document.createElement("div");
   invoiceDiv.style.display="flex";
   invoiceDiv.style.alignItems="center";
   invoiceDiv.style.justifyContent="center";
   invoiceDiv.appendChild(invoiceClone);

   html2pdf().from(invoiceDiv).save(`invoice-${receiptObj.receiptNo}.pdf`);
  };

  const redirectToChargerDetails = (charger) =>{
    // const charger = invoiceId.split("-")[0];
    navigate(`/charger/${charger}`);
  }

  if (isLoading) {
    return <Page
        title="Invoice | Oaza"
        sx={{ marginTop: { xs: "128px", md: "62px", overflow: "hidden" } }}
      > <StyledContainer maxWidth="lg"><CircularProgress
      color="success"
      size={40}
      thickness={4}
      variant="indeterminate" /> </StyledContainer>
      </Page>
  };

    return (
      <Page
        title="Invoice | Oaza"
        sx={{ marginTop: { xs: "128px", md: "62px", overflow: "hidden" } }}
      >
        <StyledContainer maxWidth="lg">
          <StyledLoadingButton
            size="small"
            type="button"
            variant="contained"
            startIcon={
              <Icon icon={downloadFill} color="#ffff" width={24} height={24} />
            }
            onClick={handlePrintPDF}
            sx={{fontSize:16}}
          >
            Save Invoice
          </StyledLoadingButton>
          <StyledLoadingButton size="small" sx={{textTransform: "none"}} disableRipple>
              {chargeConnectDisconnectMsg.forDisconnect}
          </StyledLoadingButton>

          <StyledPaper
            elevation={4}
            id="invoice"
          >
            <InfoBlock
              centered
              value1="Receipt No: "
              value2={receiptObj.receiptNo}
              sx={{ backgroundColor: "#424242", color: "#ffff" }}
            />
            <Stack padding={3} spacing={2}>
              <InfoBlock
                value1="Charging Services"
                value2={`Receipt date:${receiptObj.receiptDate}`}
              />
               <Stack>
                <InfoBlock
                  sx={{display: "flex",
                    flexDirection: "initial",
                    justifyContent: "space-between",
                    whiteSpace: "pre-wrap"}}
                  value1="Email:"
                  value2={receiptObj.email}
                  alignLeft
                />
              </Stack>
              <Stack>
                <InfoBlock
                  sx={{display: "flex",
                  flexDirection: "initial",
                  justifyContent: "space-between",
                  whiteSpace: "pre-wrap"}}
                  alignLeft
                  value1="Station ID:"
                  value2={receiptObj.stationId}
                />
                </Stack>
                <Stack>
                <InfoBlock
                  sx={{display: "flex",
                  flexDirection: "initial",
                  justifyContent: "space-between",
                  whiteSpace: "pre-wrap"}}
                  value1="Charging Duration:"
                  value2={receiptObj.chargingDuration}
                  alignLeft
                />
              </Stack>
              <Stack direction="row" justifyContent="space-between">
                <InfoBlock
                  value1="Energy"
                  value2={receiptObj.energyConsumption}
                  alignLeft
                />
                <StyledTypography>
                  {receiptObj.totalCostExcludingTax}
                </StyledTypography>
              </Stack>
              <Stack direction="row" justifyContent="space-between">
                <InfoBlock
                  value1="Tax"
                  value2={receiptObj.taxPercent}
                  alignLeft
                />
                <StyledTypography>{receiptObj.taxAmount}</StyledTypography>
              </Stack>
              {receiptObj.transactionFee && (
                <Stack direction="row" justifyContent="space-between">
                  <InfoBlock value1="Transaction Fee" value2="" alignLeft />
                  <StyledTypography>
                    {receiptObj.transactionFee}
                  </StyledTypography>
                </Stack>
              )}
              <Divider variant="fullWidth" />
              <Stack direction="row" justifyContent="space-between">
                <InfoBlock value1="Total Charging Cost" value2="" alignLeft />
                <StyledTypography fontWeight="600">{`${receiptObj.currency} ${receiptObj.totalCostIncludingTaxAndTransactionFee}`}</StyledTypography>
              </Stack>
              <Stack direction="row" justifyContent="space-between">
                <InfoBlock value1="Total Paid" value2="" alignLeft />
                <StyledTypography fontWeight="600">{`${receiptObj.currency} ${receiptObj.totalAmountPaid}`}</StyledTypography>
              </Stack>
              <Stack direction="row" justifyContent="space-between">
                <InfoBlock value1="Refund" value2="" alignLeft />
                <StyledTypography fontWeight="600">{`${receiptObj.currency} ${receiptObj.refund}`}</StyledTypography>
              </Stack>
              <InfoBlock
                value1={`Station Address: ${receiptObj.stationAddress}`}
                value2=""
                centered
                val1Normal
                val2Normal
              />

              <InfoBlock
                value1={
                  <>
                    Questions ? Reach us at {" "}
                    <a
                      href="mailto:info@oaza.energy"
                      rel="noopener noreferrer"
                    >
                      info@oaza.energy
                    </a>
                  </>
                }
                value2=""
                centered
                val1Normal
                val2Normal
              />
            </Stack>
          </StyledPaper>
          <StyledLoadingButton
            size="small"
            type="button"
            variant="contained"
            startIcon={
              <Icon icon={redirectFill} color="#ffff" width={24} height={24} />
            }
            sx={{marginTop:3,fontSize:15}}
            onClick={()=>redirectToChargerDetails(receiptObj.chargeBoxId)}
            
          >
            Back To Charger Details
          </StyledLoadingButton>
        </StyledContainer>
      </Page>
    );
}

export default TransactionInvoice
