import { QueryClient } from "@tanstack/react-query";

const errorHandler = (error) => {
  console.log(error);
};

export const getQueryClient = () => {
  return new QueryClient({
    defaultOptions: {
      queries: {
        retry: 0,
        refetchOnWindowFocus: false,
        onError: errorHandler,
      },
    },
  });
};
