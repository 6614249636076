import { Typography } from "@mui/material";

// components

import React from "react";

import Page from "../Page";

// ----------------------------------------------------------------------

export default function Terms() {

  return (
    <Page title="Dashboard | Oaza-UI">
          <Typography
            variant="p"
            alignItems={"flex-start"}
            style={{
              textAlign: "start",

              display: "block",

              marginBottom: 30,

              color: "grey",

              fontSize: 18,
            }}
          >
            <Typography variant="body1"> Effective Date: 1st November 2022. </Typography>
            <Typography variant="body1">
              By entering into this (“Agreement”) with Oaza Energy Pvt. Ltd., you may use the Oaza Energy 
              (Registered trademark of Oaza Energy Pvt. Ltd.) Mobile Application and website <a href="https://oaza.energy/">https://oaza.energy/</a> , 
              the electric vehicle charging stations which are on Oaza’s network, and purchase any other related products or 
              services offered by Oaza Energy including other services which may be introduced in future (collectively called as, 
              “Services” herein). 
              Note: For the sake of convenience, Oaza Energy , the registered trademark of  Oaza Energy Pvt. Ltd 
              will be used throughout this agreement for the sake of simplicity. 
            </Typography>
            <Typography variant="body1">
              Please read this Oaza Energy Account Agreement carefully. For accessing or using the Services, you agree to be bound by the terms and conditions of this Agreement.  
              Note: If you do not agree to all the terms and conditions of this agreement, then you may not access or use our Service. 
            </Typography>
            <Typography variant="body1">
              If you are submitting a Oaza Energy Account Application in connection with an offer or program (“Program”) offered 
              by Oaza Energy in conjunction with one of its partners, you acknowledge and agree that any additional program-specific 
              terms and conditions made available to you shall apply to your participation in such Program (“Program Terms”). 
              To the extent there is a conflict between this Agreement and any Program Terms, the Program Terms shall control 
              as it relates to your participation in the Program. 
            </Typography>
            <Typography variant="body1">
              In case you are an employee/resident of any organisation/housing apartment society to which we have extended our Services, 
              your profile will be common for availing the Services. All information shown to you as a private user including your 
              profile information, charger related information and payment mechanism information will be controlled by the 
              corporate/society admin user of your organisation/housing apartment society, and Oaza Energy shall not be responsible 
              for any information shown on the Oaza Energy Mobile App. 
            </Typography>
            <Typography variant="body1">
              By entering into this Agreement, you agree to pay all the fees (“Session Fees”) for access to a charging session 
              initiated by you, remotely by a customer service agent at your request or using an application embedded in any smart 
              phone or similar technology. 
            </Typography>
            <Typography variant="body1">
              You further agree to use your Oaza Energy Mobile App solely in accordance with instructions provided to you by 
              Oaza Energy and in compliance of all applicable laws and regulations, as well as any policies and rules of the 
              owner of the Oaza Energy Enabled Charging Station and/or the owner of the property on which the Oaza Energy 
              Enabled Charging Station is located. 
            </Typography>
            <Typography variant="body1">
              You shall promptly update online any changes to your name, email address, mailing address, PIN Code, GSTIN details, 
              telephone number and payment gateway related information when that new information is first known. 
              You remain liable for all Session Fees billed to your account until you have notified Oaza Energy Customer 
              Service of any changes in your account until you have cancelled your account. 
            </Typography>
            <Typography variant="body1">
              If you wish to submit a complaint regarding a particular transaction charged to your Oaza Energy Account, you shall 
              notify Oaza Energy within 7 days of the date of the transaction by writing to us at <a href="mailto:support@oaza.energy">support@oaza.energy</a>. 
              The complaint shall clearly indicate the type of error in the transaction. Complaints regarding incorrect charging 
              costs are processed and determined by Oaza Energy. If and when a complaint is accepted, Oaza Energy shall compensate 
              you for the amount without delay. If the complaint is rejected, Oaza Energy will inform you of the result within 
              90 days from the receipt of written complaint. 
            </Typography>
            <Typography variant="body1">
              Oaza Energy Mobile Application may be used to pay Session Fees on any publicly accessible Oaza Energy Enabled Charging Stations, 
              and on any private Oaza Energy Enabled Charging Station you are allowed to use. Using Oaza Energy Mobile Application for 
              payment of Session Fees, a record of your transaction will be created. Session Fees will be charged to your account in accordance 
              with the price configured as per the actual consumption which may be different from the estimated amount informed through the 
              Oaza Energy Mobile App. It is your responsibility to be aware of the price charged for access to the applicable Oaza Energy Enabled 
              Charging Station. For availing the Services, you shall select one of the payment mechanisms offered by Oaza Energy. 
              You shall be providing appropriate payment information to enable Oaza Energy to receive due amount against Transaction and 
              create a valid invoice for the Transaction. You shall be depositing from time-to-time prepaid amounts towards your account balance 
              and the Session Fees associated with your charging session will be deducted from that balance. You authorize Oaza Energy to, 
              from time to time, deduct amounts payable towards Session Fees from your account. You shall ensure that the registered 
              payment mechanism is valid and that it has sufficient balance. In case payment cannot be processed and the balance in your 
              account cannot be deducted, we reserve the right to close your Oaza Energy Account. Oaza Energy further has the right to 
              require payment directly from you in case of any negative account balance. You may opt to use the Oaza Energy Account, 
              to pay for the Services. Any money deposited in the Oaza Energy Account will be non-refundable, non-transferrable and may 
              only be used to pay for the Services. 
            </Typography>
            <Typography variant="body1">
              If a mobile phone with Oaza Energy Mobile Application installed is lost or stolen, please report such loss or event online, 
              immediately, or, if you do not have access to the internet, inform Oaza Energy Service immediately (see contact information at 
              the end of this document). You remain liable for all Session Fees initiated through the Oaza Energy Mobile Application until 
              such notification. 
            </Typography>
            <Typography variant="body1">
              Your Oaza Energy Mobile Application usage is subject to the terms of the Oaza Energy Privacy Policy. 
              You are responsible for all use of the Oaza Energy Mobile Application/webpage under your user name. 
              You agree that, while Oaza Energy has attempted to provide accurate information on the Oaza Energy Mobile 
              Application/webpage, such information may change frequently and in no event will Oaza Energy be responsible for the 
              accuracy, usefulness or completeness of any information, materials or other content on the Oaza Energy Mobile 
              Application/webpage, or that any such information, materials, or other content is the most up-to date. 
            </Typography>
            <Typography variant="body1">
              Oaza Energy uses all reasonable efforts to ensure that the availability of Oaza Energy Enabled Charging Stations can accurately be 
              tracked in Oaza Energy Mobile Application/website, but cannot guarantee or assure that the charging stations will be available for 
              provision of Services at any given point of time, and expressly disclaim any warranty of availability of the Oaza Energy Enabled 
              Charging Stations at any point of time as displayed in the Oaza Energy Mobile Application/website. 
              Either Party may terminate this Agreement at any time without assigning any reason. Following any termination, you remain responsible 
              for payments owed under this Agreement. If such unpaid charges are not promptly remitted, you may become liable for additional 
              service charges, fines, or penalties, and you may be subject to collection actions for any unpaid balance. 
            </Typography>
            <Typography variant="body1">
              Oaza Energy reserves the right to update, change or replace the terms and conditions of service of this Agreement at any time 
              by providing either notification on website/app OR written notice to you, which, for these purposes, shall include an email 
              sent to the email address you have on record with Oaza Energy (Hence, it’s your responsibility that your profile information 
              is up to date). You agree that all changes become applicable to you when you use Oaza Energy Mobile Application after that date. 
              However, it’s your responsibility also to check these terms and condition from time to time. 
            </Typography>
            <Typography variant="body1">
              YOUR USE OF THE SERVICES IS AT YOUR SOLE RISK. THE OAZA ENERGY ENABLED CHARGING STATIONS AND THE OAZA ENERGY MOBILE 
              APPLICATION ARE PROVIDED ON AN “AS IS” AND “AS AVAILABLE” BASIS. OAZA ENERGY EXPRESSLY DISCLAIMS ALL WARRANTIES OF ANY 
              KIND, WHETHER EXPRESSED OR IMPLIED, INCLUDING, BUT NOT LIMITED TO THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR 
              A PARTICULAR PURPOSE AND NON-INFRINGEMENT. 
            </Typography>
            <Typography variant="body1">
              Oaza Mobile Application may provide an option of advance booking on some Oaza Energy Enabled Charging 
              Stations (“Booking Feature”). For booking any charging station in advance, a boooking fee (“Booking Fee”) is payable 
              from your selected payment mechanism. The Booking Fee paid by you is non-refundable and non-transferable, and can be 
              applied only on the Oaza Energy Enabled Charging Station selected at the time of booking and only for the time period 
              for which the booking is made (“Booking Slot”). Please note that charging at your selected Oaza Energy Enabled Charging 
              Station will auto-stop 5 minutes before the end of your Booking Slot, or before the end of the last Booking Slot in case 
              you have booked multiple consecutive Booking Slots, to allow you time to disconnect your electric vehicle and egress from 
              the charging station before the next Booking Slot. 
            </Typography>
            <Typography variant="body1">
              Oaza Energy makes all reasonable efforts to ensure that Oaza Energy Enabled Charging Stations are available for charging 
              during the Booking Slot(s), however there may be unforeseen events beyond our control which affect availability of 
              Oaza Energy Enabled Charging Stations during the Booking Slot. Oaza Energy hence, reserves the right to cancel any 
              advance booking at any time before or during the Booked Slot without assigning a reason for the same. Oaza Energy shall 
              not be responsible for any losses incurred by you due to a booking being cancelled or for the Oaza Energy Enabled Charging Station not being available for charging during the Booked Slot(s) due to any reason. If a booking is cancelled by Oaza Energy or your selected Oaza Energy Enabled Charging Station is unavailable for the Booking Slot or any part thereof, Oaza Energy will refund the Booking Fee in full or partially, as may be applicable, upon receipt of an application with valid accompanying documentary/photographic evidence from you. Oaza Energy will respond to any refund request made within 90 days from the date of request. 
            </Typography>
            <Typography variant="body1">
              You agree to indemnify, protect, and hold harmless Oaza Energy and its directors, officers, employees, agents and 
              distributors, and Program Partners and entities on whose premises the Oaza Energy Enabled Charging Stations are installed, 
              and their respective directors, officers, employees and agents (collectively, the “Indemnified Parties”) from all liability 
              for any loss, damage or claim arising from or related to your misuse or misapplication of the Services, damage to the 
              charging station or damage to the charging station premises, or otherwise for any breach of the terms of this Agreement. 
            </Typography>
            <Typography variant="body1">
              YOU EXPRESSLY UNDERSTAND AND AGREE THAT NEITHER OAZA ENERGY OR ANY PROGRAM PARTNER SHALL BE LIABLE FOR, INCIDENTAL, 
              SPECIAL, CONSEQUENTIAL OR EXEMPLARY DAMAGES, INCLUDING BUT NOT LIMITED TO, DAMAGES FOR LOSS OF PROFITS, GOODWILL, USE, 
              DATA OR OTHER INTANGIBLE LOSSES (EVEN IF OAZA ENERGY HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES), ARISING OUT 
              OF OR IN ANY MANNER CONNECTED WITH THE SERVICES. 
            </Typography>
            <Typography variant="body1">
              To the maximum extent allowed by law, you agree to, and hereby do, release the Indemnified Parties from all loss, damage, 
              or injury whatsoever, known or unknown, whether arising in tort or contract or otherwise arising out of or in any manner 
              connected with the Services, including the use of the Oaza Energy Enabled Charging Stations. You agree that none of the Indemnified 
              Parties will incur any obligation or liability for any such loss, damage or injury to the maximum extent allowable by law. 
            </Typography>
            <Typography variant="body1">
              This Agreement is to be construed according to the laws of India. You agree that any dispute over privacy or the terms 
              contained in this privacy policy will be governed by the laws of India and all disputes arising out of or in connection 
              with this Agreement shall be finally settled arbitration to be held in Bangalore, Karnataka (India). 
            </Typography>
            <Typography variant="body1">
              Contact us at <a href="mailto:support@oaza.energy">support@oaza.energy</a> , if you have any questions. 
            </Typography>
          </Typography>
    </Page>
  );
}
