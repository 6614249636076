import React, { useContext } from 'react';
import { Snackbar, Alert } from "@mui/material";
import { Context } from '../../context';

const MuiAlert = React.forwardRef(function MuiAlert(props, ref) {
    return <Alert elevation={6} ref={ref} variant="filled" { ...props } />   
});

function MuiSnackBar({ message, severity, open, ...props }) {
    const context = useContext(Context);

    const handleClose = () => {
        const { setMessageOption } = context.message;
        if(props.onCloseFunc) props.onCloseFunc()
        else setMessageOption({
            open: !1
        })
    }

    return (
        <Snackbar open={open} { ...props } onClose={handleClose} autoHideDuration={3000}>
            <MuiAlert onClose={handleClose} severity={severity} sx={{ width: '100%' }}>
                {message}
            </MuiAlert>
        </Snackbar>
    )   
}

MuiSnackBar.defaultProps = {
    autoHideDuration: 6000,
    open: !1,
    anchorOrigin:{
        vertical: 'top',
        horizontal: 'right'
    }
}

export default MuiSnackBar;