/**
 * @author AshutoshMaurya
 * Upgraded to react:16.8
 */

import { useState, useContext } from 'react';
import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  Box
} from '@mui/material';

function UploadPhoto({ setImageSrc, imageSrc, uploadImage }) {
  //  const classes = useStyles();

  const handleImageChange = (e) => {
    e.preventDefault();
    const reader = new FileReader();
    const file = e.target.files[0];
    reader.onloadend = (readerEvt) => {
      const binaryString = readerEvt.target.result;
      const base64tImageString = `data:image/png;base64,${btoa(binaryString)}`;
      //setImageSrc(binaryString);
      uploadImage(binaryString);
    };
    reader.readAsDataURL(file);
  };

  return (
    <Box component="div" style={{ textAlign: '-webkit-center' }}>
      <label htmlFor="myProfilePhotoUploadBtn">
        <input
          type="file"
          id="myProfilePhotoUploadBtn"
          onChange={handleImageChange}
          style={{ display: 'none' }}
        />
        <Avatar
          src={imageSrc}
          alt="userDetail.username"
          style={{ height: '150px', width: '150px' }}
        />
      </label>
    </Box>
  );
}

export default UploadPhoto;
