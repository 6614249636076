import { TabContext } from "@mui/lab";
import {
  AppBar,
  Box,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Modal,
  Paper,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import axios from "../../util/axios";
import { Loader } from "../../util/Loader";
import { genericVehicleSegmentList, socketTypeList } from "../../util/Display";
import Constant from "../../util/constants";
import { useMemo } from "react";
import {
  RootListItemButtonStyles,
  RootTabItemStyles,
  RootTabPanelStyles,
  RootTabStyle,
} from "../@material-extend/MuiTab";
import { Form, FormikProvider, useFormik } from "formik";
import * as Yup from "yup";
import { StyledButton } from "../common/Styled";
import { Context } from "../../context";

const fetchAvailableVehicleList = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios(
        `${process.env.REACT_APP_API_END_POINT}util/get-vehicle`,
        {
          method: "get",
        }
      );
      const availableList = response.data.data;
      if (availableList) resolve(availableList);
    } catch (error) {
      console.error(error);
      reject(error);
    }
  });
};

const addUserVehicleInfo = (vehicleData) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios(
        `${process.env.REACT_APP_API_END_POINT}user/add-vehicle`,
        {
          method: "post",
          data: vehicleData,
        }
      );
      resolve(response);
    } catch (error) {
      console.error(error);
      reject(error);
    }
  });
};

const updateUserVehicleInfo = (vehicleData) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios(
        `${process.env.REACT_APP_API_END_POINT}user/update-vehicle`,
        {
          method: "patch",
          data: vehicleData,
        }
      );
      resolve(response);
    } catch (error) {
      console.error(error);
      reject(error);
    }
  });
};

const SelectVehicle = ({
  availableVehicleList,
  setSelectedVehicle,
  selectedVehicle,
}) => {
  const vehicleSegementsTabs = useMemo(
    () => [
      { value: "1", label: "All" },
      { value: "2", label: "2-Wheeler" },
      { value: "3", label: "3-Wheeler" },
      { value: "4", label: "4-Wheeler" },
    ],
    []
  );
  const { socketType } = Constant;
  const tabPanels = useMemo(() => {
    const tabPanelArray = vehicleSegementsTabs.reduce(
      (tabPanels, { value, label }) => {
        const panel = {
          label,
          value,
          vehicleList:
            value === "1"
              ? [...availableVehicleList]
              : [
                  ...availableVehicleList.filter(
                    (vehicle) => vehicle.segment == value
                  ),
                ],
        };
        const updatedVehicleList = panel?.vehicleList?.map((vehicle) => ({
          ...vehicle,
          imageUrl:
            vehicle.imageUrl ??
            genericVehicleSegmentList.find((vsl) => vsl.id == vehicle.segment)?.src,
          charger: vehicle.charger.map(
            (chargerName) => socketType[chargerName]
          ),
        }));
        panel.vehicleList = updatedVehicleList;
        //   console.log(panel);
        tabPanels = [...tabPanels, panel];
        return tabPanels;
      },
      []
    );
    //   console.log(tabPanelArray);
    return tabPanelArray;
  }, [availableVehicleList, socketType, vehicleSegementsTabs]);

  const [selectedTab, setSelectedTab] = useState("1");
  const [selectedModel, setSelectedModel] = useState(
    selectedVehicle ? selectedVehicle : null
  );

  const handleTabChange = (e, tab) => {
    setSelectedTab(tab);
    if (selectedVehicle && selectedVehicle.make && selectedVehicle.model) {
      const currentlySelectedVehicle = tabPanels[tab - 1].vehicleList.find(
        ({ make, model }) =>
          make === selectedVehicle.make && model === selectedVehicle.model
      );
      if (currentlySelectedVehicle) setSelectedModel(currentlySelectedVehicle);
      else setSelectedModel(null);
    } else setSelectedModel(null);
  };

  const handleModelClick = (index) => {
    const selectedVehicle = tabPanels[selectedTab - 1].vehicleList.find(
      (_, ind) => ind === index
    );
    setSelectedModel(selectedVehicle);
    setSelectedVehicle(selectedVehicle);
  };

  return (
    <Box paddingX={1.4}>
      <TabContext value={selectedTab}>
        <Stack>
          {/* <AppBar position="sticky" color="primary" width="80%"> */}
          <RootTabStyle
            onChange={handleTabChange}
            // indicator=""
            centered
          >
            {vehicleSegementsTabs.map((tab) => {
              return <RootTabItemStyles value={tab.value} label={tab.label} />;
            })}
          </RootTabStyle>
          {/* </AppBar> */}
        </Stack>
        {tabPanels &&
          tabPanels.map((tabPanelObj) => {
            return (
              <RootTabPanelStyles value={tabPanelObj.value}>
                <Grid container spacing={2}>
                  {tabPanelObj.vehicleList &&
                    tabPanelObj.vehicleList.map(
                      ({ make, model, imageUrl, charger }, index) => (
                        <Grid
                          item
                          xs={12}
                          sm={12}
                          md={12}
                          key={`${make}-${model}-${index}`}
                        >
                          <RootListItemButtonStyles
                            key={`${make}-${index}`}
                            onClick={() => handleModelClick(index)}
                            button
                            selected={
                              selectedModel &&
                              selectedModel.make === make &&
                              selectedModel.model === model
                            }
                          >
                            <Stack
                              direction="row"
                              alignItems="flex-start"
                              spacing={7}
                            >
                              {imageUrl && (
                                <img
                                  src={`${imageUrl}`}
                                  srcSet={`${imageUrl}`}
                                  alt={make}
                                  loading="lazy"
                                  height={200}
                                  width={200}
                                  style={{
                                    objectFit: "cover",
                                    objectPosition: "center",
                                    display: "block",
                                  }}
                                />
                              )}
                              <Box>
                                <Typography
                                  sx={{
                                    fontSize: "15.2px",
                                    fontWeight: "bold",
                                    marginBottom: 2,
                                  }}
                                >
                                  {`${make} (${model})`}
                                </Typography>
                                <Grid container spacing={1}>
                                  {charger &&
                                    charger.map((chargerName, ind) => (
                                      <Grid
                                        item
                                        xs={12}
                                        sm={12}
                                        md={12}
                                        key={`${chargerName}-${ind}`}
                                      >
                                        <Stack
                                          direction="row"
                                          alignItems="flex-start"
                                          spacing={1}
                                        >
                                          <img
                                            src={
                                              socketTypeList.find(
                                                (socket) =>
                                                  socket.type === chargerName
                                              )?.src
                                            }
                                            srcSet={
                                              socketTypeList.find(
                                                (socket) =>
                                                  socket.type === chargerName
                                              )?.src
                                            }
                                            alt={`${chargerName}`}
                                            height={20}
                                            width={20}
                                          />
                                          <span
                                            style={{
                                              fontSize: "10px",
                                              textAlign: "center",
                                            }}
                                          >
                                            {chargerName}
                                          </span>
                                        </Stack>
                                      </Grid>
                                    ))}
                                </Grid>
                              </Box>
                            </Stack>
                          </RootListItemButtonStyles>
                        </Grid>
                      )
                    )}
                </Grid>
              </RootTabPanelStyles>
            );
          })}
      </TabContext>
    </Box>
  );
};

const AddVehicleDetails = ({ selectedVehicle, setSelectedVehicle }) => {
  const initialValues = {
    make: selectedVehicle.make,
    model: selectedVehicle.model,
    registrationNo: selectedVehicle.registrationNo
      ? selectedVehicle.registrationNo
      : "",
    vinNo: selectedVehicle.vinNo ? selectedVehicle.vinNo : "",
    color: selectedVehicle.color ? selectedVehicle.color : "",
    charger: selectedVehicle.selectedCharger
      ? selectedVehicle.selectedCharger
      : "",
    // _id: selectedVehicle?._id || null
  };

  const validationSchema = Yup.object().shape({
    make: Yup.string().required().max(60, "Must be 1 characters or less"),
    model: Yup.string().required().max(60, "Must be 1 characters or less"),
    charger: Yup.string().required().max(60, "Must be 1 characters or less"),
    registrationNo: Yup.string().max(60, "Must be 60 characters or less"),
    vinNo: Yup.string().max(60, "Must be 60 characters or less"),
    color: Yup.string().max(60, "Must be 60 characters or less"),
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
  });

  const { errors, touched, setFieldValue, getFieldProps } = formik;

  const changeHandler = (e) => {
    const fieldUpdated = e.target.name;
    const updatedValue = e.target.value.trim();
    const updatedSelectedVehicleObj = {
      ...selectedVehicle,
      [fieldUpdated === "charger" ? "selectedCharger" : fieldUpdated]:
        updatedValue,
    };
    setFieldValue(fieldUpdated, updatedValue);
    setSelectedVehicle(updatedSelectedVehicleObj);
  };

  return (
    <Box>
      <Paper
        elevation={4}
        sx={{ margin: "0 11.2px 11.2px 11.2px", padding: 2 }}
      >
        <FormikProvider value={formik}>
          <Form autoComplete="off" id="team" noValidate>
            <Grid container rowSpacing={5} className="cms-grid-mb">
              <Grid item xs={12} lg={12}>
                <FormControl fullWidth size="small">
                  <TextField
                    id="make"
                    label="Make"
                    size="small"
                    variant="outlined"
                    {...getFieldProps("make")}
                    disabled
                    error={Boolean(touched.make && errors.make)}
                    helperText={touched.make && errors.make}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} lg={12}>
                <FormControl fullWidth size="small">
                  <TextField
                    id="model"
                    label="Model"
                    size="small"
                    variant="outlined"
                    {...getFieldProps("model")}
                    disabled
                    error={Boolean(touched.model && errors.model)}
                    helperText={touched.model && errors.model}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} lg={12}>
                <FormControl fullWidth size="small">
                  <InputLabel id="demo-simple-select-label">
                    {" "}
                    Charger{" "}
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label="Charger"
                    {...getFieldProps("charger")}
                    onChange={changeHandler}
                    error={Boolean(
                      touched.charger && errors.charger
                    )}
                  >
                    {selectedVehicle.charger &&
                      selectedVehicle.charger.map((chargerName) => (
                        <MenuItem value={chargerName}>{chargerName}</MenuItem>
                      ))}
                  </Select>
                  {touched.charger && (
                            <FormHelperText>{errors.charger}</FormHelperText>
                          )}
                </FormControl>
              </Grid>
              <Grid item xs={12} lg={12}>
                <FormControl fullWidth size="small">
                  <TextField
                    id="registrationNo"
                    label="Registration No"
                    size="small"
                    variant="outlined"
                    {...getFieldProps("registrationNo")}
                    onChange={changeHandler}
                    error={Boolean(
                      touched.registrationNo && errors.registrationNo
                    )}
                    helperText={touched.registrationNo && errors.registrationNo}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} lg={12}>
                <FormControl fullWidth size="small">
                  <TextField
                    id="vinNo"
                    label="Vin No"
                    size="small"
                    variant="outlined"
                    {...getFieldProps("vinNo")}
                    onChange={changeHandler}
                    error={Boolean(touched.vinNo && errors.vinNo)}
                    helperText={touched.vinNo && errors.vinNo}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} lg={12}>
                <FormControl fullWidth size="small">
                  <TextField
                    id="color"
                    label="Color"
                    size="small"
                    variant="outlined"
                    {...getFieldProps("color")}
                    onChange={changeHandler}
                    error={Boolean(touched.color && errors.color)}
                    helperText={touched.color && errors.color}
                  />
                </FormControl>
              </Grid>
            </Grid>
          </Form>
        </FormikProvider>
      </Paper>
    </Box>
  );
};

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "550px",
  bgcolor: "background.paper",
  borderRadius: 1,
  boxShadow: 24,
  paddingY: 2,
  height: "600px",
};

const AddVehicleModal = ({ open, onClose }) => {
  const { user } = useContext(Context);
  const { userDetail } = user;
  const [availableVehicleList, setAvailableVehicleList] = useState([]);
  const [availableVehicleListFetched, setAvailableVehicleListFetched] =
    useState(false);
  const [currentStage, setCurrentStage] = useState(1);
  const [selectedVehicle, setSelectedVehicle] = useState({});
  const context = useContext(Context);
  

  useEffect(() => {
    const fetchVehicleListing = async () => {
      try {
        const fetchedVehicleList = await fetchAvailableVehicleList();
        if (fetchedVehicleList) {
          setAvailableVehicleList([...fetchedVehicleList]);
        }
        setAvailableVehicleListFetched(true);
      } catch (error) {
        console.error(error);
        throw error;
      }
    };
    if (!availableVehicleListFetched) fetchVehicleListing();
  }, [availableVehicleListFetched]);

  //   useEffect(() => {
  //     console.log(selectedVehicle);
  //   }, [selectedVehicle]);

  const nextOrSubmissionBtnHandler = async () => {
    if (currentStage === 1) {
      let updatedStage = currentStage + 1;
      setCurrentStage(updatedStage);
    } else {
      if(!selectedVehicle.selectedCharger){
        const { setMessageOption } = context.message;
        setMessageOption({
          open:true,
          message:"Please select charger type",
          variant:"error"
        })
        return ;
      }
      // console.log(`vehicle`, selectedVehicle);
      const vehicleDetails = {
        make:selectedVehicle.make,
        model:selectedVehicle.model,
        registrationNo:selectedVehicle.registrationNo,
        vinNo:selectedVehicle.vinNo,
        color:selectedVehicle.color,
        charger: selectedVehicle.selectedCharger,
        user: userDetail._id,
      };
      const response = await addUserVehicleInfo(vehicleDetails);
      const status = response.data.message;
      const data = response.data.data;
      if (status === "Success" && data) {
        onClose();
      }
    }
  };

  const previousBtnHandler = () => {
    let updatedStage = currentStage - 1;
    setCurrentStage(updatedStage);
  };

  // if (!availableVehicleListFetched) {
  //   return <Loader />;
  // }
  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >{!availableVehicleListFetched ?
      <Loader/>:
      <Box sx={modalStyle}>
        <Stack direction="column" alignItems="center" spacing={2}>
          <Typography sx={{ fontWeight: "bolder" }}>
            {currentStage === 1 ? "SELECT VEHICLE" : "ADD VEHICLE DETAILS"}
          </Typography>
          {currentStage === 1 ? (
            <SelectVehicle
              availableVehicleList={availableVehicleList}
              setSelectedVehicle={setSelectedVehicle}
              selectedVehicle={selectedVehicle}
            />
          ) : (
            <AddVehicleDetails
              selectedVehicle={selectedVehicle}
              setSelectedVehicle={setSelectedVehicle}
            />
          )}
          <AppBar
            position="fixed"
            color="primary"
            sx={{ top: "auto", bottom: 0, paddingY: 1 }}
          >
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="center"
              spacing={2}
            >
              {currentStage > 1 && (
                <StyledButton
                  onClick={previousBtnHandler}
                  variant="contained"
                  style={{
                    backgroundColor: "var(--app-base)",
                    color: "var(--app-color-dark)",
                  }}
                >
                  Previous
                </StyledButton>
              )}
              <StyledButton
                disabled={!selectedVehicle.make}
                onClick={nextOrSubmissionBtnHandler}
                variant="contained"
                style={{
                  backgroundColor: "var(--app-base)",
                  color: "var(--app-color-dark)",
                }}
              >
                {currentStage === 1 ? "Next" : "Submit"}
              </StyledButton>
            </Stack>
          </AppBar>
        </Stack>
      </Box>}
      
    </Modal>
  );
};

export default AddVehicleModal;
