import { TabList, TabPanel } from "@mui/lab";
import { ListItemButton, Tab, styled } from "@mui/material";

export const RootTabStyle = styled(TabList)(({ theme }) => ({
    width:'100%',
    // padding:theme.spacing(1),
    border:"1.5px solid var(--app-color)",
    borderRadius:theme.spacing(1),
    // background:'var(--app-color)',
   boxShadow: '0px 3px 15px rgba(34, 35, 58, 0.5)',
  }));
  
  export const RootTabItemStyles = styled(Tab)(({ theme }) => ({
    color:'var(--app-color-light)',
    fontWeight:600,
    fontSize:'0.9rem',
    letterSpacing: 0.5,
    // margin: `0 ${theme.spacing(4)}px`,
    
    '&.Mui-selected':{
      color:'var(--app-color-dark)',
      // background:'var(--app-color-dark)',
      // borderRadius:theme.spacing(3),
      transition:'background-color 1s ease-in-out,color 1s ease-in-out'
    }
  }));
  


  export const RootTabPanelStyles = styled(TabPanel)(({theme})=>({
    // border:"1px solid var(--app-color)",
    borderRadius:theme.spacing(1),
  //  boxShadow: '0px 3px 15px rgba(34, 35, 58, 0.5)',
    marginTop:theme.spacing(2.5), 
    height:"400px",
    overflowY:"auto"
  }))


  export const RootListItemButtonStyles = styled(ListItemButton)(({theme})=>({
    borderRadius:theme.spacing(0.7),
    boxShadow: '0px 3px 15px rgba(34, 35, 58, 0.5)',
    '&.Mui-selected':{
      boxShadow: '0 0 10px 7.5px var(--app-color)',
    }
  }))