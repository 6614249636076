import React from 'react'
import { useMediaQuery, styled, useTheme, SvgIcon, Link } from '@mui/material';
import { StyledFab } from '../common/Styled';
import Constant from '../../util/constants';

import { ReactComponent as ContributionSVG } from "../../image/app_icon_contribution.svg";
import { Link as RouterLink } from 'react-router-dom';

const CustomizedStyledFab = styled(StyledFab)`
  bottom: 24px;
  left: 50%;
  transform: translateX(-50%);
  background-color: var(--app-color);
  color: #fff;
`;

const styles = {
  fab: {
    '&:hover': {
      backgroundColor: 'var(--app-color) !important'
    }
  },
  svg: {
    color: '#fff',
  }
}

function WhatAreYourThoughts() {
  const theme = useTheme();
  const belowMdScreen = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <div className="what-are-your-thoughts">
      {!belowMdScreen ?
        <Link component={RouterLink} variant="subtitle2" to="/feedback">
          <CustomizedStyledFab aria-label="what are your thoughts" sx={styles.fab} variant="extended" size="large">
            <SvgIcon component={ContributionSVG} sx={{ mr: 1, color: '#fff' }} inheritViewBox />
            {Constant.label.FEEDBACK}
          </CustomizedStyledFab>
        </Link> :
        <Link component={RouterLink} variant="subtitle2" to="/feedback">
          <StyledFab sx={{ bottom: 'calc(48px + 184px)' }} aria-label="what are your thoughts">
            <SvgIcon component={ContributionSVG} inheritViewBox />
          </StyledFab>
        </Link>
      }
    </div>
  )
};

export default React.memo(WhatAreYourThoughts);