import React from "react";
import { styled } from "@mui/system";
import Box from "@mui/material/Box";
import { Accordion, AccordionDetails, AccordionSummary, Button, Container, Typography } from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Link } from "react-router-dom";

const Faqs = () => {
  const ContainerStyle = styled("div")({
    textAlign: "left",
    "& .title-block--centered": {
      textAlign: "center",
    },
    "& .title-block__title ": {
      margin: 0,
      padding: 0,
      width: "100%",
      lineHeight: "1.1",
    },
 
    "& .title-block__overlay ": {
      width: "100%",
      minHeight: "calc(70vh - 90px)",
      position: "relative",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    "& .title-block__content ": {
      padding: "0 1.3rem",
      maxWidth: "100%",
    },
 
  });
  return (
    <ContainerStyle>
      <main role="main" id="faqs">
        <Box className="layout-content">
            <Box
                id="title-block-53-1"
                className="title-block title-block--centered title-block--custom-bg-color title-block--custom-text-color"
                sx={{ backgroundColor: "var(--app-color)", color: "#f3f4f7" }}
            >
                <div className="title-block__wrapper">
                <div className="title-block__overlay">
                    <div className="title-block__content container">
                    <div className="title-block__text">
                        <Box
                        sx={{ fontSize: { xs: "52px", lg: "86px" } }}
                        as="h1"
                        className="title-block__title page__title"
                        >
                        Frequently Asked Questions
                        </Box>
                        <Box
                        as="p"
                        sx={{ fontSize: { lg: "1.7rem" } }}
                        className="field--hero-summary"
                        >
                        Find Solutions to Common Queries
                        </Box>
                    </div>
                    </div>
                </div>
                </div>
            </Box>
            <Container sx={{minHeight:'60vh',py:5}}>
                <Accordion defaultExpanded >
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1-content"
                    id="panel1-header"
                    sx={{backgroundColor:'#f3f4f7'}}
                >
                    <Typography fontWeight="bold" color="gray" >I'd like to delete my oaza account.</Typography>
                </AccordionSummary>
                <AccordionDetails sx={{py:3}}>
                    <Typography>
                    To delete your oaza account, please visit our delete account page. Here, you'll find step-by-step instructions on how to delete your account and any associated actions. Click the button below to proceed to the delete account page.
                    </Typography>
                    <Link to='/delete-user-account'>
                        <Button sx={{mt:3}} variant="contained">Delete User Account</Button>
                    </Link>
                </AccordionDetails>
                </Accordion>
                
            </Container>
        </Box>
      </main>
    </ContainerStyle>
  );
};

export default Faqs;
