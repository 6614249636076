// React Library
import React, { useCallback } from 'react';
// Mui Components
import {
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Avatar,
    Box,
    Divider,
    Typography
} from '@mui/material'

// date-formatting
import format from 'date-fns/format'
//lodash
import isEmpty from 'lodash/isEmpty'
// custom functions and components
import { reportIssuesList } from '../../util/Display';

//Icons
import ElectricCarIcon from '@mui/icons-material/ElectricCar';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import InfoIcon from '@mui/icons-material/Info';

// random ids generator
import { v4 as uuidv4 } from 'uuid';

const styles = {
    checkInList: {
        width: '100%',
        maxHeight: 500,
        position: 'relative',
        overflow: 'auto',
        bgcolor: 'background.paper',
        '& > li' : {
            '& .MuiListItemSecondaryAction-root': {
                top: '30%'
            },
        },
        '&::-webkit-scrollbar': {
            width: 6   
        },
        '&::-webkit-scrollbar-thumb': {
            bgcolor: '#cfcccc',
            borderRadius: '16px',
        }
    },
    checkInStatusIconBox: {
        display: 'inline-block',
        verticalAlign: 'middle',
        marginRight: '4px',
        '& > svg': {
            width: '0.55em',
            height: '0.55em'
        }
    }
}

function CheckInList({ listItem }) {
    const getIssueText = useCallback((args) => {
        const list = reportIssuesList.find(issue => issue.value === args);
        return list?.text || '';
    } ,[listItem]);

    return (
        <List sx={styles.checkInList} className='cm-checkin-list-items'>
            {listItem.map((list, index) => (<>
                <ListItem 
                key={uuidv4()}
                secondaryAction={
                    <Typography variant="caption" top="30%">{format(new Date(list.createdAt), 'MMMM dd, yyyy')}</Typography>} 
                alignItems='flex-start' 
                className='cm-checkin-list-item'
                >
                    <ListItemAvatar>
                        <Avatar> <ElectricCarIcon /> </Avatar>
                    </ListItemAvatar>
                    <ListItemText 
                    primary={<>
                        <Box component="span" sx={styles.checkInStatusIconBox} className="cm-checkin-status-icon">
                            <CancelIcon color='error' />
                        </Box>
                        <Typography variant="subtitle2" display="inline-block">{list?.user?.name}</Typography>
                    </>}
                    secondary={<>
                        {/* <Typography display="block" lineHeight={1} variant="caption">Tata Nexon</Typography> */}
                        <Typography variant="caption">{isEmpty(list.message) ? getIssueText(list.issue) : list.message}</Typography>
                    </>}
                    />
                </ListItem>
                {list.length - 1 !== index  && <Divider variant="inset" component="li" />}
            </>))}
        </List>
    )
}

export default React.memo(CheckInList);
