// material
import { Box, Grid, Paper, Stack, Typography, useMediaQuery } from '@mui/material';
// components
import AllInclusiveIcon from '@mui/icons-material/AllInclusive';
import AtmIcon from '@mui/icons-material/Atm';
import CarRepairIcon from '@mui/icons-material/CarRepair';
import FaceRetouchingNaturalIcon from '@mui/icons-material/FaceRetouchingNatural';
import FitnessCenterIcon from '@mui/icons-material/FitnessCenter';
import HotelIcon from '@mui/icons-material/Hotel';
import LocalBarIcon from '@mui/icons-material/LocalBar';
import LocalHospitalIcon from '@mui/icons-material/LocalHospital';
import LocalParkingIcon from '@mui/icons-material/LocalParking';
import LocalPharmacyIcon from '@mui/icons-material/LocalPharmacy';
import MedicationIcon from '@mui/icons-material/Medication';
import ParkIcon from '@mui/icons-material/Park';
import RestaurantMenuIcon from '@mui/icons-material/RestaurantMenu';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import StadiumIcon from '@mui/icons-material/Stadium';
import TempleHinduIcon from '@mui/icons-material/TempleHindu';
import TheatersIcon from '@mui/icons-material/Theaters';
import TwoWheelerIcon from '@mui/icons-material/TwoWheeler';
import WcIcon from '@mui/icons-material/Wc';
import { getImage } from '../../util/Display';
import Constant from '../../util/constants';
import { StyledImg } from '../common/Styled';
import { useEffect } from 'react';
import { paymentStyles } from "./QRScanner/payment.constants";
import styled from '@emotion/styled';
import  EmailLogo from '@mui/icons-material/Email';
import Call from '@mui/icons-material/Call';
import { useTheme } from '@emotion/react';
// ----------------------------------------------------------------------

const styles = {
    grid: {
        gap: '14px'
    }
}

const StyledContainer = styled(Grid)(({theme}) => ({
    display: "flex",
    flexDirection: "column",
    placeItems: "center",
}))

const StackBox = styled(Paper)(({theme}) => ({
    padding: "8px 5px",
    color: "#76BC45",
    fontSize: 14,
    whiteSpace: "nowrap",
    boxShadow: "0px 0px 4px 0px #00000040",
    fontWeight: "600"
}))

export default function MarkerInformationTab({locationDetail, forLocationTab}) {

    const getFormattedLocation = (openingDetail) => {
        if (openingDetail) {
            return openingDetail.split("\n");
        } else {
            return [];
        }
    }
    const getOpenTime = (day) => {
        let openingTime = "N/A";
        if (!locationDetail || !locationDetail.oeningHours || !locationDetail.oeningHours[day]) {
            return openingTime;
        }
        let dayOpeningTime = locationDetail.oeningHours[day];
        if (dayOpeningTime.allDay) {
            openingTime = "24 x 7";
        }
        return openingTime;
    }

    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <>
            <Box sx={paymentStyles.tabParent}>
                <Typography variant="h3" textAlign="center" mb={2} fontSize="16px" fontWeight={"600"}>{Constant.label.LOCATION_TIME_INFORMATION}</Typography>
                <Grid sx={{ display: "grid", gridTemplateColumns: isSmallScreen || forLocationTab ? "repeat(2, 1fr)" : "repeat(3, 1fr)", gridGap: "1rem" }}>
                    <StackBox>{`Sunday: ${getOpenTime('sunday')}`}</StackBox>
                    <StackBox>{`Monday: ${getOpenTime('monday')}`}</StackBox>
                    <StackBox>{`Tuesday: ${getOpenTime('tuesday')}`}</StackBox>
                    <StackBox>{`Wednesday: ${getOpenTime('wednesday')}`}</StackBox>
                    <StackBox>{`Thursday: ${getOpenTime('thursday')}`}</StackBox>
                    <StackBox>{`Friday: ${getOpenTime('friday')}`}</StackBox>
                    <StackBox>{`Saturday: ${getOpenTime('saturday')}`}</StackBox>
                </Grid>
            </Box>
            <Box sx={{...(paymentStyles.tabParent), placeItems: "center", gap:"1rem"}}>
                <Typography  variant="h3" textAlign="center" mb={2} fontSize="16px" fontWeight={"600"}>{Constant.label.NEARBY_AMENITIES}</Typography>
                {locationDetail?.amenities.length > 0 ? <Box sx={{display: "grid", gridTemplateColumns: isSmallScreen ? "repeat(4, 1fr)" : "repeat(5, 1fr)", rowGap: "16px"}}>
                    {locationDetail?.amenities?.includes("PARKING") && <StyledContainer container spacing={0}>
                        <LocalParkingIcon />
                        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>Parking</Typography>
                    </StyledContainer>}
                    {locationDetail?.amenities?.includes("RESTAURANT") && <StyledContainer container spacing={0}>
                        <RestaurantMenuIcon />
                        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>Restaurant</Typography>
                    </StyledContainer>}
                    {locationDetail?.amenities?.includes("SHOPPING") && <StyledContainer container spacing={0}>
                        <ShoppingCartIcon />
                        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>Shopping Center</Typography>
                    </StyledContainer>}
                    {locationDetail?.amenities?.includes("ATM") && <StyledContainer container spacing={0}>
                        <AtmIcon />
                        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>ATM</Typography>
                    </StyledContainer>}
                    {locationDetail?.amenities?.includes("HOTEL") && <StyledContainer container spacing={0}>
                        <HotelIcon />
                        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>Hotel</Typography>
                    </StyledContainer>}
                    {locationDetail?.amenities?.includes("BAR") && <StyledContainer container spacing={0}>
                        <LocalBarIcon />
                        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>Bar</Typography>
                    </StyledContainer>}
                    {locationDetail?.amenities?.includes("24/7 MONITORING") && <StyledContainer container spacing={0}>
                        <AllInclusiveIcon />
                        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>24/7 Monitoring</Typography>
                    </StyledContainer>}

                    {locationDetail?.amenities?.includes("HOSPITAL") && <StyledContainer container spacing={0}>
                        <LocalHospitalIcon />
                        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>Hospital</Typography>
                    </StyledContainer>}

                    {locationDetail?.amenities?.includes("PHARMACY") && <StyledContainer container spacing={0}>
                        <LocalPharmacyIcon />
                        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>Medical Store</Typography>
                    </StyledContainer>}

                    {locationDetail?.amenities?.includes("AUTO SERVICE") && <StyledContainer container spacing={0}>
                        <CarRepairIcon />
                        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>Auto Service Center</Typography>
                    </StyledContainer>}

                    {locationDetail?.amenities?.includes("KID ACTIVITY ZONE") && <StyledContainer container spacing={0}>
                        <StadiumIcon />
                        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>Kid Acitivity Zone</Typography>
                    </StyledContainer>}

                    {locationDetail?.amenities?.includes("PARK") && <StyledContainer container spacing={0}>
                        <ParkIcon />
                        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>Park</Typography>
                    </StyledContainer>}

                    {locationDetail?.amenities?.includes("PUBLIC WASHROOM") && <StyledContainer container spacing={0}>
                        <WcIcon />
                        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>Public Washroom</Typography>
                    </StyledContainer>}

                    {locationDetail?.amenities?.includes("PILGRIMAGE SITE") && <StyledContainer container spacing={0}>
                        <TempleHinduIcon />
                        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>Pilgrimage Sites</Typography>
                    </StyledContainer>}

                    {locationDetail?.amenities?.includes("SALON") && <StyledContainer container spacing={0}>
                        <FaceRetouchingNaturalIcon />
                        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>Salon</Typography>
                    </StyledContainer>}

                    {locationDetail?.amenities?.includes("THEATER") && <StyledContainer container spacing={0}>
                        <TheatersIcon />
                        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>Cinema</Typography>
                    </StyledContainer>}

                    {locationDetail?.amenities?.includes("GYM") && <StyledContainer container spacing={0}>
                        <FitnessCenterIcon />
                        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>Gym</Typography>
                    </StyledContainer>}

                    {(locationDetail?.amenities?.includes("HOSPITAL") || locationDetail?.amenities?.includes("MEDICAL STORE")) && <StyledContainer container spacing={0}>
                        <MedicationIcon />
                        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>Hospital / Medical Store</Typography>
                    </StyledContainer>}
                    {locationDetail?.amenities?.includes("AUTO SERVICE") && <StyledContainer container spacing={0}>
                        <TwoWheelerIcon />
                        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>Auto Service</Typography>
                    </StyledContainer>}
                </Box> : <Typography sx={{color: "#76BC45", marginTop: "-1rem"}}>N/A</Typography>}


            </Box>
            <Box sx={paymentStyles.tabParent}>
                <Typography  variant="h3" textAlign="center" mb={2} fontSize="16px" fontWeight={"600"}>Operator Info:</Typography>
                <Box sx={{ display: "flex", flexWrap:"wrap", flexDirection: isSmallScreen || forLocationTab ? "column" : "row", gap: isSmallScreen || forLocationTab ? "1rem" : "1.3rem"}}>
                    <Box sx={{display: "flex"}}>
                        < Call/>:&nbsp;
                        <Typography sx={{color: "#76BC45" }}> {locationDetail?.phone ? locationDetail?.phone : locationDetail?.alternatePhone ? locationDetail.alternatePhone : "N/A"}</Typography>
                    </Box>
                    <Box sx={{display: "flex"}}>
                        <EmailLogo />:&nbsp;
                        <Typography sx={{color: "#76BC45" }}> {locationDetail?.email ? locationDetail?.email : "N/A"}</Typography>
                    </Box>
                </Box>
            </Box>
        </>
    );
}
