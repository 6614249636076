import urls from "./urls";
import axios from 'axios';
import Token from "../util/Token";

function queryString(u, query){
    if(query.hasOwnProperty('queryParameters')){
        const { queryParameters } = query;
        const url = new URL(u);
        for(let [k, v] of Object.entries(queryParameters)){
            url.searchParams.set(k, v);
        }
        u = url;
    }
    return u;
}

let url = null;
let authHeader = {
    Authorization: `Bearer ${Token.get()}`
}

const services = {
    addToFavourites: async (data) => {
        return await axios.post(urls.ADD_FAVOURITES, data);
    },
    removeFromFavourites: async (data) => {
        return await axios.post(urls.REMOVE_FAVOURITES, data);
    },
    getLocationDetail: async (data) => {
        url = queryString(urls.GET_LOCATION_DETAILS, data);
        return (await axios.get(url));
    },
    reportIssue: async (data) => {
        return await axios.post(urls.REPORT_ISSUE, data)
    },
    getReportedIssues: async (data) => {
        url = queryString(urls.GET_REPORTED_ISSUE, data)
        return (await axios.get(url));
    },
    reaction: async (data) => {
        return await axios.post(urls.REACTION, data);
    },
    getReactions: async (data) => {
        url = queryString(urls.GET_REACTIONS, data);
        return (await axios.get(url));
    },
    uploadImage: async (data) => {
        const uploadRes = await axios.post(urls.UPLOAD_IMAGE, { image: data.image });
        return await axios.post(urls.ADD_IMAGE, { id: data.id, imagePath: uploadRes.data.data })
    },
    removeImage: async (data) => {
        return await axios.post(urls.REMOVE_IMAGE, data);
    },
    getUser: async (data) => {
        url = queryString(urls.GET_USER, data);
        return (await axios.get(url, { headers: authHeader }));
    },
    createTrip: async (data) => {
        return await axios.post(urls.CREATE_TRIP, data)
    },
    updateTrip: async (data) => {
        return await axios.post(urls.UPDATE_TRIP, data);
    },
    getTrip: async (data) => {
        url = queryString(urls.GET_TRIP, data);
        return (await axios.get(url, { headers: authHeader }));
    },
    deleteTrip: async (data) => {
        url = queryString(urls.DELETE_TRIP, data);
        return await axios.delete(url, { headers: authHeader });
    }
}

async function HttpService(methodName, data){
    try{
        const response = await services[methodName](data);
        if (!response.status) {
            throw new Error(response.statusText)
        }

        return {
            type: 'ok',
            status: response.status,
            data: response.data.data
        }
    }catch(err){
        return {
            type: 'error',
            status: false,
            message: err.message
        }
    }
}

export default HttpService